import React, { useState } from 'react';
import { Flex, IconButton, useColorModeValue, Image, Avatar } from '@chakra-ui/react';
import { FaFlask, FaHistory, FaBell, FaEye } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth'; // Import useAuth hook

const MobileNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const bgColor = useColorModeValue('gray.800', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const activeColor = useColorModeValue('blue.500', 'blue.300');
  const inactiveColor = useColorModeValue('gray.500', 'gray.400');
  const { user } = useAuth(); // Use useAuth hook to get user information
  const [imageError, setImageError] = useState(false);

  const isActive = (path: string) => location.pathname.startsWith(path);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Flex
      as="nav"
      justify="space-around"
      py={2}
      bg={bgColor}
      borderTop="1px"
      borderColor={borderColor}
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      zIndex={10}
      className="mobile-nav"
    >
      <IconButton
        aria-label="Inventory"
        icon={<FaFlask />}
        variant="ghost"
        isActive={isActive('/inventory')}
        onClick={() => navigate('/inventory')}
        _hover={{ color: activeColor, transform: 'scale(1.2)' }}
        _active={{ color: activeColor }}
        color={isActive('/inventory') ? activeColor : inactiveColor}
        transition="all 0.2s"
        className="chakra-icon-button"
      />
      <IconButton
        aria-label="Watchlist"
        icon={<FaEye />}
        variant="ghost"
        isActive={isActive('/watchlist')}
        onClick={() => navigate('/watchlist')}
        _hover={{ color: activeColor, transform: 'scale(1.2)' }}
        _active={{ color: activeColor }}
        color={isActive('/watchlist') ? activeColor : inactiveColor}
        transition="all 0.2s"
        className="chakra-icon-button"
      />
      <IconButton
        aria-label="History"
        icon={<FaHistory />}
        variant="ghost"
        isActive={isActive('/full-history')}
        onClick={() => navigate('/full-history')}
        _hover={{ color: activeColor, transform: 'scale(1.2)' }}
        _active={{ color: activeColor }}
        color={isActive('/full-history') ? activeColor : inactiveColor}
        transition="all 0.2s"
        className="chakra-icon-button"
      />
      <IconButton
        aria-label="Notifications"
        icon={<FaBell />}
        variant="ghost"
        isActive={isActive('/notifications')}
        onClick={() => navigate('/notifications')}
        _hover={{ color: activeColor, transform: 'scale(1.2)' }}
        _active={{ color: activeColor }}
        color={isActive('/notifications') ? activeColor : inactiveColor}
        transition="all 0.2s"
        className="chakra-icon-button"
      />
      <IconButton
        aria-label="Profile"
        icon={
          imageError || !user?.profileImage ? (
            <Avatar size="sm" name={user?.displayName || 'User'} />
          ) : (
            <Image
              src={user.profileImage}
              alt={user?.displayName || 'User Profile'}
              className="profile-image"
              boxSize="1.5rem"
              borderRadius="full"
              onError={handleImageError}
              fallback={<Avatar size="sm" name={user?.displayName || 'User'} />}
            />
          )
        }
        variant="ghost"
        isActive={isActive('/profile')}
        onClick={() => navigate('/profile')}
        _hover={{ color: activeColor, transform: 'scale(1.2)' }}
        _active={{ color: activeColor }}
        color={isActive('/profile') ? activeColor : inactiveColor}
        transition="all 0.2s"
        className="chakra-icon-button"
      />
    </Flex>
  );
};

export default MobileNavigation;