import React, { createContext, useCallback, useMemo, useContext } from 'react';
import { User } from '../components/types';
import { useAuth } from '../contexts/AuthContext';

export interface AppContextProps {
  user: User | null;
  updateUser: (updatedUser: User) => Promise<void>;
  login: (username: string, password: string) => Promise<{ user: User; token: string }>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  isLoading: boolean;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, isAuthenticated, isLoading, login: authLogin, logout: authLogout, updateUser: authUpdateUser } = useAuth();

  const login = useCallback(async (username: string, password: string): Promise<{ user: User; token: string }> => {
    return await authLogin(username, password);
  }, [authLogin]);

  const logout = useCallback(async () => {
    await authLogout();
  }, [authLogout]);

  const updateUser = useCallback(async (updatedUser: User) => {
    await authUpdateUser(updatedUser);
  }, [authUpdateUser]);

  const contextValue = useMemo(() => ({
    user,
    updateUser,
    login,
    logout,
    isAuthenticated,
    isLoading,
  }), [
    user,
    updateUser,
    login,
    logout,
    isAuthenticated,
    isLoading,
  ]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};