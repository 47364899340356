import React, { useState, useEffect } from 'react';
import { Box, Text, Button, VStack } from '@chakra-ui/react';
import { useAuth } from '../hooks/useAuth'; // Adjust this import based on your Firebase auth hook location

interface MobileLoggerProps {
  debugUserIds: string[]; // Array of Firebase Auth UIDs that should see the logger
}

interface LogEntry {
  type: 'log' | 'warn' | 'error';
  message: string;
}

const MobileLogger: React.FC<MobileLoggerProps> = ({ debugUserIds }) => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useAuth(); // Assuming your Firebase auth hook provides a user object

  const isDebugUser = user && debugUserIds.includes(user.id || '');

  useEffect(() => {
    if (!isDebugUser) return;

    const originalLog = console.log;
    const originalWarn = console.warn;
    const originalError = console.error;

    console.log = (...args) => {
      setLogs(prevLogs => [...prevLogs, { type: 'log', message: args.join(' ') }]);
      originalLog.apply(console, args);
    };

    console.warn = (...args) => {
      setLogs(prevLogs => [...prevLogs, { type: 'warn', message: args.join(' ') }]);
      originalWarn.apply(console, args);
    };

    console.error = (...args) => {
      setLogs(prevLogs => [...prevLogs, { type: 'error', message: args.join(' ') }]);
      originalError.apply(console, args);
    };

    return () => {
      console.log = originalLog;
      console.warn = originalWarn;
      console.error = originalError;
    };
  }, [isDebugUser]);

  if (!isDebugUser) return null;

  if (!isVisible) {
    return (
      <Button
        position="fixed"
        bottom="70px" // Increased from 20px to 70px
        right="20px"
        onClick={() => setIsVisible(true)}
        zIndex={9998} // Decreased from 9999 to 9998
      >
        Show Logs
      </Button>
    );
  }

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      height="50%"
      bg="black"
      color="white"
      p={4}
      overflowY="auto"
      zIndex={9999}
    >
      <Button onClick={() => setIsVisible(false)} mb={2}>
        Hide Logs
      </Button>
      <Button onClick={() => setLogs([])} mb={2} ml={2}>
        Clear Logs
      </Button>
      <Box overflowY="auto" height="calc(100% - 40px)">
        <VStack align="stretch" spacing={2}>
          {logs.map((log, index) => (
            <Text
              key={index}
              color={log.type === 'error' ? 'red.300' : log.type === 'warn' ? 'yellow.300' : 'white'}
            >
              {log.message}
            </Text>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default MobileLogger;