import { useContext, useCallback, useMemo, useRef } from 'react';
import { HistoryContext } from '../contexts/HistoryContext';
import { useAuth } from './useAuth';
import { useLogger } from '../index';

export const useHistory = () => {
  const context = useContext(HistoryContext);
  const { user, getUserProfile } = useAuth();
  const logger = useLogger();
  const lastFetchRef = useRef<{ date: Date | null; page: number | null; pageSize: number | null }>({
    date: null,
    page: null,
    pageSize: null
  });

  if (context === null) {
    throw new Error('useHistory must be used within a HistoryProvider');
  }

  const fetchHistoryForDate = useCallback(async (date: Date, page: number, pageSize: number) => {
    if (!user) {
      logger.error('User is not available');
      return null;
    }

    const currentFetch = { date, page, pageSize };
    if (
      lastFetchRef.current.date?.getTime() === date.getTime() &&
      lastFetchRef.current.page === page &&
      lastFetchRef.current.pageSize === pageSize
    ) {
      logger.info('Skipping fetch, same parameters as last fetch');
      return null;
    }

    lastFetchRef.current = currentFetch;

    try {
      logger.info('Fetching history for date:', date, 'page:', page, 'pageSize:', pageSize);
      const result = await context.fetchHistoryForDate(date, page, pageSize);
      logger.info('Fetched history:', result);
      return result;
    } catch (error) {
      logger.error('Error fetching history for date:', error);
      throw error;
    }
  }, [context, user]);

  return useMemo(() => ({
    fetchHistoryForDate,
    clearRecentHistory: context.clearRecentHistory,
    addHistoryEntry: context.addHistoryEntry,
    isHistoryLoading: context.isHistoryLoading,
    historyError: context.historyError,
    personalEntries: context.personalEntries,
    sharedEntries: context.sharedEntries,
    getUserProfile,
  }), [
    fetchHistoryForDate,
    context.clearRecentHistory,
    context.addHistoryEntry,
    context.isHistoryLoading,
    context.historyError,
    context.personalEntries,
    context.sharedEntries,
    getUserProfile,
  ]);
};