import React, { createContext, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createLogger } from './utils/logger';
import logger from './utils/logger';
import MobileLogger from './components/MobileLogger';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider

// Create a context for the logger
const LoggerContext = createContext(createLogger(console));

// Custom hook to use the logger
export const useLogger = () => useContext(LoggerContext);

// Logger Provider component
const LoggerProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const logger = useContext(LoggerContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // In production, logging is already handled by the createLogger function
      // You can add additional production-specific logging setup here if needed
    }
  }, []);

  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
};

// Optional: Customize Chakra UI theme
const theme = extendTheme({
  // Your custom theme settings
});

// Create a wrapper component that includes all providers and the MobileLogger
const AppWrapper = () => (
  <LoggerProvider>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <App />
        <MobileLogger debugUserIds={['Qt3JBWgevSbiUd70Gquo4xVPcRr1']} />
      </AuthProvider>
    </ChakraProvider>
  </LoggerProvider>
);

async function prepare() {
  // Any preparation code if needed
}

prepare().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <AppWrapper />
    </React.StrictMode>
  );

  reportWebVitals();
}).catch(error => {
  logger.error('Error during application initialization:', error);
});