// ReagentList.tsx

import React, { useState, useCallback } from 'react';
import { useReagent } from '../hooks/useReagent';
import { useAuth } from '../hooks/useAuth';
import { useNotification } from '../hooks/useNotification';
import { Reagent, User } from '../components/types';
import { FaMapMarkerAlt, FaClock, FaEye, FaEyeSlash, FaEdit } from 'react-icons/fa';
import { formatTimeLeft } from '../utils/reagentUtils';
import {
  Box,
  VStack,
  Text,
  Flex,
  Button,
  Input,
  Icon,
  useColorModeValue,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Select,
  Spinner,
} from '@chakra-ui/react';
import ProfileImage from './ProfileImage';
import { getDisplayName } from '../utils/userUtils';
import { useLogger } from '../index';
import { ReagentCategory } from '../contexts/ReagentContext';
import {categoryNames } from '../utils/categoryNames';

interface ReagentListProps {
  isPersonal: boolean;
  isMobile: boolean;
  reagents: Reagent[];
  onSave: (updatedReagent: Reagent, newLocation?: string) => Promise<Reagent>;
  onDelete: (id: string, name: string, isPersonal: boolean) => Promise<void>;
  toggleReagentStatus: (id: string, duration: number, isPersonal: boolean) => Promise<Reagent | undefined>;
  onAddReagent: () => void;
  onCheckout: (reagentId: string, reagentName: string) => void;
  userProfileCache: Record<string, User>;
  existingLocations: string[];
  existingLabs: string[];
  releaseSKU: (category: ReagentCategory, sku: string, isPersonal: boolean, labId: string) => Promise<void>;
}

const ReagentList: React.FC<ReagentListProps> = ({
  isPersonal,
  isMobile,
  reagents,
  onSave,
  onDelete,
  toggleReagentStatus,
  userProfileCache,
  existingLocations,
  onCheckout,
  releaseSKU,
}) => {
  const { watchReagent, unwatchReagent, getNextSKU } = useReagent();
  const { user } = useAuth();
  const { updateReagent } = useReagent();
  const { addNotification } = useNotification();
  const [newLocation, setNewLocation] = useState('');
  const { deleteReagent } = useReagent();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingSku, setEditingSku] = useState<string>('');
  const [editingName, setEditingName] = useState<string>('');
  const [editingLocation, setEditingLocation] = useState<string>('');
  const [editingTimeLeft, setEditingTimeLeft] = useState<number | undefined>(undefined);
  const [editingCategory, setEditingCategory] = useState<ReagentCategory>(ReagentCategory.GN);
  const [isGeneratingNewSku, setIsGeneratingNewSku] = useState(false);
  const [categorySkus, setCategorySkus] = useState<{ [category: string]: string }>({});
  const [editingReagent, setEditingReagent] = useState<Reagent | null>(null);

  const logger = useLogger();
  const toast = useToast();

  const cardBgColor = useColorModeValue('white', 'gray.700');

  const isEditing = (reagentId: string) => editingId === reagentId;

  const handleEdit = (reagent: Reagent) => {
    setEditingReagent(reagent);
    setEditingId(reagent.id);
    setEditingSku(reagent.sku || '');
    setEditingName(reagent.name);
    setEditingLocation(reagent.location);
    setEditingTimeLeft(reagent.timeLeft ?? undefined);
    setEditingCategory(reagent.category || ReagentCategory.GN);
    setCategorySkus({ [reagent.category]: reagent.sku });
    setNewLocation('');
  };

  const handleCategoryChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>, reagent: Reagent) => {
      const newCategory = e.target.value as ReagentCategory;
      setEditingCategory(newCategory);
      setIsGeneratingNewSku(true);
  
      if (!categorySkus[newCategory]) {
        try {
          const isPersonal = reagent.isPersonal;
          const labId = isPersonal ? (user?.id || 'UNKNOWN') : (reagent.lab || 'MAIN');
          logger.log('Generating SKU with:', { category: newCategory, isPersonal, labId });
          const newSku = await getNextSKU(newCategory, isPersonal, labId);
          if (newSku) {
            logger.log('New SKU generated:', newSku);
            setCategorySkus((prev) => ({ ...prev, [newCategory]: newSku }));
            setEditingSku(newSku);
          } else {
            logger.error('Failed to generate new SKU');
            toast({
              title: 'Error',
              description: 'Failed to generate new SKU. Please try again.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (error) {
          logger.error('Error generating SKU:', error);
          toast({
            title: 'Error',
            description: 'Failed to generate new SKU. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsGeneratingNewSku(false);
        }
      } else {
        setEditingSku(categorySkus[newCategory]);
        setIsGeneratingNewSku(false);
      }
    },
    [categorySkus, getNextSKU, logger, toast, user]
  );
  

  const saveEditing = async (reagent: Reagent) => {
    const updatedReagent: Reagent = {
      ...reagent,
      sku: editingSku,
      name: editingName,
      location: editingLocation === 'new' ? newLocation : editingLocation,
      timeLeft: editingTimeLeft !== undefined ? editingTimeLeft : reagent.timeLeft,
      category: editingCategory,
    };
  
    try {
      await onSave(updatedReagent, editingLocation === 'new' ? newLocation : undefined);
      setEditingId(null);
      // Release unused SKUs
      Object.entries(categorySkus).forEach(([categoryKey, sku]) => {
        if (reagent.category !== editingCategory && sku !== reagent.sku) {
          releaseSKU(categoryKey as ReagentCategory, sku, reagent.isPersonal, reagent.lab || 'MAIN').catch((error: any) => {
            logger.error('Error releasing SKU:', error);
          });
        }
      });
      setCategorySkus({});
      toast({
        title: 'Reagent Updated',
        description: `Reagent "${reagent.name}" has been updated successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      logger.error('Error saving reagent:', error);
      addNotification(`Failed to update reagent "${reagent.name}".`, reagent.isPersonal, reagent.id);
      toast({
        title: 'Error',
        description: `Failed to update reagent. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCancelEdit = () => {
    if (editingReagent) {
      // Release all generated SKUs except the original one
      Object.entries(categorySkus).forEach(([categoryKey, sku]) => {
        if (sku !== editingSku) {
          releaseSKU(categoryKey as ReagentCategory, sku, editingReagent.isPersonal, editingReagent.lab || 'MAIN').catch((error: any) => {
            logger.error('Error releasing SKU:', error);
          });
        }
      });      
    }
    setEditingId(null);
    setCategorySkus({});
    setEditingReagent(null); // Reset the editing reagent
  };

  const handleCheckoutOrIn = async (reagent: Reagent) => {
    if (!user) {
      logger.error('No authenticated user');
      return;
    }

    try {
      if (reagent.status === 'Available') {
        onCheckout(reagent.id, reagent.name); // Use onCheckout here
        toast({
          title: 'Reagent Checked Out',
          description: `Reagent "${reagent.name}" has been checked out.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        addNotification(`Reagent "${reagent.name}" has been checked out.`, reagent.isPersonal, reagent.id);
      } else if (reagent.checkedOutBy?.id === user.id) {
        await toggleReagentStatus(reagent.id, 0, reagent.isPersonal);
        toast({
          title: 'Reagent Checked In',
          description: `Reagent "${reagent.name}" has been checked in.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        addNotification(`Reagent "${reagent.name}" has been checked in.`, reagent.isPersonal, reagent.id);
      } else {
        addNotification(`You can't check in a reagent that you didn't check out.`, reagent.isPersonal, reagent.id);
        toast({
          title: 'Action Not Allowed',
          description: `You can't check in a reagent that you didn't check out.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      logger.error('Error checking in/out reagent:', error);
      toast({
        title: 'Error',
        description: `Failed to check ${reagent.status === 'Available' ? 'out' : 'in'} reagent.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      addNotification(`Failed to check ${reagent.status === 'Available' ? 'out' : 'in'} reagent.`, reagent.isPersonal, reagent.id);
    }
  };

  const handleDelete = async (reagent: Reagent) => {
    if (!user) {
      logger.error('No authenticated user');
      return;
    }
  
    const confirmed = window.confirm(`Are you sure you want to delete the reagent "${reagent.name}"?`);
    if (!confirmed) {
      return;
    }
  
    try {
      await onDelete(reagent.id, reagent.name, reagent.isPersonal);
      toast({
        title: 'Reagent Deleted',
        description: `Reagent "${reagent.name}" has been deleted successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      addNotification(`Reagent "${reagent.name}" has been deleted.`, reagent.isPersonal, reagent.id);
    } catch (error) {
      logger.error('Error deleting reagent:', error);
      toast({
        title: 'Error',
        description: `Failed to delete reagent "${reagent.name}".`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      addNotification(`Failed to delete reagent "${reagent.name}".`, reagent.isPersonal, reagent.id);
    }
  };

  const handleWatchToggle = async (reagent: Reagent) => {
    try {
      if (reagent.watchedBy?.includes(user?.id || '')) {
        await unwatchReagent(reagent.id);
        toast({
          title: 'Reagent Unwatched',
          description: `You have stopped watching reagent "${reagent.name}".`,
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        addNotification(`You have stopped watching reagent "${reagent.name}".`, reagent.isPersonal, reagent.id);
      } else {
        await watchReagent(reagent.id);
        toast({
          title: 'Reagent Watched',
          description: `You are now watching reagent "${reagent.name}".`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        addNotification(`You are now watching reagent "${reagent.name}".`, reagent.isPersonal, reagent.id);
      }
    } catch (error) {
      logger.error('Error toggling watch status:', error);
      toast({
        title: 'Error',
        description: `Failed to toggle watch status for reagent "${reagent.name}".`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      addNotification(`Failed to toggle watch status for reagent "${reagent.name}".`, reagent.isPersonal, reagent.id);
    }
  };

  const renderReagentRow = (reagent: Reagent) => {
    const isCheckedOutByCurrentUser = reagent.checkedOutBy?.id === user?.id;
    const canEdit = reagent.isPersonal || reagent.status === 'Available' || isCheckedOutByCurrentUser;
    const isEditing = editingId === reagent.id;
    const isOverdue = reagent.status === 'Overdue';

    return (
      <Tr
        key={reagent.id}
        bg={isOverdue ? 'red.100' : undefined}
        _hover={{
          bg: isOverdue ? 'red.200' : 'gray.100',
        }}
      >
        <Td width="10%">
          {isEditing ? (
            <>
              <Text>{editingSku}</Text>
              {isGeneratingNewSku && (
                <Text fontSize="sm" color="orange.500">
                  Generating new SKU... <Spinner size="sm" />
                </Text>
              )}
            </>
          ) : (
            reagent.sku
          )}
        </Td>
        <Td width="15%">
          {isEditing ? (
            <Input
              value={editingName}
              onChange={(e) => setEditingName(e.target.value)}
              size="sm"
            />
          ) : (
            reagent.name
          )}
        </Td>
        <Td width="15%">
          {isEditing ? (
            <>
              <Select
                value={editingLocation}
                onChange={(e) => setEditingLocation(e.target.value)}
                size="sm"
                mb={2}
              >
                {existingLocations.map((loc) => (
                  <option key={loc} value={loc}>
                    {loc}
                  </option>
                ))}
                <option value="new">Add new location</option>
              </Select>
              {editingLocation === 'new' && (
                <Input
                  value={newLocation}
                  onChange={(e) => setNewLocation(e.target.value)}
                  placeholder="New Location"
                  size="sm"
                />
              )}
            </>
          ) : (
            <Flex align="center">
              <Icon as={FaMapMarkerAlt} mr={2} />
              {reagent.location}
            </Flex>
          )}
        </Td>
        <Td width="15%">
          {isEditing ? (
            <Select
              value={editingCategory}
              onChange={(e) => handleCategoryChange(e, reagent)}
              size="sm"
              isDisabled={isGeneratingNewSku}
            >
              {Object.entries(ReagentCategory).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          ) : (
            reagent.category
          )}
        </Td>
        <Td width="10%">{reagent.status}</Td>
        <Td width="15%">
          {!isPersonal && reagent.status !== 'Available' && reagent.checkedOutBy && (
            <Flex align="center">
              <ProfileImage
                user={userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy}
                size="small"
              />
              <Text ml={2} fontSize="sm">
                {getDisplayName(userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy)}
              </Text>
            </Flex>
          )}
        </Td>
        <Td width="15%">
          {isEditing ? (
            <Input
              type="number"
              value={editingTimeLeft}
              onChange={(e) => setEditingTimeLeft(Number(e.target.value))}
              size="sm"
              placeholder="Time Left (minutes)"
            />
          ) : (
            <Flex align="center">
              <Icon as={FaClock} mr={2} />
              <Text>
                {reagent.status === 'In Use' ? formatTimeLeft(reagent.timeLeft, reagent.status) : 'N/A'}
              </Text>
            </Flex>
          )}
        </Td>
        <Td width="20%">
          <Flex gap={2} justify="flex-start">
            {isEditing ? (
              <>
                <Button onClick={() => saveEditing(reagent)} colorScheme="green" size="sm">
                  Save
                </Button>
                <Button onClick={handleCancelEdit} colorScheme="gray" size="sm">
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  colorScheme={reagent.status === 'Available' ? 'yellow' : 'orange'}
                  size="sm"
                  onClick={() => handleCheckoutOrIn(reagent)}
                  isDisabled={!canEdit && reagent.status !== 'Available'}
                >
                  {reagent.status === 'Available' ? 'Check Out' : 'Check In'}
                </Button>
                <IconButton
                  aria-label="Edit"
                  icon={<FaEdit />}
                  size="sm"
                  colorScheme="blue"
                  onClick={() => handleEdit(reagent)}
                  isDisabled={!canEdit}
                />
                {!isPersonal && (
                  <IconButton
                    aria-label={reagent.watchedBy?.includes(user?.id || '') ? 'Unwatch' : 'Watch'}
                    icon={reagent.watchedBy?.includes(user?.id || '') ? <FaEyeSlash /> : <FaEye />}
                    onClick={() => handleWatchToggle(reagent)}
                    size="sm"
                    colorScheme={reagent.watchedBy?.includes(user?.id || '') ? 'teal' : 'gray'}
                  />
                )}
              </>
            )}
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <Box>
      {isMobile ? (
        <VStack spacing={4}>
          {reagents.map((reagent) => (
            <Box
              key={reagent.id}
              bg={cardBgColor}
              borderRadius="md"
              boxShadow="md"
              p={4}
              position="relative"
            >
              {isEditing(reagent.id) && editingReagent?.id === reagent.id ? (
                <VStack spacing={2} align="stretch">
                  <Input
                    value={editingName}
                    onChange={(e) => setEditingName(e.target.value)}
                    placeholder="Reagent Name"
                  />
                  <Input
                    value={editingLocation}
                    onChange={(e) => setEditingLocation(e.target.value)}
                    placeholder="Location"
                  />
                  <Input
                    type="number"
                    value={editingTimeLeft}
                    onChange={(e) => setEditingTimeLeft(Number(e.target.value))}
                    placeholder="Time Left (minutes)"
                  />
                  <Select
                    value={editingCategory}
                    onChange={(e) => handleCategoryChange(e, reagent)}
                    isDisabled={isGeneratingNewSku}
                  >
                    {Object.entries(ReagentCategory).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  {isGeneratingNewSku && (
                    <Flex align="center">
                      <Spinner size="sm" mr={2} />
                      <Text>Generating new SKU...</Text>
                    </Flex>
                  )}
                  <Flex justify="space-between">
                    <Button colorScheme="green" size="sm" onClick={() => saveEditing(reagent)}>
                      Save
                    </Button>
                    <Button colorScheme="gray" size="sm" onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                    <Button colorScheme="red" size="sm" onClick={() => handleDelete(reagent)}>
                      Delete
                    </Button>
                  </Flex>
                </VStack>
              ) : (
                <>
                  <Flex justify="space-between" align="center">
                    <Text fontWeight="bold">{reagent.name}</Text>
                    <Text color={reagent.status === 'Overdue' ? 'red.500' : 'gray.500'}>
                      {reagent.status}
                    </Text>
                  </Flex>
                  <Flex align="center" mt={2}>
                    <Icon as={FaMapMarkerAlt} mr={2} />
                    <Text>{reagent.location}</Text>
                  </Flex>
                  <Flex align="center" mt={2}>
                    <Icon as={FaClock} mr={2} />
                    <Text>{formatTimeLeft(reagent.timeLeft, reagent.status)}</Text>
                  </Flex>
                  {!isPersonal && reagent.status !== 'Available' && reagent.checkedOutBy && (
                    <Flex align="center" mt={2}>
                      <ProfileImage
                        user={userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy}
                        size="small"
                      />
                      <Text ml={2} fontSize="sm">
                        {getDisplayName(userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy)}
                      </Text>
                    </Flex>
                  )}
                  <Flex mt={4} justify="space-between" align="center">
                    <Button
                      colorScheme={reagent.status === 'Available' ? 'yellow' : 'orange'}
                      size="sm"
                      onClick={() => handleCheckoutOrIn(reagent)}
                      isDisabled={!isPersonal && reagent.status !== 'Available' && reagent.checkedOutBy?.id !== user?.id}
                    >
                      {reagent.status === 'Available' ? 'Check Out' : 'Check In'}
                    </Button>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleEdit(reagent)}
                      isDisabled={!isPersonal && reagent.status !== 'Available' && reagent.checkedOutBy?.id !== user?.id}
                    >
                      Edit
                    </Button>
                    {!isPersonal && (
                      <IconButton
                        aria-label={reagent.watchedBy?.includes(user?.id || '') ? 'Unwatch' : 'Watch'}
                        icon={reagent.watchedBy?.includes(user?.id || '') ? <FaEyeSlash /> : <FaEye />}
                        onClick={() => handleWatchToggle(reagent)}
                        size="sm"
                        colorScheme={reagent.watchedBy?.includes(user?.id || '') ? 'teal' : 'gray'}
                      />
                    )}
                  </Flex>
                </>
              )}
            </Box>
          ))}
        </VStack>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th width="10%">SKU</Th>
              <Th width="15%">Name</Th>
              <Th width="15%">Location</Th>
              <Th width="15%">Category</Th>
              <Th width="10%">Status</Th>
              <Th width="15%">In Use By</Th>
              <Th width="15%">Time Left</Th>
              <Th width="20%">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {reagents.map((reagent) => {
              const isCheckedOutByCurrentUser = reagent.checkedOutBy?.id === user?.id;
              const canEdit = reagent.isPersonal || reagent.status === 'Available' || isCheckedOutByCurrentUser;
              const isEditing = editingId === reagent.id;
              const isOverdue = reagent.status === 'Overdue';

              return (
                <Tr
                  key={reagent.id}
                  bg={isOverdue ? 'red.100' : undefined}
                  _hover={{
                    bg: isOverdue ? 'red.200' : 'gray.100',
                  }}
                >
                  <Td width="10%">
                    {isEditing ? (
                      <>
                        <Text>{editingSku}</Text>
                        {isGeneratingNewSku && (
                          <Text fontSize="sm" color="orange.500">
                            Generating new SKU... <Spinner size="sm" />
                          </Text>
                        )}
                      </>
                    ) : (
                      reagent.sku
                    )}
                  </Td>
                  <Td width="15%">
                    {isEditing ? (
                      <Input
                        value={editingName}
                        onChange={(e) => setEditingName(e.target.value)}
                        size="sm"
                      />
                    ) : (
                      reagent.name
                    )}
                  </Td>
                  <Td width="15%">
                    {isEditing ? (
                      <>
                        <Select
                          value={editingLocation}
                          onChange={(e) => setEditingLocation(e.target.value)}
                          size="sm"
                          mb={2}
                        >
                          {existingLocations.map((loc) => (
                            <option key={loc} value={loc}>
                              {loc}
                            </option>
                          ))}
                          <option value="new">Add new location</option>
                        </Select>
                        {editingLocation === 'new' && (
                          <Input
                            value={newLocation}
                            onChange={(e) => setNewLocation(e.target.value)}
                            placeholder="New Location"
                            size="sm"
                          />
                        )}
                      </>
                    ) : (
                      <Flex align="center">
                        <Icon as={FaMapMarkerAlt} mr={2} />
                        {reagent.location}
                      </Flex>
                    )}
                  </Td>
                  <Td width="15%">
                    {isEditing ? (
                      <Select
                        value={editingCategory}
                        onChange={(e) => handleCategoryChange(e, reagent)}
                        size="sm"
                        isDisabled={isGeneratingNewSku}
                      >
                        {Object.entries(ReagentCategory).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      reagent.category
                    )}
                  </Td>
                  <Td width="10%">{reagent.status}</Td>
                  <Td width="15%">
                    {!isPersonal && reagent.status !== 'Available' && reagent.checkedOutBy && (
                      <Flex align="center">
                        <ProfileImage
                          user={userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy}
                          size="small"
                        />
                        <Text ml={2} fontSize="sm">
                          {getDisplayName(userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy)}
                        </Text>
                      </Flex>
                    )}
                  </Td>
                  <Td width="15%">
                    {isEditing ? (
                      <Input
                        type="number"
                        value={editingTimeLeft}
                        onChange={(e) => setEditingTimeLeft(Number(e.target.value))}
                        size="sm"
                        placeholder="Time Left (minutes)"
                      />
                    ) : (
                      <Flex align="center">
                        <Icon as={FaClock} mr={2} />
                        <Text>
                          {reagent.status === 'In Use' ? formatTimeLeft(reagent.timeLeft, reagent.status) : 'N/A'}
                        </Text>
                      </Flex>
                    )}
                  </Td>
                  <Td width="20%">
                    <Flex gap={2} justify="flex-start">
                      {editingId === reagent.id ? (
                        <>
                          <Button colorScheme="green" size="sm" onClick={() => saveEditing(reagent)}>
                            Save
                          </Button>
                          <Button colorScheme="gray" size="sm" onClick={handleCancelEdit}>
                            Cancel
                          </Button>
                          <Button colorScheme="red" size="sm" onClick={() => handleDelete(reagent)}>
                            Delete
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            colorScheme={reagent.status === 'Available' ? 'yellow' : 'orange'}
                            size="sm"
                            onClick={() => handleCheckoutOrIn(reagent)}
                            isDisabled={!canEdit && reagent.status !== 'Available'}
                          >
                            {reagent.status === 'Available' ? 'Check Out' : 'Check In'}
                          </Button>
                          <IconButton
                            aria-label="Edit"
                            icon={<FaEdit />}
                            size="sm"
                            colorScheme="blue"
                            onClick={() => handleEdit(reagent)}
                            isDisabled={!canEdit}
                          />
                          {!isPersonal && (
                            <IconButton
                              aria-label={reagent.watchedBy?.includes(user?.id || '') ? 'Unwatch' : 'Watch'}
                              icon={reagent.watchedBy?.includes(user?.id || '') ? <FaEyeSlash /> : <FaEye />}
                              onClick={() => handleWatchToggle(reagent)}
                              size="sm"
                              colorScheme={reagent.watchedBy?.includes(user?.id || '') ? 'teal' : 'gray'}
                            />
                          )}
                        </>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default ReagentList;
