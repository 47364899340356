export const profileImageUrls = {
    default: 'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile0.png',
    images: [
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile0.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile1.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile2.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile3.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile4.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile5.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile6.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile7.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile8.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile9.png',
      'https://storage.googleapis.com/swanky-lab.appspot.com/profileImages/profile10.png',
    ],
  };

  export const logoUrls = {
    main: 'https://storage.googleapis.com/swanky-lab.appspot.com/logo/main.png',
  };