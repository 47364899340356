import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { 
  Box, 
  VStack, 
  Heading, 
  Text, 
  Flex, 
  Badge, 
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Avatar,
  Tooltip,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { FaMapMarkerAlt, FaClock, FaExpandAlt } from 'react-icons/fa';
import { useReagent } from '../hooks/useReagent';
import { useAuth } from '../hooks/useAuth';
import MobileNavigation from './MobileNavigation';
import { Reagent, User } from '../components/types';
import { formatTimeLeft } from '../utils/reagentUtils';
import { useLogger } from '../index';

const MobileWatchlist: React.FC = () => {
  const { 
    reagents, 
    toggleReagentStatus, 
    extendReagentDuration 
  } = useReagent();

  const { user, getUserProfile, userProfileCache } = useAuth();
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const cardBgColor = useColorModeValue('gray.100', 'gray.700');
  const [extendingReagent, setExtendingReagent] = useState<Reagent | null>(null);
  const [extendDuration, setExtendDuration] = useState<number>(60);
  const [userProfiles, setUserProfiles] = useState<Record<string, User | null>>({});
  const logger = useLogger();
  const toast = useToast();

  const watchedReagents = useMemo(() => {
    if (!user) return [];
    return reagents.filter(reagent => !reagent.isPersonal && reagent.watchedBy?.includes(user.id));
  }, [reagents, user]);

  const activeReagents = useMemo(() => {
    if (!user) return [];
    return reagents.filter(reagent => 
      (reagent.status === 'In Use' || reagent.status === 'Overdue') && 
      reagent.checkedOutBy?.id === user.id
    );
  }, [reagents, user]);

  const uniqueUserIds = useMemo(() => {
    const ids = new Set<string>();
    [...watchedReagents, ...activeReagents].forEach(reagent => {
      if (reagent.checkedOutBy?.id) ids.add(reagent.checkedOutBy.id);
    });
    return Array.from(ids);
  }, [watchedReagents, activeReagents]);

  useEffect(() => {
    uniqueUserIds.forEach(userId => {
      if (!userProfileCache[userId]) {
        getUserProfile(userId);
      }
    });
  }, [uniqueUserIds, getUserProfile, userProfileCache]);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      const profiles: Record<string, User | null> = {};
      for (const userId of uniqueUserIds) {
        if (!userProfileCache[userId]) {
          profiles[userId] = await getUserProfile(userId);
        } else {
          profiles[userId] = userProfileCache[userId];
        }
      }
      setUserProfiles(profiles);
    };

    fetchUserProfiles();
  }, [uniqueUserIds, getUserProfile, userProfileCache]);

  const renderUserInfo = useCallback((reagent: Reagent) => {
    if (!reagent.checkedOutBy) {
      return 'Unknown User';
    }
    const userId = reagent.checkedOutBy.id;
    const profile = userProfiles[userId] || userProfileCache[userId];
    return profile ? (
      <Flex align="center">
        <Avatar 
          size="sm" 
          name={profile.displayName || 'User'} 
          src={profile.profileImage} 
          mr={2}
        />
        <Text>{profile.displayName || 'Unnamed User'}</Text>
      </Flex>
    ) : 'Loading...';
  }, [userProfiles, userProfileCache]);

  const handleExtend = (reagent: Reagent) => {
    setExtendingReagent(reagent);
    setExtendDuration(60); // Default to 60 minutes
  };

  const confirmExtend = async () => {
    if (extendingReagent) {
      try {
        await extendReagentDuration(extendingReagent.id, extendDuration);
        setExtendingReagent(null);
        logger.log(`Reagent duration extended: ${extendingReagent.name}, ${extendDuration} minutes`);
        toast({
          title: "Duration Extended",
          description: `Extended ${extendingReagent.name} by ${extendDuration} minutes.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        logger.error('Error extending reagent duration:', error);
        toast({
          title: "Error",
          description: "Failed to extend reagent duration. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const renderReagentList = (reagents: Reagent[], isWatchlist: boolean) => (
    <VStack spacing={4} align="stretch" px={4}>
      {reagents.length > 0 ? (
        reagents.map((reagent: Reagent) => (
          <Box 
            key={reagent.id} 
            className={`entry-box ${!isWatchlist && reagent.status === 'Overdue' ? 'flashing' : ''}`}
            p={4} 
            bg={cardBgColor} 
            borderRadius="md" 
            boxShadow="md"
          >
            <VStack align="stretch" spacing={2}>
              <Flex justify="space-between" align="center">
                <Heading size="md" className="entry-title">{reagent.name}</Heading>
                <Badge colorScheme={
                  reagent.status === 'Available' ? 'green' : 
                  reagent.status === 'Overdue' ? 'red' : 'yellow'
                }>
                  {reagent.status}
                </Badge>
              </Flex>
              <Text fontSize="sm" color="gray.500">SKU: {reagent.sku || 'N/A'}</Text>
              {!isWatchlist && (
                <Badge alignSelf="flex-start" colorScheme={reagent.isPersonal ? "purple" : "teal"}>
                  {reagent.isPersonal ? "Personal" : "Shared"}
                </Badge>
              )}
              <Text className="entry-details">
                <Icon as={FaMapMarkerAlt} className="entry-icon" />
                {reagent.location || 'No location specified'}
              </Text>
              <Text className="entry-details entry-time-left">
                <Icon as={FaClock} className="entry-icon" />
                Time left: {formatTimeLeft(reagent.timeLeft, reagent.status)}
              </Text>
              {reagent.checkedOutBy ? (
                <Flex className="entry-details entry-user" align="center">
                  {renderUserInfo(reagent)}
                </Flex>
              ) : (
                <Text className="entry-details entry-user">Not checked out</Text>
              )}
              <Flex justify="space-between" align="center" mt={2}>
                {isWatchlist && reagent.status === 'Available' ? (
                  <Button
                    colorScheme="yellow"
                    size="sm"
                    onClick={() => toggleReagentStatus(reagent.id, 60, reagent.isPersonal)}
                  >
                    Check Out
                  </Button>
                ) : (
                  reagent.checkedOutBy?.id === user?.id && (
                    <Button
                      colorScheme="orange"
                      size="sm"
                      onClick={() => toggleReagentStatus(reagent.id, 0, reagent.isPersonal)}
                    >
                      Check In
                    </Button>
                  )
                )}
                {!isWatchlist && reagent.checkedOutBy?.id === user?.id && (
                  <Tooltip label="Extend Duration" placement="top">
                    <IconButton
                      aria-label="Extend Duration"
                      icon={<FaExpandAlt />}
                      size="sm"
                      colorScheme="blue"
                      onClick={() => handleExtend(reagent)}
                    />
                  </Tooltip>
                )}
              </Flex>
            </VStack>
          </Box>
        ))
      ) : (
        <Text textAlign="center" mt={8}>No reagents found</Text>
      )}
    </VStack>
  );


  return (
    <Box bg={bgColor} minHeight="100vh" color={textColor} pb={16}>
      <Box bg="blue.400" py={4} mb={4}>
        <Heading as="h1" size="xl" textAlign="center" color="white">Watchlist</Heading>
      </Box>
      <Tabs isFitted variant="soft-rounded" colorScheme="blue" mb={4} className="mobile-tabs">
        <TabList mb="1em">
          <Tab>Active Reagents</Tab>
          <Tab>Watched Reagents</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {renderReagentList(activeReagents, false)}
          </TabPanel>
          <TabPanel>
            {renderReagentList(watchedReagents, true)}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal isOpen={!!extendingReagent} onClose={() => setExtendingReagent(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Extend Duration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Enter the number of minutes to extend:</Text>
            <Input 
              type="number" 
              value={extendDuration} 
              onChange={(e) => setExtendDuration(parseInt(e.target.value))}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={confirmExtend}>
              Confirm
            </Button>
            <Button variant="ghost" onClick={() => setExtendingReagent(null)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <MobileNavigation />
    </Box>
  );
};

export default MobileWatchlist;