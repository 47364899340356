import React, { useReducer, useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Input, Flex, Select, Text, Spinner, useMediaQuery, VStack, InputGroup, InputRightElement, Button, Avatar } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from '../hooks/useHistory';
import HistoryEntryList from './HistoryEntryList';
import { startOfDay, isEqual, format } from 'date-fns';
import { HistoryEntry, User } from '../components/types';
import { debounce } from 'lodash';
import { useAuth } from '../contexts/AuthContext';
import { Timestamp } from 'firebase/firestore';
import MobileNavigation from './MobileNavigation';
import logger from '../utils/logger';
import { useReagent } from '../hooks/useReagent';
import { Reagent } from '../components/types';


type State = {
  historyData: {
    personalEntries: HistoryEntry[];
    sharedEntries: HistoryEntry[];
    totalPersonal: number;
    totalShared: number;
  };
  filterText: string;
  sortOption: 'newest' | 'oldest';
  selectedDate: Date;
  activeTab: number;
  currentPage: number;
  pageSize: number;
};

type Action =
  | { type: 'SET_HISTORY_DATA'; payload: State['historyData'] }
  | { type: 'SET_FILTER_TEXT'; payload: string }
  | { type: 'SET_SORT_OPTION'; payload: State['sortOption'] }
  | { type: 'SET_SELECTED_DATE'; payload: Date }
  | { type: 'SET_ACTIVE_TAB'; payload: number }
  | { type: 'SET_CURRENT_PAGE'; payload: number }
  | { type: 'SET_PAGE_SIZE'; payload: number };

const initialState: State = {
  historyData: {
    personalEntries: [],
    sharedEntries: [],
    totalPersonal: 0,
    totalShared: 0
  },
  filterText: '',
  sortOption: 'newest',
  selectedDate: startOfDay(new Date()),
  activeTab: 0,
  currentPage: 1,
  pageSize: 20
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_HISTORY_DATA':
      return { ...state, historyData: action.payload };
    case 'SET_FILTER_TEXT':
      return { ...state, filterText: action.payload, currentPage: 1 };
    case 'SET_SORT_OPTION':
      return { ...state, sortOption: action.payload, currentPage: 1 };
    case 'SET_SELECTED_DATE':
      return { ...state, selectedDate: action.payload, currentPage: 1 };
    case 'SET_ACTIVE_TAB':
      return { ...state, activeTab: action.payload, currentPage: 1 };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };
    case 'SET_PAGE_SIZE':
      return { ...state, pageSize: action.payload, currentPage: 1 };
    default:
      return state;
  }
}

const FullHistory: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const renderCount = useRef(0);
  renderCount.current += 1;
  logger.log(`FullHistory render count: ${renderCount.current}`);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { 
    fetchHistoryForDate, 
    isHistoryLoading, 
    historyError,
    personalEntries,
    sharedEntries
  } = useHistory();
  const { user, getUserProfile } = useAuth();
  const [userProfiles, setUserProfiles] = useState<{[key: string]: User | null}>({});
  const [reagentData, setReagentData] = useState<{[key: string]: Reagent | null}>({});
  const { getReagentByName } = useReagent();

  const fetchHistoryData = useCallback(async () => {
    if (!isHistoryLoading && user) {
      try {
        logger.log('FullHistory: Fetching history');
        const result = await fetchHistoryForDate(state.selectedDate, state.currentPage, state.pageSize);
        if (result !== null) {
          dispatch({ 
            type: 'SET_HISTORY_DATA', 
            payload: {
              personalEntries: result.personalEntries,
              sharedEntries: result.sharedEntries,
              totalPersonal: result.totalPersonal,
              totalShared: result.totalShared
            }
          });

          // Fetch reagent data
          const allEntries = [...result.personalEntries, ...result.sharedEntries];
          const uniqueReagentNames = [...new Set(allEntries.map(entry => entry.reagent))];
          const reagents: {[key: string]: Reagent | null} = {};
          for (const reagentName of uniqueReagentNames) {
            reagents[reagentName] = await getReagentByName(reagentName);
          }
          setReagentData(reagents);
        } else {
          logger.log('Fetched history is null, using existing data');
        }
      } catch (error) {
        logger.error('Error fetching history:', error);
      }
    }
  }, [fetchHistoryForDate, state.selectedDate, state.currentPage, state.pageSize, isHistoryLoading, user, getReagentByName]);

  useEffect(() => {
    fetchHistoryData();
  }, [fetchHistoryData]);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      const entries = [...state.historyData.personalEntries, ...state.historyData.sharedEntries];
      const uniqueUserIds = [...new Set(entries.map(entry => entry.userId))];
      const profiles: {[key: string]: User | null} = {};
      for (const userId of uniqueUserIds) {
        profiles[userId] = await getUserProfile(userId);
      }
      setUserProfiles(profiles);
    };
    fetchUserProfiles();
  }, [state.historyData.personalEntries, state.historyData.sharedEntries, getUserProfile]);

  const handleTabChange = useCallback((index: number) => {
    dispatch({ type: 'SET_ACTIVE_TAB', payload: index });
  }, []);

  const handleFilterTextChange = useCallback(debounce((value: string) => {
    dispatch({ type: 'SET_FILTER_TEXT', payload: value });
  }, 300), []);

  const handleSortOptionChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: 'SET_SORT_OPTION', payload: event.target.value as 'newest' | 'oldest' });
  }, []);

  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      dispatch({ type: 'SET_SELECTED_DATE', payload: startOfDay(date) });
    }
  }, []);

  const handlePageChange = useCallback((page: number) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: page });
  }, []);

  const handlePageSizeChange = useCallback((size: number) => {
    dispatch({ type: 'SET_PAGE_SIZE', payload: size });
  }, []);

  const { filteredEntries, totalPages } = useMemo(() => {
    logger.log('FullHistory: Filtering and sorting history');
    const entries = state.activeTab === 0 ? personalEntries : sharedEntries;
    const filtered = entries
      .filter(entry => {
        const entryDate = entry.timestamp instanceof Timestamp ? startOfDay(entry.timestamp.toDate()) : startOfDay(new Date());
        return isEqual(entryDate, state.selectedDate) &&
          (entry.action.toLowerCase().includes(state.filterText.toLowerCase()) ||
           entry.user.toLowerCase().includes(state.filterText.toLowerCase()) ||
           (entry.reagent && entry.reagent.toLowerCase().includes(state.filterText.toLowerCase())));
      })
      .sort((a, b) => {
        const aTime = a.timestamp instanceof Timestamp ? a.timestamp.toMillis() : 0;
        const bTime = b.timestamp instanceof Timestamp ? b.timestamp.toMillis() : 0;
        return state.sortOption === 'newest' ? bTime - aTime : aTime - bTime;
      });
    
    const total = Math.ceil(filtered.length / state.pageSize);
    return { filteredEntries: filtered, totalPages: total };
  }, [state.activeTab, personalEntries, sharedEntries, state.filterText, state.sortOption, state.selectedDate, state.pageSize]);

  const paginatedEntries = useMemo(() => {
    const startIndex = (state.currentPage - 1) * state.pageSize;
    return filteredEntries.slice(startIndex, startIndex + state.pageSize);
  }, [filteredEntries, state.currentPage, state.pageSize]);

  const renderMobileHistoryEntry = useCallback((entry: HistoryEntry) => {
    const userProfile = userProfiles[entry.userId];
    const reagent = reagentData[entry.reagent];
    
    return (
      <Box 
        key={entry.id} 
        borderWidth="1px" 
        borderRadius="lg" 
        p={2} 
        mb={2}
      >
        <Flex alignItems="center" mb={1}>
          <Avatar 
            size="sm" 
            name={userProfile?.displayName || entry.user} 
            src={userProfile?.profileImage} 
            mr={2}
          />
          <Text fontWeight="bold" fontSize="sm">{userProfile?.displayName || entry.user}</Text>
        </Flex>
        <Text fontSize="sm" mb={1}>{entry.action}</Text>
        <Flex justifyContent="space-between" fontSize="xs" color="gray.500">
          <Text>{entry.reagent}</Text>
          <Text>{reagent?.sku || 'N/A'}</Text>
        </Flex>
        <Text fontSize="xs" color="gray.500">
          {entry.timestamp instanceof Timestamp 
            ? format(entry.timestamp.toDate(), 'yyyy-MM-dd h:mm:ss a')
            : 'N/A'}
        </Text>
      </Box>
    );
  }, [userProfiles, reagentData]);

  const renderContent = useMemo(() => {
    logger.log('FullHistory: Rendering content');
    if (isHistoryLoading) {
      return <Spinner />;
    }
    if (historyError) {
      return <Text color="red.500">Error: {historyError}</Text>;
    }
    return isLargerThan768 ? (
      <HistoryEntryList
        entries={paginatedEntries}
        currentPage={state.currentPage}
        totalPages={totalPages}
        pageSize={state.pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        users={userProfiles}
        reagents={reagentData}  // Add this line
      />
    ) : (
      <VStack spacing={2} align="stretch">
        {paginatedEntries.map(renderMobileHistoryEntry)}
        <Flex justifyContent="space-between" mt={2}>
          <Button 
            size="sm"
            onClick={() => handlePageChange(state.currentPage - 1)} 
            isDisabled={state.currentPage === 1}
          >
            Previous
          </Button>
          <Text fontSize="sm">Page {state.currentPage} of {totalPages}</Text>
          <Button 
            size="sm"
            onClick={() => handlePageChange(state.currentPage + 1)} 
            isDisabled={state.currentPage === totalPages}
          >
            Next
          </Button>
        </Flex>
      </VStack>
    );
  }, [isLargerThan768, isHistoryLoading, historyError, paginatedEntries, state.currentPage, totalPages, state.pageSize, handlePageChange, handlePageSizeChange, renderMobileHistoryEntry, userProfiles, reagentData]);


  const renderDesktopFilters = useMemo(() => (
    <Flex direction="row" gap={4} mb={4} alignItems="center">
      <Input
        placeholder="Filter by reagent or user"
        onChange={(e) => handleFilterTextChange(e.target.value)}
        width="300px"
      />
      <Select
        value={state.sortOption}
        onChange={handleSortOptionChange}
        width="200px"
      >
        <option value="newest">Sort by Newest</option>
        <option value="oldest">Sort by Oldest</option>
      </Select>
      <Box width="auto">
        <DatePicker
          selected={state.selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          customInput={<Input width="100%" />}
        />
      </Box>
    </Flex>
  ), [state.sortOption, state.selectedDate, handleFilterTextChange, handleSortOptionChange, handleDateChange]);

  const renderMobileFilters = useMemo(() => (
    <VStack spacing={4} width="100%" mb={4}>
      <InputGroup>
        <Input
          placeholder="Filter by reagent or user"
          value={state.filterText}
          onChange={(e) => handleFilterTextChange(e.target.value)}
          pr="4.5rem"
        />
        <InputRightElement width="4.5rem">
          <SearchIcon color="gray.500" />
        </InputRightElement>
      </InputGroup>
      <Flex width="100%" justifyContent="space-between">
        <Select
          value={state.sortOption}
          onChange={handleSortOptionChange}
          width="48%"
        >
          <option value="newest">Newest</option>
          <option value="oldest">Oldest</option>
        </Select>
        <Box width="48%">
          <DatePicker
            selected={state.selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            customInput={<Input width="100%" />}
          />
        </Box>
      </Flex>
    </VStack>
  ), [state.filterText, state.sortOption, state.selectedDate, handleFilterTextChange, handleSortOptionChange, handleDateChange]);

  return (
    <Box minHeight="100vh" position="relative" pb={isLargerThan768 ? 0 : "60px"}>
      {isLargerThan768 ? (
        // Desktop version
        <Box p={6}>
          <Heading as="h1" size="xl" mb={6}>Full History Log</Heading>
          <Tabs index={state.activeTab} onChange={handleTabChange}>
            <TabList mb={4}>
              <Tab>Personal History</Tab>
              <Tab>Shared History</Tab>
            </TabList>
            <TabPanels>
              {['Personal', 'Shared'].map((tabType) => (
                <TabPanel key={tabType} p={0}>
                  <Box>
                    {renderDesktopFilters}
                    {renderContent}
                  </Box>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      ) : (
        // Mobile version
        <VStack spacing={0} align="stretch" height="100vh" overflowY="auto">
          <Box bg="blue.400" py={4} mb={4}>
            <Heading as="h1" size="lg" textAlign="center" color="white">Full History Log</Heading>
          </Box>
          <Box px={4}>
            {renderMobileFilters}
            <Tabs isFitted variant="soft-rounded" colorScheme="blue" mb={4} index={state.activeTab} onChange={handleTabChange} className="mobile-tabs">
              <TabList mb="1em">
                <Tab>Personal</Tab>
                <Tab>Shared</Tab>
              </TabList>
              <TabPanels>
                {['Personal', 'Shared'].map((tabType) => (
                  <TabPanel key={tabType} p={0}>
                    {renderContent}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Box>
        </VStack>
      )}
      {!isLargerThan768 && <MobileNavigation />}
    </Box>
  );
};

export default FullHistory;