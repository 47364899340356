import React, { createContext, useState, useCallback, useEffect, useMemo } from 'react';
import { useAuth } from '../hooks/useAuth';
import { HistoryEntry } from '../components/types';
import { collection, onSnapshot, Timestamp, doc, getDoc, QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { db } from '../services/firebaseService';
import historyService from '../services/historyService';
import { format } from 'date-fns';
import debounce from 'lodash/debounce';
import { useLogger } from '../index';

export type HistoryContextType = {
  fetchHistoryForDate: (date: Date, page: number, pageSize: number) => Promise<{
    personalEntries: HistoryEntry[];
    sharedEntries: HistoryEntry[];
    totalPersonal: number;
    totalShared: number;
  }>;
  clearRecentHistory: () => Promise<void>;
  addHistoryEntry: (action: string, isPersonal: boolean, reagentName?: string, duration?: number) => Promise<void>;
  isHistoryLoading: boolean;
  historyError: string | null;
  personalEntries: HistoryEntry[];
  sharedEntries: HistoryEntry[];
};

export const HistoryContext = createContext<HistoryContextType | null>(null);

export const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, getUserProfile } = useAuth();
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [historyError, setHistoryError] = useState<string | null>(null);
  const [personalEntries, setPersonalEntries] = useState<HistoryEntry[]>([]);
  const [sharedEntries, setSharedEntries] = useState<HistoryEntry[]>([]);
  const logger = useLogger();

  const fetchHistoryForDate = useCallback(async (date: Date, page: number, pageSize: number) => {
    if (!user) throw new Error("User must be logged in to fetch history.");
    setIsHistoryLoading(true);
    setHistoryError(null);
    try {
      const dateString = format(date, 'yyyy-MM-dd');
      
      // Fetch personal entries for the logged-in user only
      const personalHistoryRef = doc(db, 'history', 'personal', user.id, dateString);
      const personalSnapshot = await getDoc(personalHistoryRef);
      
      // Fetch shared entries
      const sharedHistoryRef = doc(db, 'history', 'shared', 'all', dateString);
      const sharedSnapshot = await getDoc(sharedHistoryRef);
  
      const personalEntries = personalSnapshot.exists() 
        ? historyService.parseHistoryEntries(personalSnapshot.data(), true)
        : [];
      
      const sharedEntries = sharedSnapshot.exists()
        ? historyService.parseHistoryEntries(sharedSnapshot.data(), false)
        : [];
  
      const sortEntries = (entries: HistoryEntry[]) => 
        [...entries].sort((a, b) => {
          const aTime = a.timestamp instanceof Timestamp ? a.timestamp.toMillis() : 0;
          const bTime = b.timestamp instanceof Timestamp ? b.timestamp.toMillis() : 0;
          return bTime - aTime;
      });
      
      const sortedPersonal = sortEntries(personalEntries);
      const sortedShared = sortEntries(sharedEntries);
  
      const paginateEntries = (entries: HistoryEntry[]) => {
        const startIndex = (page - 1) * pageSize;
        return entries.slice(startIndex, startIndex + pageSize);
      };
  
      return {
        personalEntries: paginateEntries(sortedPersonal),
        sharedEntries: paginateEntries(sortedShared),
        totalPersonal: sortedPersonal.length,
        totalShared: sortedShared.length
      };
    } catch (error) {
      logger.error('Error fetching history:', error);
      setHistoryError('Failed to load history for the selected date');
      throw error;
    } finally {
      setIsHistoryLoading(false);
    }
  }, [user, logger]);

  const updateEntries = useCallback((prevEntries: HistoryEntry[], newEntries: HistoryEntry[]) => {
    const entriesMap = new Map(prevEntries.map(entry => [entry.id, entry]));
    newEntries.forEach(entry => entriesMap.set(entry.id, entry));
    return Array.from(entriesMap.values());
  }, []);

  useEffect(() => {
    if (!user) {
      setIsHistoryLoading(false);
      return;
    }
  
    setIsHistoryLoading(true);
  
    // Personal history reference
    const personalHistoryRef = collection(db, 'history', 'personal', user.id);
  
    // Shared history reference
    const sharedHistoryRef = collection(db, 'history', 'shared', 'all');
  
    const unsubscribePersonal = onSnapshot(personalHistoryRef, (snapshot) => {
      const newEntries = snapshot.docs.flatMap((doc: QueryDocumentSnapshot<DocumentData>) => 
        historyService.parseHistoryEntries(doc.data(), true)
      );
      setPersonalEntries(prev => updateEntries(prev, newEntries));
      setIsHistoryLoading(false);
    }, (error) => {
      logger.error("Error fetching personal history:", error);
      setHistoryError("Failed to load personal history");
      setIsHistoryLoading(false);
    });
  
    const unsubscribeShared = onSnapshot(sharedHistoryRef, (snapshot) => {
      const newEntries = snapshot.docs.flatMap((doc: QueryDocumentSnapshot<DocumentData>) => 
        historyService.parseHistoryEntries(doc.data(), false)
      );
      setSharedEntries(prev => updateEntries(prev, newEntries));
      setIsHistoryLoading(false);
    }, (error) => {
      logger.error("Error fetching shared history:", error);
      setHistoryError("Failed to load shared history");
      setIsHistoryLoading(false);
    });
  
    return () => {
      unsubscribePersonal();
      unsubscribeShared();
    };
  }, [user, updateEntries, logger]);

  // Clear history state when user changes
  useEffect(() => {
    setPersonalEntries([]);
    setSharedEntries([]);
  }, [user]);

  // Clear history state on logout
  useEffect(() => {
    const handleLogout = () => {
      setPersonalEntries([]);
      setSharedEntries([]);
    };

    window.addEventListener('user-logout', handleLogout);

    return () => {
      window.removeEventListener('user-logout', handleLogout);
    };
  }, []);

  const clearRecentHistory = useCallback(async () => {
    if (!user) return;
    setIsHistoryLoading(true);
    setHistoryError(null);
    try {
      await historyService.clearOldHistory(user, 30); // Clear history older than 30 days
    } catch (error) {
      setHistoryError('Failed to clear recent history');
      throw error;
    } finally {
      setIsHistoryLoading(false);
    }
  }, [user]);

  const addHistoryEntry = useCallback(
    (action: string, isPersonal: boolean, reagentName?: string, duration?: number): Promise<void> => {
      return new Promise<void>((resolve) => {
        const debouncedAdd = debounce(async () => {
          if (!user) {
            logger.error('No authenticated user');
            resolve();
            return;
          }
          setIsHistoryLoading(true);
          setHistoryError(null);
          try {
            logger.info('Adding history entry:', { action, isPersonal, reagentName, duration });
            const userProfile = await getUserProfile(user.id);
            const formattedAction = duration !== undefined
              ? `${action} for ${duration} minutes.`
              : `${action}.`;
          
            const newEntry: HistoryEntry = {
              id: Date.now().toString(),
              timestamp: Timestamp.now(),
              action: formattedAction,
              user: userProfile?.displayName || 'Unknown User',
              userId: user.id,
              isPersonal,
              reagent: reagentName || '',
              ...(duration !== undefined && { duration })
            };
            await historyService.addHistoryEntry(newEntry, user);
            logger.info('History entry added successfully');
          } catch (error) {
            logger.error('Failed to add history entry:', error);
            setHistoryError('Failed to add history entry');
            if (error instanceof Error) {
              logger.error('Error message:', error.message);
              logger.error('Error stack:', error.stack);
            }
          } finally {
            setIsHistoryLoading(false);
            resolve();
          }
        }, 300);
  
        debouncedAdd();
      });
    },
    [user, getUserProfile, logger]
  );

  const contextValue = useMemo(() => ({
    fetchHistoryForDate,
    clearRecentHistory,
    addHistoryEntry,
    isHistoryLoading,
    historyError,
    personalEntries,
    sharedEntries,
  }), [fetchHistoryForDate, clearRecentHistory, addHistoryEntry, isHistoryLoading, historyError, personalEntries, sharedEntries]);

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
};