import { useState } from 'react';
import { useReagent } from './useReagent';
import { useAuth } from './useAuth';
import { useNotification } from './useNotification';
import { useToast } from '@chakra-ui/react';
import { useLogger } from '../index';

export const useCheckout = () => {
  const { user } = useAuth();
  const { toggleReagentStatus } = useReagent();
  const { addNotification } = useNotification();
  const [checkoutModalData, setCheckoutModalData] = useState({ isOpen: false, reagentId: '', reagentName: '' });
  const toast = useToast();
  const logger = useLogger();

  const handleCheckout = (reagentId: string, reagentName: string) => {
    logger.log('handleCheckout called with:', { reagentId, reagentName });
    if (reagentId && reagentName) {
      setCheckoutModalData({ isOpen: true, reagentId, reagentName });
    } else {
      logger.error('Invalid reagent data for checkout:', { reagentId, reagentName });
      addNotification('Invalid reagent data for checkout', false, reagentId);
    }
  };

  const handleCheckoutConfirm = async (duration: number) => {
    logger.log('handleCheckoutConfirm called with duration:', duration);
    if (user) {
      try {
        logger.log('Attempting to toggle reagent status:', checkoutModalData.reagentId);
        await toggleReagentStatus(checkoutModalData.reagentId, duration, false);
        logger.log('Reagent status toggled successfully');
        toast({
          title: "Reagent Checked Out",
          description: `Reagent "${checkoutModalData.reagentName}" checked out successfully for ${duration} minutes.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        addNotification(`Reagent "${checkoutModalData.reagentName}" checked out successfully.`, false, checkoutModalData.reagentId);
        setCheckoutModalData({ isOpen: false, reagentId: '', reagentName: '' });
      } catch (error) {
        logger.error('Error checking out reagent:', error);
        toast({
          title: "Check-out Error",
          description: `Failed to check out reagent "${checkoutModalData.reagentName}".`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        addNotification('Failed to check out reagent', false, checkoutModalData.reagentId);
      }
    } else {
      logger.error('No user found when trying to confirm checkout');
      toast({
        title: "Authentication Error",
        description: "You must be logged in to checkout a reagent",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      addNotification('You must be logged in to checkout a reagent', false, checkoutModalData.reagentId);
    }
  };

  return { checkoutModalData, handleCheckout, handleCheckoutConfirm, setCheckoutModalData };
};