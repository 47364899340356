import { useCallback } from 'react';
import { skuManager } from '../services/SKUManager';
import { ReagentCategory } from '../contexts/ReagentContext';
import { useLogger } from '../index';
import { useAuth } from '../hooks/useAuth';

export const useSKU = () => {
  const logger = useLogger();
  const { user } = useAuth();

  const getNextSKU = useCallback(async (category: ReagentCategory, isPersonal: boolean, labId: string | null | undefined): Promise<string | null> => {
    logger.log('getNextSKU called with:', { category, isPersonal, labId });
    if (!user) {
      logger.error('Attempted to get SKU without a logged-in user');
      throw new Error('User not authenticated');
    }
    try {
      let idForSKU = isPersonal ? user.id : (labId || 'MAIN');

      // Validation
      if (isPersonal && (!user.id || user.id === 'UNKNOWN')) {
        logger.error('Invalid user ID for SKU:', user.id);
        throw new Error('Invalid user ID for SKU.');
      }

      const sku = await skuManager.getNextSKU(category, isPersonal, idForSKU);
      logger.log('Next SKU for category', category, 'is', sku);
      return sku;
    } catch (error) {
      logger.error('Error getting next SKU:', error);
      throw error;
    }
  }, [user, logger]);

  const useNextSKU = useCallback(async (category: ReagentCategory, isPersonal: boolean, labId: string | null | undefined): Promise<string | null> => {
    logger.log('useNextSKU called with:', { category, isPersonal, labId });
    if (!user) {
      logger.error('Attempted to use SKU without a logged-in user');
      throw new Error('User not authenticated');
    }
    try {
      let idForSKU = isPersonal ? user.id : (labId || 'MAIN');

      // Validation
      if (isPersonal && (!user.id || user.id === 'UNKNOWN')) {
        logger.error('Invalid user ID for useNextSKU:', user.id);
        throw new Error('Invalid user ID for useNextSKU.');
      }
      if (!isPersonal && (labId === 'UNKNOWN')) {
        logger.error('Invalid lab ID for useNextSKU:', labId);
        throw new Error('Invalid lab ID for useNextSKU.');
      }

      const sku = await skuManager.useNextSKU(category, isPersonal, idForSKU);
      logger.log('SKU used from skuManager:', sku);
      return sku;
    } catch (error) {
      logger.error('Error in useNextSKU:', error);
      return null;
    }
  }, [logger, user]);

  const releaseSKU = useCallback(async (sku: string, category: ReagentCategory, isPersonal: boolean, id: string): Promise<void> => {
    logger.log('releaseSKU called with:', { sku, category, isPersonal, id });
    try {
      // Validation
      if (isPersonal && (!id || id === 'MAIN')) {
        logger.error('Invalid user ID for releasing SKU:', id);
        throw new Error('Invalid user ID for releasing SKU.');
      }
      if (!isPersonal && (!id || id === 'UNKNOWN')) {
        logger.error('Invalid lab ID for releasing SKU:', id);
        throw new Error('Invalid lab ID for releasing SKU.');
      }

      await skuManager.releaseSKU(sku, category, isPersonal, id);
      logger.log('SKU released successfully');
    } catch (error) {
      logger.error('Error releasing SKU:', error);
    }
  }, [logger]);

  return { getNextSKU, useNextSKU, releaseSKU };
};