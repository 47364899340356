import React, { memo, useCallback, useMemo, } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Image, Text, Avatar, Button, Flex, Select } from '@chakra-ui/react';
import { HistoryEntry, User, Reagent } from '../components/types';
import { Timestamp } from 'firebase/firestore';
import { useLogger } from '../index';
import { ReagentCategory } from '../contexts/ReagentContext';

interface HistoryEntryRowProps {
  entry: HistoryEntry;
  user: User | null;
  reagent: Reagent | null;
}

const HistoryEntryRow = memo(({ entry, user, reagent }: HistoryEntryRowProps) => {
  const [imageError, setImageError] = React.useState(false);
  const logger = useLogger();
  
  const handleImageError = useCallback(() => {
    setImageError(true);
    logger.warn(`Failed to load profile image for user: ${user?.displayName || entry.user}`);
  }, [user, entry.user, logger]);

  const formattedTimestamp = useMemo(() => {
    return entry.timestamp instanceof Timestamp
      ? entry.timestamp.toDate().toLocaleString()
      : 'Pending';
  }, [entry.timestamp]);

  return (
    <Tr>
      <Td>{formattedTimestamp}</Td>
      <Td>
        <Box display="flex" alignItems="center">
          {imageError || !user?.profileImage ? (
            <Avatar size="sm" name={user?.displayName || entry.user} mr={2} />
          ) : (
            <Image
              src={user?.profileImage}
              alt={user?.displayName || entry.user}
              boxSize="30px"
              borderRadius="full"
              mr={2}
              onError={handleImageError}
              fallback={<Avatar size="sm" name={user?.displayName || entry.user} />}
            />
          )}
          {user?.displayName || entry.user}
        </Box>
      </Td>
      <Td>{entry.action}</Td>
      <Td>{entry.reagent}</Td>
      <Td>{reagent?.sku || 'N/A'}</Td>
    </Tr>
  );
});

HistoryEntryRow.displayName = 'HistoryEntryRow';

interface HistoryEntryListProps {
  entries: HistoryEntry[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  reagents: { [key: string]: Reagent | null };
  users: { [key: string]: User | null };
}

const HistoryEntryList: React.FC<HistoryEntryListProps> = memo(({ 
  entries, 
  currentPage, 
  totalPages, 
  pageSize,
  onPageChange,
  onPageSizeChange,
  reagents,
  users
}) => {
  const logger = useLogger();

  const handlePreviousPage = useCallback(() => {
    onPageChange(Math.max(1, currentPage - 1));
  }, [currentPage, onPageChange]);

  const handleNextPage = useCallback(() => {
    onPageChange(Math.min(totalPages, currentPage + 1));
  }, [currentPage, totalPages, onPageChange]);

  const handlePageSizeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = Number(e.target.value);
    onPageSizeChange(newPageSize);
  }, [onPageSizeChange]);

  const memoizedEntries = useMemo(() => entries.map((entry) => {
    const user = users[entry.userId];
    const reagent = reagents[entry.reagent];
    return (
      <HistoryEntryRow
        key={entry.id}
        entry={entry}
        user={user || { 
          id: entry.userId, 
          displayName: entry.user, 
          profileImage: '',
          notifyOnWatched: false,
        }}
        reagent={reagent}
      />
    );
  }), [entries, users, reagents]);

  if (entries.length === 0) {
    logger.info('No history entries available');
    return <Text>No history entries available.</Text>;
  }

  return (
    <Box>
      <Table>
        <Thead>
          <Tr>
            <Th>Timestamp</Th>
            <Th>User</Th>
            <Th>Action</Th>
            <Th>Reagent</Th>
            <Th>SKU</Th>
          </Tr>
        </Thead>
        <Tbody>
          {memoizedEntries}
        </Tbody>
      </Table>
      <Flex justifyContent="space-between" alignItems="center" mt={4}>
        <Button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Text>Page {currentPage} of {totalPages}</Text>
        <Button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
        <Flex alignItems="center">
          <Text mr={2}>Items per page:</Text>
          <Select value={pageSize} onChange={handlePageSizeChange} width="auto">
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
        </Flex>
      </Flex>
    </Box>
  );
});

HistoryEntryList.displayName = 'HistoryEntryList';

export default HistoryEntryList;