import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCheckout: (duration: number) => void;
  reagentName: string;
}

const CheckoutModal: React.FC<CheckoutModalProps> = ({ isOpen, onClose, onCheckout, reagentName }) => {
  const [duration, setDuration] = useState('');

  const handleCheckout = () => {
    const parsedDuration = parseInt(duration, 10);
    if (!isNaN(parsedDuration) && parsedDuration > 0) {
      onCheckout(parsedDuration);
      onClose();
    }
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/^0+/, ''); // Remove leading zeros
    setDuration(value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Checkout {reagentName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Duration (minutes)</FormLabel>
            <Input 
              type="number" 
              value={duration} 
              onChange={handleDurationChange}
              min="1"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleCheckout} isDisabled={!duration || parseInt(duration, 10) <= 0}>
            Checkout
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CheckoutModal;