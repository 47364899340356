type LoggerMethods = 'log' | 'error' | 'warn' | 'info';

export const createLogger = (consoleObj: Console) => {
  const logger: Record<LoggerMethods, (...args: any[]) => void> = {
    log: (message: string, ...args: any[]) => {
      if (process.env.NODE_ENV !== 'production') {
        consoleObj.log(message, ...args);
      }
    },
    error: (message: string, ...args: any[]) => {
      consoleObj.error(message, ...args);
    },
    warn: (message: string, ...args: any[]) => {
      if (process.env.NODE_ENV !== 'production') {
        consoleObj.warn(message, ...args);
      }
    },
    info: (message: string, ...args: any[]) => {
      if (process.env.NODE_ENV !== 'production') {
        consoleObj.info(message, ...args);
      }
    },
  };

  return logger;
};

export default createLogger(console);