import React, { useEffect, useCallback } from 'react';
import { Box, VStack, Text, Button, Heading } from '@chakra-ui/react';
import { useNotification } from '../hooks/useNotification';
import { differenceInHours } from 'date-fns';
import { Timestamp, FieldValue } from 'firebase/firestore';
import { useLogger } from '../index';


const Notifications: React.FC = () => {
  const { notifications, clearAllNotifications, markAllNotificationsAsRead } = useNotification();
  const logger = useLogger();



  const markAsRead = useCallback(() => {
    markAllNotificationsAsRead();
  }, [markAllNotificationsAsRead, logger]);

  useEffect(() => {
    markAsRead();
  }, [markAsRead]);

  // Helper function to safely get Date from Timestamp or FieldValue
  const getDateFromTimestamp = (timestamp: Timestamp | FieldValue): Date => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate();
    }
    // If it's a FieldValue (serverTimestamp), we can't convert it to a Date
    // So we'll use the current date as a fallback
    return new Date();
  };

  // Filter notifications to show only those from the last 48 hours
  const recentNotifications = notifications.filter(n => {
    const notificationTime = getDateFromTimestamp(n.timestamp).getTime();
    const now = new Date().getTime();
    const hoursDiff = differenceInHours(now, notificationTime);
    return hoursDiff <= 48;
  });

  if (recentNotifications.length === 0) {
    logger.log('No recent notifications to display.');
  }

  return (
    <Box className={`p-6 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <Heading as="h1" size="2xl" mb={6}>Notifications</Heading>
      <Button onClick={clearAllNotifications} mb={4}>Clear All Notifications</Button>
      <VStack spacing={4} align="stretch">
        {recentNotifications.length === 0 ? (
          <Text>No notifications in the last 48 hours</Text>
        ) : (
          recentNotifications.map(notification => (
            <Box
              key={notification.id}
              p={4}
              borderWidth={1}
              borderRadius="md"
              bg={notification.read ? 'gray.100' : 'white'}
            >
              <Text>{notification.message}</Text>
              <Text fontSize="sm" color="gray.500">
                {getDateFromTimestamp(notification.timestamp).toLocaleString()}
              </Text>
            </Box>
          ))
        )}
      </VStack>
    </Box>
  );
};

export default Notifications;