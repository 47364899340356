// reagentService.ts

import { collection, doc, deleteDoc, query, getDocs, addDoc, updateDoc, getDoc, where, Timestamp, serverTimestamp, onSnapshot, FieldValue, arrayRemove } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from './firebaseService';
import { Reagent, User, HistoryEntry } from '../components/types';
import { historyService } from './historyService';
import { addMinutes } from 'date-fns';
import { ReagentCategory } from '../contexts/ReagentContext';
import { notificationService } from './notificationService';
import { FirebaseError } from 'firebase/app';
import { createLogger } from '../utils/logger';
import { skuManager } from './SKUManager';



const logger = createLogger(console);


export const reagentService = {
  
  setupReagentListeners(callback: (reagents: Reagent[]) => void, user: User) {
    if (!user) throw new Error('No authenticated user');
  
    logger.log('Setting up Firestore listeners for reagents');
  
    const reagentsCollection = collection(db, 'reagents');
    const personalQuery = query(reagentsCollection, where('owner', '==', user.id));
    const sharedQuery = query(reagentsCollection, where('owner', '==', 'shared'));
  
    let personalReagents: Reagent[] = [];
    let sharedReagents: Reagent[] = [];
  
    const unsubscribePersonal = onSnapshot(personalQuery, 
      (snapshot) => {
        logger.log('Personal reagents snapshot received');
        personalReagents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as Reagent);
        callback([...personalReagents, ...sharedReagents]);
      },
      (error) => {
        logger.error("Error in personal reagents listener:", error);
      }
    );
  
    const unsubscribeShared = onSnapshot(sharedQuery, 
      (snapshot) => {
        logger.log('Shared reagents snapshot received');
        sharedReagents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as Reagent);
        callback([...personalReagents, ...sharedReagents]);
      },
      (error) => {
        logger.error("Error in shared reagents listener:", error);
      }
    );
  
    return () => {
      logger.log('Unsubscribing from Firestore listeners');
      unsubscribePersonal();
      unsubscribeShared();
    };
  },

  async addReagent(
    newReagent: Partial<Omit<Reagent, "id">>,
    user: User
  ): Promise<Reagent> {
    if (!user) throw new Error('No authenticated user');
  
    logger.log('Current user:', user.id);
    logger.log('Attempting to add reagent:', newReagent);
  
    // Validation
    if (!newReagent.name) {
      logger.error('Reagent name is missing.');
      throw new Error('Reagent name is required.');
    }
  
    if (!newReagent.category) {
      logger.error('Reagent category is missing.');
      throw new Error('Reagent category is required.');
    }
  
    if (!newReagent.isPersonal && (!newReagent.lab || newReagent.lab === 'UNKNOWN')) {
      logger.warn('Lab ID is missing or invalid for shared reagent. Defaulting to MAIN.');
      newReagent.lab = 'MAIN';
    }    
  
    const reagentsCollection = collection(db, 'reagents');
    
    // Use the SKU manager to get the next SKU
    const sku = await skuManager.getNextSKU(
      newReagent.category as ReagentCategory,
      newReagent.isPersonal || false,
      newReagent.isPersonal ? user.id : (newReagent.lab || 'MAIN')
    );
    if (sku === null) {
      throw new Error('Failed to get new SKU');
    }
    
    const reagentToAdd: Omit<Reagent, 'id'> = {
      name: newReagent.name || '',
      sku: sku,
      location: newReagent.location || '',
      originalLocation: newReagent.location || '',
      status: newReagent.status || 'Available',
      owner: newReagent.isPersonal ? user.id : 'shared',
      isPersonal: newReagent.isPersonal || false,
      watchedBy: [],
      lastUpdated: serverTimestamp(),
      createdAt: serverTimestamp(),       // Added createdAt
      updatedAt: serverTimestamp(),       // Added updatedAt
      checkoutTime: null,
      dueTime: null,
      duration: newReagent.duration || null,
      timeLeft: newReagent.timeLeft || null,
      isWatched: false,
      checkedOutBy: null,
      notifiedAt15: false,
      notifiedAtDue: false,
      lastOverdueNotification: null,
      category: newReagent.category || ReagentCategory.GN,
      lab: newReagent.isPersonal ? '' : newReagent.lab || '', // Avoid 'UNKNOWN'
    };
    
  
    logger.log('Reagent to add:', reagentToAdd);
  
    try {
      logger.log('Adding reagent to Firestore:', reagentToAdd);
      const docRef = await addDoc(reagentsCollection, reagentToAdd);
      logger.log('Reagent added successfully. Document ID:', docRef.id);
  
      const savedReagent = { id: docRef.id, ...reagentToAdd };
  
      // Increment the nextSKU value after successfully adding the reagent
      await skuManager.incrementNextSKU(
        newReagent.category as ReagentCategory,
        newReagent.isPersonal || false,
        newReagent.isPersonal ? user.id : (newReagent.lab || 'MAIN')
      );
  
      const historyEntry: Omit<HistoryEntry, 'timestamp'> & { timestamp: FieldValue } = {
        id: Date.now().toString(),
        timestamp: serverTimestamp(),
        action: `New ${reagentToAdd.isPersonal ? 'personal' : 'shared'} reagent "${reagentToAdd.name}" added to ${reagentToAdd.location}.`,
        user: user.displayName || 'Unknown User',
        userId: user.id,
        isPersonal: reagentToAdd.isPersonal,
        reagent: reagentToAdd.name
      };
  
      await historyService.addHistoryEntry(historyEntry, user);
  
      await notificationService.addNotification({
        message: `New ${reagentToAdd.isPersonal ? 'personal' : 'shared'} reagent "${reagentToAdd.name}" added to ${reagentToAdd.location}.`,
        isPersonal: reagentToAdd.isPersonal,
        reagentId: docRef.id,
        userId: user.id,
        timestamp: serverTimestamp(),
        read: false
      });
  
      return savedReagent;
    } catch (error) {
      logger.error('Error adding reagent:', error);
      if (error instanceof FirebaseError) {
        logger.error('Firebase error code:', error.code);
        logger.error('Firebase error message:', error.message);
      }
      throw error;
    }
  },

  async updateReagentStatus(id: string, status: 'Available' | 'In Use' | 'Overdue'): Promise<void> {
    const reagentRef = doc(db, 'reagents', id);
    await updateDoc(reagentRef, { status, lastUpdated: serverTimestamp() });
  },

  async getServerTime(): Promise<number> {
    const timestamp = Timestamp.now();
    return timestamp.toMillis();
  },


  async updateReagent(updatedReagent: Reagent): Promise<Reagent> {
    const reagentRef = doc(db, 'reagents', updatedReagent.id);
    const { id, ...reagentWithoutId } = updatedReagent;

    // Fetch the current reagent data
    const currentReagentSnap = await getDoc(reagentRef);
    const currentReagent = currentReagentSnap.data() as Reagent;

    // Check if the category has changed
    if (currentReagent.category !== reagentWithoutId.category) {
      // Get a new SKU for the new category
      const newSku = await skuManager.useNextSKU(
        reagentWithoutId.category,
        reagentWithoutId.isPersonal,
        reagentWithoutId.isPersonal ? updatedReagent.owner : (reagentWithoutId.lab || 'MAIN')
      );
      // Release the old SKU
      await skuManager.releaseSKU(currentReagent.sku, currentReagent.category, currentReagent.isPersonal, currentReagent.lab || 'MAIN');
      // Update the SKU in the reagent data
      reagentWithoutId.sku = newSku;
    }
  
    // If timeLeft is provided and greater than 0, update the status, duration, and dueTime
    if (reagentWithoutId.timeLeft && reagentWithoutId.timeLeft > 0) {
      const now = Timestamp.now();
      reagentWithoutId.status = 'In Use';
      reagentWithoutId.duration = reagentWithoutId.timeLeft;
      reagentWithoutId.checkoutTime = now;
      reagentWithoutId.dueTime = Timestamp.fromDate(addMinutes(now.toDate(), reagentWithoutId.timeLeft));
      
      // Ensure checkedOutBy is set
      if (!reagentWithoutId.checkedOutBy) {
        logger.error('checkedOutBy is not set for a reagent being checked out');
      }
      
      // Reset notification flags
      reagentWithoutId.notifiedAt15 = false;
      reagentWithoutId.notifiedAtDue = false;
      reagentWithoutId.lastOverdueNotification = null;
    } 
    // If the reagent was in use or overdue but now has no time left, reset its status to 'Available'
    else if (['In Use', 'Overdue'].includes(reagentWithoutId.status) && (!reagentWithoutId.timeLeft || reagentWithoutId.timeLeft <= 0)) {
      reagentWithoutId.status = 'Available';
      reagentWithoutId.timeLeft = null;
      reagentWithoutId.duration = null;
      reagentWithoutId.checkoutTime = null;
      reagentWithoutId.dueTime = null;
      reagentWithoutId.checkedOutBy = null;
      reagentWithoutId.notifiedAt15 = false;
      reagentWithoutId.notifiedAtDue = false;
      reagentWithoutId.lastOverdueNotification = null;
    }
  
    reagentWithoutId.lastUpdated = serverTimestamp();
  
    await updateDoc(reagentRef, reagentWithoutId);
    return { id, ...reagentWithoutId };
  },

  async deleteReagent(id: string, user: User): Promise<void> {
    const reagentRef = doc(db, 'reagents', id);
    const reagentSnap = await getDoc(reagentRef);
    if (!reagentSnap.exists()) {
      throw new Error("Reagent not found");
    }
    
    const reagentData = reagentSnap.data() as Reagent;
  
    if (reagentData.isPersonal && reagentData.owner !== user.id) {
      throw new Error("You don't have permission to delete this personal reagent");
    }
  
    await deleteDoc(reagentRef);
    
    // Release the SKU when deleting the reagent
    await skuManager.releaseSKU(reagentData.sku, reagentData.category, reagentData.isPersonal, reagentData.isPersonal ? user.id : (reagentData.lab || 'MAIN'));
  
    const historyEntry: Omit<HistoryEntry, 'timestamp'> & { timestamp: FieldValue } = {
      id: Date.now().toString(),
      timestamp: serverTimestamp(),
      action: `${reagentData.isPersonal ? 'Personal' : 'Shared'} Reagent "${reagentData.name}" deleted.`,
      user: user.displayName || 'Unknown User',
      userId: user.id,
      isPersonal: reagentData.isPersonal,
      reagent: reagentData.name
    };
  
    await historyService.addHistoryEntry(historyEntry, user);
  },


  async watchReagent(id: string, user: User): Promise<Reagent> {
    if (!user) throw new Error('No authenticated user');

    const reagentRef = doc(db, 'reagents', id);
    const reagentSnap = await getDoc(reagentRef);
    if (!reagentSnap.exists()) {
      throw new Error('Reagent not found');
    }

    const reagent = { id: reagentSnap.id, ...reagentSnap.data() } as Reagent;
    const updatedWatchedBy = Array.from(new Set([...(reagent.watchedBy || []), user.id]));
    
    const updateData: Partial<Reagent> = {
      watchedBy: updatedWatchedBy
    };

    await updateDoc(reagentRef, updateData);

    return { ...reagent, ...updateData };
  },

  async unwatchReagent(id: string, user: User): Promise<Reagent> {
    if (!user) throw new Error('No authenticated user');

    try {
      const reagentRef = doc(db, 'reagents', id);
      const reagentSnap = await getDoc(reagentRef);

      if (!reagentSnap.exists()) {
        throw new Error("Reagent not found");
      }

      const reagent = { id: reagentSnap.id, ...reagentSnap.data() } as Reagent;

      await updateDoc(reagentRef, {
        watchedBy: arrayRemove(user.id)
      });

      const updatedReagent = {
        ...reagent,
        watchedBy: reagent.watchedBy?.filter(watcherId => watcherId !== user.id) || []
      };

      return updatedReagent;
    } catch (error) {
      logger.error('Error unwatching reagent:', error);
      throw error;
    }
  },

  async toggleReagentStatus(id: string, duration: number, isPersonal: boolean, user: User): Promise<Reagent> {
    if (!user) throw new Error('No authenticated user');
  
    try {
      logger.log(`Attempting to toggle reagent status. ID: ${id}, Duration: ${duration}, IsPersonal: ${isPersonal}`);
      
      const reagentRef = doc(db, 'reagents', id);
      const reagentSnap = await getDoc(reagentRef);
  
      if (!reagentSnap.exists()) {
        logger.error(`Reagent with ID ${id} not found`);
        throw new Error("Reagent not found");
      }
  
      const reagent = { id: reagentSnap.id, ...reagentSnap.data() } as Reagent;
      logger.log('Current reagent data:', reagent);
  
      if (reagent.isPersonal && reagent.owner !== user.id) {
        logger.error(`Permission denied: User ${user.id} attempted to toggle personal reagent ${id} owned by ${reagent.owner}`);
        throw new Error("You don't have permission to toggle the status of this personal reagent");
      }
  
      const updateData: Partial<Reagent> = {};
      let newStatus: 'Available' | 'In Use';
  
      if (duration > 0) {
        newStatus = 'In Use';
        updateData.checkedOutBy = { id: user.id, displayName: user.displayName, profileImage: user.profileImage, notifyOnWatched: user.notifyOnWatched };
        updateData.checkoutTime = Timestamp.now();
        updateData.dueTime = Timestamp.fromDate(addMinutes(new Date(), duration));
        updateData.duration = duration;
        updateData.timeLeft = duration;
      } else {
        if (reagent.checkedOutBy && reagent.checkedOutBy.id !== user.id) {
          logger.error(`User ${user.id} attempted to check in reagent ${id} checked out by ${reagent.checkedOutBy.id}`);
          throw new Error("You can't check in a reagent that you didn't check out");
        }
        newStatus = 'Available';
        updateData.checkedOutBy = null;
        updateData.checkoutTime = null;
        updateData.dueTime = null;
        updateData.duration = null;
        updateData.timeLeft = null;
      }
  
      updateData.status = newStatus;
      logger.log('Update data to be applied:', updateData);
  
      await updateDoc(reagentRef, updateData);
      logger.log(`Successfully updated reagent ${id} in Firestore`);
  
      const updatedReagent: Reagent = { ...reagent, ...updateData };
      logger.log('Updated reagent data:', updatedReagent);
  
      // Notifications for shared reagents being checked in
      if (!isPersonal && newStatus === 'Available') {
        logger.log(`Sending notifications for shared reagent ${id} becoming available`);
        for (const watcherId of reagent.watchedBy || []) {
          if (watcherId !== user.id) {
            try {
              await notificationService.addNotification({
                message: `Shared reagent "${reagent.name}" is now available.`,
                isPersonal: false,
                reagentId: id,
                userId: watcherId,
                timestamp: serverTimestamp(),
                read: false
              });
              logger.log(`Notification sent to user ${watcherId}`);
            } catch (notificationError) {
              logger.error(`Error sending notification to user ${watcherId}:`, notificationError);
            }
          }
        }
      }
  
      return updatedReagent;
    } catch (error) {
      logger.error('Error in toggleReagentStatus:', error);
      if (error instanceof Error) {
        logger.error('Error message:', error.message);
        logger.error('Error stack:', error.stack);
      }
      throw error;
    }
  },

  async extendReagentDuration(id: string, duration: number, user: User): Promise<Reagent> {
    const reagentRef = doc(db, 'reagents', id);
    const reagentSnap = await getDoc(reagentRef);
    if (!reagentSnap.exists()) {
      throw new Error("Reagent not found");
    }
  
    const reagent = { id: reagentSnap.id, ...reagentSnap.data() } as Reagent;
    const now = Timestamp.now();
    let newDueTime: Timestamp;
    let newTimeLeft: number;
  
    if (reagent.status === 'Overdue') {
      // For overdue reagents, reset the timer completely
      newDueTime = Timestamp.fromDate(addMinutes(now.toDate(), duration));
      newTimeLeft = duration;
    } else {
      // For non-overdue reagents, add to the existing time
      const currentDueTime = reagent.dueTime?.toDate() || now.toDate();
      newDueTime = Timestamp.fromDate(addMinutes(currentDueTime, duration));
      newTimeLeft = reagent.timeLeft ? reagent.timeLeft + duration : duration;
    }
  
    const updateData: Partial<Reagent> = {
      dueTime: newDueTime,
      status: 'In Use', // Reset the status to 'In Use' in case it was 'Overdue'
      lastUpdated: now,
      notifiedAt15: false,
      notifiedAtDue: false,
      lastOverdueNotification: null,
      timeLeft: newTimeLeft,
      duration: reagent.duration ? reagent.duration + duration : duration,
    };
  
    // Only reset checkoutTime if the reagent was overdue
    if (reagent.status === 'Overdue') {
      updateData.checkoutTime = now;
    }
  
    await updateDoc(reagentRef, updateData);
  
    const updatedReagent: Reagent = {
      ...reagent,
      ...updateData,
    };
  
    const historyEntry: Omit<HistoryEntry, 'timestamp'> & { timestamp: FieldValue } = {
      id: Date.now().toString(),
      timestamp: serverTimestamp(),
      action: `${updatedReagent.isPersonal ? 'Personal' : 'Shared'} Reagent "${updatedReagent.name}" duration extended by ${duration} minutes.`,
      user: updatedReagent.checkedOutBy?.displayName || 'Unknown User',
      userId: updatedReagent.checkedOutBy?.id || '',
      isPersonal: updatedReagent.isPersonal,
      reagent: updatedReagent.name
    };
  
    await historyService.addHistoryEntry(historyEntry, user);
  
    return updatedReagent;
  },

  detectChanges(oldReagent: Reagent, updatedReagent: Reagent): string[] {
    const changes = [];
    if (oldReagent.name !== updatedReagent.name) {
      changes.push(`name changed from "${oldReagent.name}" to "${updatedReagent.name}"`);
    }
    if (oldReagent.location !== updatedReagent.location) {
      changes.push(`location changed from "${oldReagent.location}" to "${updatedReagent.location}"`);
    }
    if (oldReagent.timeLeft !== updatedReagent.timeLeft) {
      const timeDiff = updatedReagent.timeLeft! - oldReagent.timeLeft!;
      changes.push(`session duration ${timeDiff > 0 ? 'extended' : 'reduced'} by ${Math.abs(timeDiff)} minutes`);
    }
    return changes;
  },
};

export default reagentService;
