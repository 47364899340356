import { useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import { useLogger } from '../index';

export const useAuthRequest = () => {
  const auth = getAuth();
  const logger = useLogger();

  const authRequest = useCallback(async <T>(
    requestFunction: (token: string) => Promise<T>
  ): Promise<T> => {
    try {
      const user = auth.currentUser;
      logger.info('Current user in authRequest:', user); // Add this line
      if (!user) {
        throw new Error('No user is signed in');
      }
  
      const token = await user.getIdToken(false);
      logger.info('Token obtained:', token.substring(0, 10) + '...'); // Add this line (only log part of the token for security)
      return await requestFunction(token);
    } catch (error) {
      if (error instanceof Error && error.message.includes('auth/id-token-expired')) {
        // Force token refresh
        await auth.currentUser?.getIdToken(true);
        const newToken = await auth.currentUser?.getIdToken();
        if (!newToken) {
          throw new Error('Failed to refresh auth token');
        }
        return await requestFunction(newToken);
      }
      throw error;
    }
  }, [auth]);

  return authRequest;
};