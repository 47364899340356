// EditReagentModal.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Text,
  Select,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { Reagent } from '../components/types';
import { useLogger } from '../index';
import { ReagentCategory } from '../contexts/ReagentContext';
import { useReagent } from '../hooks/useReagent';

interface EditReagentModalProps {
  isOpen: boolean;
  onClose: () => void;
  reagent: Reagent | null;
  onSave: (updatedReagent: Reagent, newLocation?: string) => Promise<void>;
  onDelete: (id: string, name: string, isPersonal: boolean) => Promise<void>;
  existingLocations: string[];
}

const EditReagentModal: React.FC<EditReagentModalProps> = ({
  isOpen,
  onClose,
  reagent,
  onSave,
  onDelete,
  existingLocations,
}) => {
  const [editingName, setEditingName] = useState('');
  const [editingLocation, setEditingLocation] = useState('');
  const [editingTimeLeft, setEditingTimeLeft] = useState<number | undefined>(undefined);
  const [editingSku, setEditingSku] = useState('');
  const [editingCategory, setEditingCategory] = useState<ReagentCategory>(ReagentCategory.GN);
  const [newLocation, setNewLocation] = useState('');
  const [isGeneratingNewSku, setIsGeneratingNewSku] = useState(false);
  const [categorySkus, setCategorySkus] = useState<{ [category: string]: string }>({});
  const [isSaving, setIsSaving] = useState(false);
  const [editingLabId, setEditingLabId] = useState('');
  const [originalCategory, setOriginalCategory] = useState<ReagentCategory>(ReagentCategory.GN);
  const [originalSku, setOriginalSku] = useState('');

  const toast = useToast();
  const logger = useLogger();
  const { getNextSKU, releaseSKU } = useReagent();

  const handleClose = () => {
    // Release any unused new SKUs
    Object.entries(categorySkus).forEach(([category, sku]) => {
      if (category !== originalCategory && sku !== originalSku) {
        logger.log(`Releasing unused SKU for category ${category}:`, sku);
        releaseSKU(category as ReagentCategory, sku, reagent!.isPersonal, reagent!.lab || 'UNKNOWN')
          .catch(error => logger.error('Error releasing unused SKU:', error));
      }
    });
  
    // Reset all editing states
    setEditingName('');
    setEditingLocation('');
    setEditingTimeLeft(undefined);
    setEditingSku('');
    setEditingCategory(ReagentCategory.GN);
    setNewLocation('');
    setIsGeneratingNewSku(false);
    setCategorySkus({});
    
    // Reset original values
    setOriginalCategory(ReagentCategory.GN);
    setOriginalSku('');
  
    // Call the onClose prop
    onClose();
  };

  useEffect(() => {
    if (reagent) {
      logger.log('Reagent loaded in EditReagentModal:', reagent);
      setEditingName(reagent.name);
      setEditingLocation(reagent.location);
      setEditingTimeLeft(reagent.timeLeft ?? undefined);
      setEditingSku(reagent.sku || '');
      setEditingCategory(reagent.category || ReagentCategory.GN);
      setNewLocation('');
      setCategorySkus({ [reagent.category]: reagent.sku });
      setOriginalCategory(reagent.category || ReagentCategory.GN);
      setOriginalSku(reagent.sku || '');
    }
  }, [reagent, logger]);

  const handleCategoryChange = useCallback(
    async (newCategory: ReagentCategory) => {
      logger.log('Category changed to:', newCategory);
      setEditingCategory(newCategory);
      setIsGeneratingNewSku(true);

      if (reagent) {
        if (categorySkus[newCategory]) {
          logger.log('Using cached SKU:', categorySkus[newCategory]);
          setEditingSku(categorySkus[newCategory]);
          setIsGeneratingNewSku(false);
        } else {
          try {
            logger.log('Generating new SKU for category:', newCategory);
            const labId = reagent.isPersonal
              ? reagent.owner.slice(0, 4).toUpperCase()
              : reagent.lab || 'MAIN';
            
            // Generate a new SKU but don't release the old one yet
            const newSku = await getNextSKU(newCategory, reagent.isPersonal, labId);
            logger.log('New SKU generated:', newSku);

            if (newSku) {
              setEditingSku(newSku);
              setCategorySkus((prev) => ({ ...prev, [newCategory]: newSku }));
            } else {
              throw new Error('Failed to get new SKU');
            }
          } catch (error) {
            const errorMessage = 'Error getting new SKU';
            logger.error(errorMessage, error);
            toast({
              title: 'Error',
              description: errorMessage,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          } finally {
            setIsGeneratingNewSku(false);
          }
        }
      }
    },
    [reagent, getNextSKU, logger, toast, categorySkus]
  );

  const handleSave = async () => {
    if (reagent) {
      // Validation
      if (!editingName.trim()) {
        toast({
          title: 'Error',
          description: 'Reagent name is required.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      if (editingLocation === 'new' && !newLocation.trim()) {
        toast({
          title: 'Error',
          description: 'New location is required.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      if (!reagent.isPersonal && (editingLabId === 'UNKNOWN' || !editingLabId.trim())) {
        toast({
          title: 'Error',
          description: 'Valid lab ID is required for shared reagents.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      const updatedReagent: Reagent = {
        ...reagent,
        name: editingName.trim(),
        location: editingLocation === 'new' ? newLocation.trim() : editingLocation,
        sku: editingSku,
        category: editingCategory,
        status: editingTimeLeft !== undefined ? 'In Use' : reagent.status,
        timeLeft: editingTimeLeft !== undefined ? editingTimeLeft : reagent.timeLeft,
        duration: editingTimeLeft !== undefined ? editingTimeLeft : reagent.duration,
        lab: reagent.isPersonal ? '' : editingLabId.trim(),
      };
  
      setIsSaving(true);
      try {
        logger.log('Attempting to save updated reagent:', updatedReagent);
        await onSave(updatedReagent, editingLocation === 'new' ? newLocation.trim() : undefined);
        
        // If the category has changed, release the old SKU
        if (originalCategory !== editingCategory) {
          logger.log('Releasing old SKU:', originalSku);
          await releaseSKU(originalCategory, originalSku, reagent.isPersonal, reagent.lab || 'UNKNOWN');
        }
        
        logger.log('Reagent saved successfully');
        handleClose();
      } catch (error) {
        logger.error('Failed to save reagent:', error);
        // If save failed and category was changed, release the new SKU
        if (originalCategory !== editingCategory) {
          logger.log('Releasing new SKU due to save failure:', editingSku);
          await releaseSKU(editingCategory, editingSku, reagent.isPersonal, reagent.lab || 'UNKNOWN');
        }
        toast({
          title: 'Error',
          description: 'Failed to save reagent. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleDelete = async () => {
    if (reagent && window.confirm('Are you sure you want to delete this reagent?')) {
      try {
        logger.log('Attempting to delete reagent:', reagent);
        await onDelete(reagent.id, reagent.name, reagent.isPersonal);
        logger.log('Releasing SKU after deletion:', reagent.sku);
        await releaseSKU(reagent.category, reagent.sku, reagent.isPersonal, reagent.lab || 'UNKNOWN');
        logger.log('Reagent deleted successfully');
        handleClose();
      } catch (error) {
        logger.error('Failed to delete reagent:', error);
        toast({
          title: 'Error',
          description: 'Failed to delete reagent. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Reagent {reagent?.isPersonal ? '(Personal)' : '(Shared)'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Select
                value={editingCategory}
                onChange={(e) => handleCategoryChange(e.target.value as ReagentCategory)}
                isDisabled={isGeneratingNewSku}
              >
                {Object.entries(ReagentCategory).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>SKU</FormLabel>
              <Input value={editingSku} isReadOnly />
              {isGeneratingNewSku && (
                <Text fontSize="sm" color="orange.500">
                  Generating new SKU... <Spinner size="sm" />
                </Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                value={editingName}
                onChange={(e) => setEditingName(e.target.value)}
                placeholder="Reagent Name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Location</FormLabel>
              <Select
                value={editingLocation}
                onChange={(e) => setEditingLocation(e.target.value)}
              >
                {existingLocations.map((loc) => (
                  <option key={loc} value={loc}>
                    {loc}
                  </option>
                ))}
                <option value="new">Add new location</option>
              </Select>
            </FormControl>
            {editingLocation === 'new' && (
              <FormControl>
                <FormLabel>New Location</FormLabel>
                <Input
                  value={newLocation}
                  onChange={(e) => setNewLocation(e.target.value)}
                  placeholder="New Location"
                />
              </FormControl>
            )}
            <FormControl>
              <FormLabel>Time Left (minutes)</FormLabel>
              <Input
                type="number"
                value={editingTimeLeft}
                onChange={(e) => setEditingTimeLeft(Number(e.target.value))}
                placeholder="Time Left"
              />
              <Text fontSize="sm" color="gray.500">
                {editingTimeLeft !== undefined
                  ? 'Adding time will check out the reagent.'
                  : 'Leave empty to keep current status.'}
              </Text>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
        <Button 
          colorScheme="blue" 
          mr={3} 
          onClick={handleSave} 
          isDisabled={isGeneratingNewSku || isSaving}
          isLoading={isSaving}
        >
          Save
        </Button>
          <Button colorScheme="red" mr={3} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditReagentModal;
