import { useContext, useState, useCallback } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';
import { useAuth } from './useAuth';

interface NotificationError {
  type: 'add' | 'clear' | 'markRead' | 'toggle';
  message: string;
}

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }

  const { user, updateUser } = useAuth();

  const {
    notifications,
    unreadNotificationsCount,
    addNotification: contextAddNotification,
    clearAllNotifications: contextClearAllNotifications,
    markAllNotificationsAsRead: contextMarkAllNotificationsAsRead
  } = context;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<NotificationError | null>(null);

  const handleAddNotification = useCallback(async (message: string, isPersonal: boolean, reagentId: string) => {
    setLoading(true);
    setError(null);
    try {
      await contextAddNotification(message, isPersonal, reagentId);
    } catch (err: any) {
      setError({ type: 'add', message: err.message || 'Error adding notification' });
    } finally {
      setLoading(false);
    }
  }, [contextAddNotification]);

  const handleClearAllNotifications = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await contextClearAllNotifications();
    } catch (err: any) {
      setError({ type: 'clear', message: err.message || 'Error clearing notifications' });
    } finally {
      setLoading(false);
    }
  }, [contextClearAllNotifications]);

  const handleToggleNotifyOnWatched = useCallback(async (value: boolean) => {
    if (!user) return;
    setLoading(true);
    setError(null);
    try {
      await updateUser({ ...user, notifyOnWatched: value });
    } catch (err: any) {
      setError({ type: 'toggle' as const, message: err.message || 'Error toggling notification preference' });
    } finally {
      setLoading(false);
    }
  }, [user, updateUser]);


  const handleMarkAllNotificationsAsRead = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await contextMarkAllNotificationsAsRead();
    } catch (err: any) {
      setError({ type: 'markRead', message: err.message || 'Error marking notifications as read' });
    } finally {
      setLoading(false);
    }
  }, [contextMarkAllNotificationsAsRead]);

  return {
    notifications,
    unreadNotificationsCount,
    loading,
    error,
    addNotification: handleAddNotification,
    clearAllNotifications: handleClearAllNotifications,
    markAllNotificationsAsRead: handleMarkAllNotificationsAsRead,
    toggleNotifyOnWatched: handleToggleNotifyOnWatched,
  };
};