import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useLogger } from '../index';


const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, isLoading } = useAuth();
  const logger = useLogger();
  

  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    const fetchLogoUrl = async () => {
      const storage = getStorage();
      const logoRef = ref(storage, 'logo/main.png');
      try {
        const url = await getDownloadURL(logoRef);
        setLogoSrc(url);
        logger.log('Logo URL fetched successfully');
      } catch (error) {
        logger.error("Error fetching logo URL:", error);
        setLogoError(true);
      }
    };

    fetchLogoUrl();
  }, []);

  const [logoError, setLogoError] = useState(false);

  const handleLogoError = () => {
    logger.error('Failed to load logo');
    setLogoError(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    if (!email || !password) {
      setError('Please enter both email and password.');
      logger.warn('Login attempt with missing email or password');
      return;
    }
    try {
      await login(email, password);
      logger.log('User logged in successfully');
      navigate('/dashboard');
    } catch (err) {
      logger.error('Login error:', err);
      setError('Invalid email or password. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-600 to-blue-500">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md"
      >
        <div className="flex justify-center mb-6">
          {!logoError && logoSrc ? (
            <img 
              src={logoSrc} 
              alt="Swanky Lab" 
              className="w-32 h-32 object-contain" 
              onError={handleLogoError}
            />
          ) : (
            <div className="w-32 h-32 flex items-center justify-center bg-purple-200 rounded-full">
              <span className="text-2xl font-bold text-purple-800">SL</span>
            </div>
          )}
        </div>
        <h2 className="text-3xl font-bold mb-6 text-center text-purple-800">Welcome to Swanky Lab</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6 relative">
            <FaUser className="absolute top-3 left-3 text-purple-500" />
            <input
              className="w-full pl-10 pr-3 py-2 rounded-lg border-2 border-purple-300 outline-none focus:border-purple-500"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6 relative">
            <FaLock className="absolute top-3 left-3 text-purple-500" />
            <input
              className="w-full pl-10 pr-10 py-2 rounded-lg border-2 border-purple-300 outline-none focus:border-purple-500"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="absolute top-3 right-3 text-purple-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-purple-500 to-blue-500 text-white py-2 rounded-lg hover:opacity-90 transition duration-300"
            disabled={isLoading}
          >
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </motion.div>
    </div>
  );
};

export default Login;