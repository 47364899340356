import { useContext, useCallback } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export const useAuth = () => {
  const context = useContext(AuthContext);
  
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const memoizedGetUserProfile = useCallback(context.getUserProfile, [context.getUserProfile]);

  return {
    ...context,
    getUserProfile: memoizedGetUserProfile
  };
};