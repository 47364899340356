import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { profileImageUrls } from '../configs/config';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../hooks/useAuth';
import { Switch, FormControl, FormLabel, Button } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useLogger } from '../index';



const MAX_RETRIES = 3;

const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const { user, updateUser, logout } = useAuth();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [saveMessage, setSaveMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [displayName, setDisplayName] = useState<string>('');
  const [notifyOnWatched, setNotifyOnWatched] = useState(user?.notifyOnWatched ?? false);

  const logger = useLogger();

  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName || '');
      setSelectedImage(user.profileImage);
    }
  }, [user]);

  const fetchImageUrl = useCallback(async (path: string, retries = 0): Promise<string> => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, path);
      return await getDownloadURL(imageRef);
    } catch (error) {
      logger.error(`Failed to load image: ${path}, attempt ${retries + 1}`, error);
      if (retries < MAX_RETRIES) {
        return fetchImageUrl(path, retries + 1);
      }
      return '';
    }
  }, []);

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        profileImageUrls.images.map(path => fetchImageUrl(path))
      );
      const validUrls = urls.filter(url => url !== '');
      setImageUrls(validUrls);
    };

    fetchImageUrls();
  }, [fetchImageUrl]);

  useEffect(() => {
    if (!initialLoadComplete && imageUrls.length > 0) {
      if (user && user.profileImage) {
        setSelectedImage(user.profileImage);
      } else {
        setSelectedImage(imageUrls[0]);
      }
      setInitialLoadComplete(true);
    }
  }, [user, imageUrls, initialLoadComplete]);

  const handleSave = async () => {
    if (user && (selectedImage !== user.profileImage || displayName !== user.displayName || notifyOnWatched !== user.notifyOnWatched)) {
      setIsLoading(true);
      setError(null);
      const updatedUser = {
        ...user,
        profileImage: selectedImage || '',
        displayName: displayName,
        notifyOnWatched: notifyOnWatched,
      };
      try {
        await updateUser(updatedUser);
        setSaveMessage('Profile updated successfully!');
        setTimeout(() => setSaveMessage(''), 3000);
      } catch (err) {
        logger.error('Failed to update profile:', err);
        setError('Failed to update profile. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleImageSelect = (image: string) => {
    setSelectedImage(image);
  };

  const handleImageLoad = (src: string) => {
    logger.info(`Image loaded successfully: ${src}`);
    setLoadedImages(prev => new Set(prev).add(src));
  };

  const handleImageError = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const src = e.currentTarget.src;
    const currentIndex = imageUrls.indexOf(src);
    if (currentIndex < imageUrls.length - 1) {
      const nextImage = imageUrls[currentIndex + 1];
      logger.info(`Attempting to use next available image: ${nextImage}`);
      e.currentTarget.src = nextImage;
    } else {
      logger.info('No more fallback images available, hiding the image element');
      e.currentTarget.style.display = 'none';
    }
  }, [imageUrls]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      logger.error('Logout failed:', err);
      setError('Failed to logout. Please try again.');
    }
  };

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">User Profile</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Display Name</label>
        <input
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Profile Image</label>
        <div className="flex space-x-4">
          <div className="w-32 h-32 border-2 border-gray-300 rounded-md overflow-hidden">
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected Profile"
                className="w-full h-full object-cover"
                onError={handleImageError}
                onLoad={() => handleImageLoad(selectedImage)}
              />
            )}
          </div>
          <div className="flex-1 flex flex-wrap gap-2 overflow-y-auto max-h-32">
            {imageUrls.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Profile ${index}`}
                className={`cursor-pointer border-2 w-12 h-12 object-cover ${
                  selectedImage === image ? 'border-blue-500' : 'border-transparent'
                } ${loadedImages.has(image) ? '' : 'opacity-50'}`}
                onClick={() => handleImageSelect(image)}
                onError={handleImageError}
                onLoad={() => handleImageLoad(image)}
              />
            ))}
          </div>
        </div>
      </div>
      <FormControl display="flex" alignItems="center" mb={4}>
        <FormLabel htmlFor="notify-watched" mb="0" className="text-sm font-medium">
          Notify when watched shared reagents become available
        </FormLabel>
        <Switch
          id="notify-watched"
          isChecked={notifyOnWatched}
          onChange={(e) => setNotifyOnWatched(e.target.checked)}
        />
      </FormControl>
      <button
        onClick={handleSave}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-4"
        disabled={isLoading}
      >
        {isLoading ? 'Saving...' : 'Save'}
      </button>
      <Button
        leftIcon={<FiLogOut />}
        onClick={handleLogout}
        colorScheme="red"
        variant="outline"
        w="full"
      >
        Logout
      </Button>
      {saveMessage && <p className="mt-2 text-green-600">{saveMessage}</p>}
      {error && <p className="mt-2 text-red-600">{error}</p>}
    </div>
  );
};

export default UserProfile;