import { useState, useEffect, useCallback } from 'react';
import { Timestamp } from 'firebase/firestore';
import { reagentService } from '../services/reagentService';

export function useServerTime() {
  const [serverTimeOffset, setServerTimeOffset] = useState(0);

  const syncTime = useCallback(async () => {
    const start = Date.now();
    const serverTime = await reagentService.getServerTime();
    const end = Date.now();
    const latency = (end - start) / 2;
    const offset = serverTime - (end - latency);
    setServerTimeOffset(offset);
  }, []);

  useEffect(() => {
    syncTime();
    const intervalId = setInterval(syncTime, 60000); // Sync every minute
    return () => clearInterval(intervalId);
  }, [syncTime]);

  const getServerTime = useCallback(() => {
    return Date.now() + serverTimeOffset;
  }, [serverTimeOffset]);

  const getServerTimestamp = useCallback(() => {
    return Timestamp.fromDate(new Date(getServerTime()));
  }, [getServerTime]);

  return { getServerTime, getServerTimestamp };
}