import { collection, doc, setDoc, getDoc, getDocs, Timestamp, DocumentData, updateDoc, writeBatch, arrayUnion, FieldValue } from 'firebase/firestore';
import { db } from './firebaseService';
import { HistoryEntry, User } from '../components/types';
import { subDays, format, isAfter, eachDayOfInterval } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import logger from '../utils/logger';


export const historyService = {
  async addHistoryEntry(entry: Omit<HistoryEntry, 'timestamp'>, user: User): Promise<void> {
    const date = format(new Date(), 'yyyy-MM-dd');
    const now = new Date();
    const entryWithId = {
      ...entry,
      id: entry.id || uuidv4(),
      timestamp: Timestamp.fromDate(now),
      user: user.displayName || 'Unknown User',
      userId: user.id,
      ...(entry.duration !== undefined && { duration: entry.duration })
    };
  
    try {
      if (entry.isPersonal) {
        const historyRef = doc(db, 'history', 'personal', user.id, date);
        await setDoc(historyRef, {
          entries: arrayUnion(entryWithId)
        }, { merge: true });
      } else {
        const historyRef = doc(db, 'history', 'shared', 'all', date);
        await setDoc(historyRef, {
          entries: arrayUnion(entryWithId)
        }, { merge: true });
      }
      logger.info('History entry added successfully');
    } catch (error) {
      logger.error('Error adding history entry:', error);
      throw error;
    }
  },

  async fetchPaginatedHistoryForDate(user: User, date: Date, page: number, pageSize: number, isPersonal: boolean): Promise<{ entries: HistoryEntry[], totalEntries: number }> {
    const dateString = format(date, 'yyyy-MM-dd');
    const docRef = isPersonal
      ? doc(db, 'history', 'personal', user.id, dateString)
      : doc(db, 'history', 'shared', 'all', dateString);
    
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      const allEntries = this.parseHistoryEntries(data, isPersonal);
      
      // Filter personal entries to only include the current user's entries
      const filteredEntries = isPersonal 
        ? allEntries.filter(entry => entry.userId === user.id)
        : allEntries;

      const totalEntries = filteredEntries.length;
      const startIndex = (page - 1) * pageSize;
      const paginatedEntries = filteredEntries
        .sort((a, b) => this.compareTimestamps(b.timestamp, a.timestamp))
        .slice(startIndex, startIndex + pageSize);
  
      return { entries: paginatedEntries, totalEntries };
    }
    return { entries: [], totalEntries: 0 };
  },

  async fetchRecentHistory(user: User, days: number = 7): Promise<{ personalEntries: HistoryEntry[], sharedEntries: HistoryEntry[] }> {
    logger.info('historyService: Fetching recent history for user:', user.id, 'for the last', days, 'days');
    const recentDate = subDays(new Date(), days);
    const dateRange = eachDayOfInterval({ start: recentDate, end: new Date() });
  
    const fetchEntries = async (isPersonal: boolean) => {
      const entries: HistoryEntry[] = [];
      for (const date of dateRange) {
        const dateString = format(date, 'yyyy-MM-dd');
        const docRef = isPersonal
          ? doc(db, 'history', 'personal', user.id, dateString)
          : doc(db, 'history', 'shared', 'all', dateString);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          entries.push(...this.parseHistoryEntries(docSnap.data(), isPersonal));
        }
      }
      return entries;
    };
  
    const [personalEntries, sharedEntries] = await Promise.all([
      fetchEntries(true),
      fetchEntries(false)
    ]);
  
    logger.info('historyService: Fetched personal entries:', personalEntries.length);
    logger.info('historyService: Fetched shared entries:', sharedEntries.length);
  
    return {
      personalEntries: personalEntries.sort((a, b) => this.compareTimestamps(b.timestamp, a.timestamp)),
      sharedEntries: sharedEntries.sort((a, b) => this.compareTimestamps(b.timestamp, a.timestamp))
    };
  },

  async fetchHistoryRange(user: User, startDate: Date, endDate: Date, isPersonal: boolean): Promise<HistoryEntry[]> {
    const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
    const allEntries: HistoryEntry[] = [];

    for (const date of dateRange) {
      const dateString = format(date, 'yyyy-MM-dd');
      const docRef = isPersonal
        ? doc(db, 'history', 'personal', user.id, dateString)
        : doc(db, 'history', 'shared', 'all', dateString);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const entries = this.parseHistoryEntries(docSnap.data(), isPersonal);
          allEntries.push(...entries);
        }
      } catch (error) {
        logger.error(`Error fetching history for date ${dateString}:`, error);
      }
    }

    return allEntries;
  },

  async updateHistoryEntry(entry: HistoryEntry, user: User): Promise<void> {
    const date = entry.timestamp instanceof Timestamp 
      ? format(entry.timestamp.toDate(), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'); // Use current date if timestamp is FieldValue
    const docRef = entry.isPersonal
      ? doc(db, 'history', 'personal', user.id, date)
      : doc(db, 'history', 'shared', 'all', date);
    
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      const updatedEntries = data.entries.map((e: HistoryEntry) => 
        e.id === entry.id ? { ...e, ...entry } : e
      );
      
      await updateDoc(docRef, { entries: updatedEntries });
    }
  },

  async clearOldHistory(user: User, days: number = 30): Promise<void> {
    const cutoffDate = subDays(new Date(), days);

    const deleteOldDocs = async (isPersonal: boolean) => {
      const historyCollection = isPersonal
        ? collection(db, 'history', 'personal', user.id)
        : collection(db, 'history', 'shared', 'all');
      const snapshot = await getDocs(historyCollection);
      
      const batch = writeBatch(db);
      snapshot.docs.forEach((doc) => {
        const docDate = new Date(doc.id);
        if (isAfter(cutoffDate, docDate)) {
          batch.delete(doc.ref);
        }
      });
      
      await batch.commit();
    };

    await Promise.all([
      deleteOldDocs(true),
      deleteOldDocs(false)
    ]);
  },

  parseHistoryEntries(data: DocumentData, isPersonal: boolean = true): HistoryEntry[] {
    if (!data.entries || !Array.isArray(data.entries)) {
      logger.warn('No entries found or entries is not an array');
      return [];
    }
    return data.entries.map((entry: any) => ({
      ...entry,
      id: entry.id || uuidv4(),
      timestamp: entry.timestamp instanceof Timestamp ? entry.timestamp : Timestamp.fromDate(new Date(entry.timestamp)),
      userId: entry.userId || '',
      user: entry.user || 'Unknown User',
      isPersonal: isPersonal,
      duration: entry.duration || undefined
    }));
  },

  compareTimestamps(a: Timestamp | FieldValue, b: Timestamp | FieldValue): number {
    const aTime = a instanceof Timestamp ? a.toDate().getTime() : 0;
    const bTime = b instanceof Timestamp ? b.toDate().getTime() : 0;
    return bTime - aTime;
  }
};

export default historyService;