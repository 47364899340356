import { collection, addDoc, query, where, getDocs, updateDoc, Timestamp, writeBatch, doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseService';
import { Notification, Reagent, HistoryEntry, User } from '../components/types';
import { differenceInMinutes } from 'date-fns';
import { getAuth } from 'firebase/auth';
import logger from '../utils/logger';

export const notificationService = {
  async checkReagentAlerts(reagents: Reagent[]): Promise<{ notifications: Notification[], historyEntries: HistoryEntry[] }> {
    const notifications: Notification[] = [];
    const historyEntries: HistoryEntry[] = [];
    const now = new Date();
  
    for (const reagent of reagents) {
      if (reagent.status === 'In Use' && reagent.checkoutTime && reagent.duration && reagent.dueTime) {
        const dueTime = reagent.dueTime.toDate();
        const minutesLeft = Math.round(differenceInMinutes(dueTime, now));
  
        if (minutesLeft === 15 && !reagent.notifiedAt15) {
          notifications.push(await this.createNotification(reagent, 15, false));
          await this.updateReagentNotificationStatus(reagent.id, { notifiedAt15: true });
        } else if (minutesLeft <= 0) {
          const minutesOverdue = Math.abs(minutesLeft);
          const lastNotificationTime = reagent.lastOverdueNotification?.toDate();
          
          if (!reagent.notifiedAtDue) {
            notifications.push(await this.createNotification(reagent, 0, true));
            historyEntries.push(this.createHistoryEntry(reagent, `${reagent.name} is now overdue.`));
            await this.updateReagentNotificationStatus(reagent.id, { 
              notifiedAtDue: true, 
              lastOverdueNotification: Timestamp.now() 
            });
          } else if (!lastNotificationTime || differenceInMinutes(now, lastNotificationTime) >= 30) {
            // Only send notifications for up to 2 hours after being overdue
            if (minutesOverdue <= 120) {
              notifications.push(await this.createNotification(reagent, minutesOverdue, true));
              historyEntries.push(this.createHistoryEntry(reagent, `${reagent.name} is overdue by ${minutesOverdue} minutes.`));
              await this.updateReagentNotificationStatus(reagent.id, { 
                lastOverdueNotification: Timestamp.now() 
              });
            }
          }
        }
      } else if (reagent.status === 'Available' && !reagent.isPersonal && reagent.watchedBy && reagent.watchedBy.length > 0) {
        // Notify users who are watching this shared reagent and have notifications enabled
        for (const userId of reagent.watchedBy) {
          const user = await this.getUserById(userId);
          if (user && user.notifyOnWatched) {
            notifications.push(await this.createNotification(reagent, 0, false, userId, true));
          }
        }
      }
    }
  
    return { notifications, historyEntries };
  },

  async createNotification(reagent: Reagent, minutes: number, isOverdue: boolean, userId?: string, isWatchedAvailable: boolean = false): Promise<Notification> {
    let message: string;
    if (isWatchedAvailable) {
      message = `Watched reagent ${reagent.name} is now available.`;
    } else {
      message = isOverdue
        ? `${reagent.name} is overdue by ${minutes} minutes.`
        : `${reagent.name} is due for check-in in ${minutes} minutes.`;
    }

    const notification: Omit<Notification, 'id'> = {
      message,
      timestamp: Timestamp.now(),
      read: false,
      isPersonal: reagent.isPersonal,
      reagentId: reagent.id,
      userId: userId || reagent.checkedOutBy?.id || ''
    };

    const docRef = await addDoc(collection(db, 'notifications'), notification);
    return { id: docRef.id, ...notification };
  },

  createHistoryEntry(reagent: Reagent, action: string): HistoryEntry {
    return {
      id: '', // This will be set when adding to Firestore
      timestamp: Timestamp.now(),
      action,
      user: reagent.checkedOutBy?.displayName || 'Unknown',
      userId: reagent.checkedOutBy?.id || '',
      isPersonal: reagent.isPersonal,
      reagent: reagent.name
    };
  },

  async updateReagentNotificationStatus(reagentId: string, updateData: Partial<Reagent>): Promise<void> {
    const reagentRef = doc(db, 'reagents', reagentId);
    await updateDoc(reagentRef, updateData);
  },

  async getRecentNotifications(userId: string): Promise<Notification[]> {
    try {
      const notificationsRef = collection(db, 'notifications');
      const q = query(
        notificationsRef, 
        where('userId', '==', userId),
        where('timestamp', '>=', Timestamp.fromDate(new Date(Date.now() - 48 * 60 * 60 * 1000)))
      );
      const querySnapshot = await getDocs(q);
      const notifications = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Notification));
      
      logger.log(`Retrieved ${notifications.length} recent notifications for user: ${userId}`);
      return notifications;
    } catch (error) {
      logger.error('Error getting recent notifications:', error);
      throw error;
    }
  },

  async clearAllNotifications(userId: string): Promise<void> {
    try {
      const notificationsRef = collection(db, 'notifications');
      const q = query(notificationsRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();
      
      logger.log(`All notifications cleared for user: ${userId}`);
    } catch (error) {
      logger.error('Error clearing all notifications:', error);
      throw error;
    }
  },

  async markAllNotificationsAsRead(userId: string): Promise<void> {
    try {
      const notificationsRef = collection(db, 'notifications');
      const q = query(notificationsRef, where('userId', '==', userId), where('read', '==', false));
      const querySnapshot = await getDocs(q);
      
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { read: true });
      });
      await batch.commit();
      
      logger.log(`All notifications marked as read for user: ${userId}`);
    } catch (error) {
      logger.error('Error marking all notifications as read:', error);
      throw error;
    }
  },

  async addNotification(notification: Omit<Notification, 'id'>): Promise<string> {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');
  
    logger.log('Attempting to add notification:', notification);
    try {
      const notificationWithUserId = {
        ...notification,
        userId: user.uid // Ensure userId is always set to the current user's ID
      };
      const docRef = await addDoc(collection(db, 'notifications'), notificationWithUserId);
      logger.log('Notification added successfully, ID:', docRef.id);
      return docRef.id;
    } catch (error) {
      logger.error('Error adding notification:', error);
      if (error instanceof Error) {
        logger.error('Error message:', error.message);
        logger.error('Error stack:', error.stack);
      }
      throw error;
    }
  },

  async getUserById(userId: string): Promise<User | null> {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return { 
        id: userDoc.id, 
        ...userData,
        notifyOnWatched: userData.notifyOnWatched || false
      } as User;
    }
    return null;
  },
};

export default notificationService;