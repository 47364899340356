import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Box, Flex, useMediaQuery, Spinner, ChakraProvider } from '@chakra-ui/react';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';
import { CombinedProvider } from './contexts/CombinedProvider';
import Sidebar from './components/Sidebar';
import MobileNavigation from './components/MobileNavigation';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Inventory from './components/Inventory';
import FullHistory from './components/FullHistory';
import UserProfile from './components/UserProfile';
import MobileWatchlist from './components/MobileWatchlist';
import Notifications from './components/Notifications';

const AppContent: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { isAuthenticated, isLoading } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setIsSidebarOpen(false);
  }, [location]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <Box className="App" minHeight="100vh">
      <Flex direction="column" height="100vh">
        <Flex flex={1} overflow="hidden">
          {isLargerThan768 && (
            <Box width="240px" position="fixed" top={0} left={0} bottom={0} bg="gray.800">
              <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
            </Box>
          )}
          <Box
            flex={1}
            marginLeft={isLargerThan768 ? "240px" : "0"}
            transition="margin-left 0.3s"
            overflowY="auto"
          >
            <Box p={6}>
              <Routes>
                {isLargerThan768 ? (
                  <Route path="/dashboard" element={<Dashboard />} />
                ) : (
                  <Route path="/dashboard" element={<Navigate to="/inventory" replace />} />
                )}
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/full-history" element={<FullHistory />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/profile" element={<UserProfile />} />
                {!isLargerThan768 && (
                  <Route path="/watchlist" element={<MobileWatchlist />} />
                )}
                <Route path="/" element={<Navigate to={isLargerThan768 ? "/dashboard" : "/inventory"} replace />} />
              </Routes>
            </Box>
          </Box>
        </Flex>
        {!isLargerThan768 && <MobileNavigation />}
      </Flex>
    </Box>
  );
};

const App: React.FC = () => {
  return (
    <ChakraProvider>
      <Router>
        <AuthProvider>
          <AppProvider>
            <CombinedProvider>
              <AppContent />
            </CombinedProvider>
          </AppProvider>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
};

export default App;