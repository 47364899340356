import React, { useMemo, useState, useEffect, memo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaFlask, FaHistory, FaBell, FaEye, FaMapMarkerAlt, FaChevronDown, FaChevronUp, FaCheck, FaChevronLeft, FaClock, FaExpandAlt } from 'react-icons/fa';
import { Box, Flex, VStack, useMediaQuery, Collapse, IconButton, Button, Text, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, useToast } from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';
import { useReagent } from '../hooks/useReagent';
import { useNotification } from '../hooks/useNotification';
import { getDisplayName } from '../utils/userUtils';
import { formatTimeLeft } from '../utils/reagentUtils';
import '../index.css';

import ProfileImage from './ProfileImage';
import CheckoutModal from './CheckoutModal';
import { useCheckout } from '../hooks/useCheckout';
import { Reagent } from './types';
import { useLogger } from '../index';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = memo(({ isOpen, onClose }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { user, userProfileCache } = useAuth();
  const { reagents, toggleReagentStatus, extendReagentDuration, setReagents } = useReagent();
  const { markAllNotificationsAsRead, unreadNotificationsCount } = useNotification();
  const navigate = useNavigate();
  const [isWatchlistOpen, setIsWatchlistOpen] = useState(false);
  const [isActiveReagentsOpen, setIsActiveReagentsOpen] = useState(false);
  const { checkoutModalData, handleCheckout, handleCheckoutConfirm, setCheckoutModalData } = useCheckout();
  const [extendingReagent, setExtendingReagent] = useState<Reagent | null>(null);
  const [extendDuration, setExtendDuration] = useState<number>(60);
  const logger = useLogger();
  const toast = useToast();
  const { addNotification } = useNotification();

  useEffect(() => {
    logger.info('Reagents in Sidebar:', reagents);
  }, [reagents]);

  const watchedReagents = useMemo(() => {
    if (!reagents || !user) return [];
    return reagents.filter((reagent) => reagent.watchedBy?.includes(user.id));
  }, [reagents, user]);

  const activeReagents = useMemo(() => {
    if (!reagents || !user) return [];
    return reagents.filter((reagent) => 
      reagent.checkedOutBy?.id === user.id && 
      (reagent.status === 'In Use' || reagent.status === 'Overdue')
    );
  }, [reagents, user]);
  
  const handleCheckIn = async (reagentId: string, reagentName: string) => {
    if (user) {
      try {
        await toggleReagentStatus(reagentId, 0, false);
        toast({
          title: "Reagent Checked In",
          description: `Reagent "${reagentName}" checked in successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        addNotification(`Reagent "${reagentName}" checked in successfully.`, false, reagentId);
        // Update the local state to reflect the change
        setReagents(prevReagents => 
          prevReagents.map(r => 
            r.id === reagentId 
              ? { ...r, status: 'Available', checkedOutBy: null, timeLeft: null } 
              : r
          )
        );
      } catch (error) {
        logger.error('Error checking in reagent:', error);
        toast({
          title: "Check In Error",
          description: `Failed to check in reagent "${reagentName}".`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        addNotification(`Failed to check in reagent "${reagentName}".`, false, reagentId);
      }
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
    onClose();
  };

  const handleNotificationClick = () => {
    markAllNotificationsAsRead();
    navigate('/notifications');
    onClose();
  };

  const handleExtend = (reagent: Reagent) => {
    setExtendingReagent(reagent);
    setExtendDuration(60); // Default to 60 minutes
  };

  const confirmExtend = async () => {
    if (extendingReagent) {
      try {
        await extendReagentDuration(extendingReagent.id, extendDuration);
        setExtendingReagent(null);
        // The reagents state will be updated by the context
      } catch (error) {
        logger.error('Error extending reagent duration:', error);
        // You might want to show an error notification here
      }
    }
  };

  const NavContent = () => (
    <VStack spacing={4} align="stretch" w="full" h="full">
      <Box>
        <NavLink 
          to="/dashboard" 
          onClick={onClose} 
          className="px-4 py-2 hover:bg-gray-700 text-base font-medium block"
        >
          <FaFlask className="inline-block mr-2" /> Inventory
        </NavLink>
        <NavLink 
          to="/full-history" 
          onClick={onClose} 
          className="px-4 py-2 hover:bg-gray-700 text-base font-medium block"
        >
          <FaHistory className="inline-block mr-2" /> History
        </NavLink>
        <Box 
          onClick={handleNotificationClick} 
          className="px-4 py-2 hover:bg-gray-700 cursor-pointer text-base font-medium"
        >
          <FaBell className="inline-block mr-2" /> Notifications
          {unreadNotificationsCount > 0 && (
            <span className="ml-2 w-3 h-3 bg-green-500 rounded-full inline-block"></span>
          )}
        </Box>
      </Box>
  
      <Box flex={1} overflowY="auto" css={{
        '&::-webkit-scrollbar': { width: '8px' },
        '&::-webkit-scrollbar-track': { background: 'transparent' },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '24px',
          '&:hover': { background: 'rgba(255, 255, 255, 0.2)' },
        },
      }}>
        <Box className="px-4 py-2">
          <h3 className="font-bold mb-2 flex items-center text-sm">
            <FaEye className="mr-2" /> Watchlist
            <IconButton
              aria-label="Toggle Watchlist"
              icon={isWatchlistOpen ? <FaChevronUp /> : <FaChevronDown />}
              size="sm"
              variant="ghost"
              onClick={() => setIsWatchlistOpen(!isWatchlistOpen)}
              ml="auto"
              color="gray.300"
              _hover={{ color: "white", bg: "gray.700" }}
            />
          </h3>
              <Collapse in={isWatchlistOpen}>
          {watchedReagents.length > 0 ? (
            <Box mt={2}>
              {watchedReagents.map((reagent) => (
                <Box
                  key={reagent.id}
                  mb={2}
                  p={2}
                  borderRadius="md"
                  bg="rgba(255, 255, 255, 0.1)"
                >
                  <Flex justify="space-between" align="center" mb={1}>
                    <Text fontSize="xs" fontWeight="bold">
                      {reagent.name}
                    </Text>
                    <Text fontSize="xs" color="gray.400">
                      SKU: {reagent.sku || 'N/A'}
                    </Text>
                  </Flex>
                  <Flex align="center" mb={1}>
                    <Box
                      w={2}
                      h={2}
                      borderRadius="full"
                      bg={reagent.status === 'Available' ? 'green.500' : reagent.status === 'Overdue' ? 'red.500' : 'orange.500'}
                      mr={2}
                    />
                    <Text fontSize="xs">{reagent.status}</Text>
                  </Flex>
                  {reagent.status !== 'Available' && (
                    <Flex align="center" mb={1}>
                      <FaClock size={10} style={{ marginRight: '4px' }} />
                      <Text fontSize="xs">
                        {formatTimeLeft(reagent.timeLeft, reagent.status)}
                      </Text>
                    </Flex>
                  )}
                  <Flex align="center" mb={1}>
                    <FaMapMarkerAlt size={10} style={{ marginRight: '4px' }} />
                    <Text fontSize="xs">{reagent.location || 'No location specified'}</Text>
                  </Flex>
                  {reagent.checkedOutBy && (
                    <Flex align="center" mb={1}>
                      <ProfileImage 
                        user={userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy} 
                        size="small" 
                      />
                      <Text fontSize="xs" ml={1}>
                        {getDisplayName(userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy)}
                      </Text>
                    </Flex>
                  )}
                  {reagent.status === 'Available' && (
                    <Button
                      size="xs"
                      colorScheme="green"
                      mt={1}
                      onClick={() => {
                        logger.info('Checkout button clicked for reagent:', reagent);
                        handleCheckout(reagent.id, reagent.name);
                      }}
                    >
                      Check Out
                    </Button>
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <Text fontSize="sm" color="gray.400" mt={2}>
              No watched reagents
            </Text>
          )}
        </Collapse>
        </Box>
        <Box className="px-4 py-2">
          <h3 className="font-bold mb-2 flex items-center text-sm">
            <FaCheck className="mr-2" /> Active Reagents
            <IconButton
              aria-label="Toggle Active Reagents"
              icon={isActiveReagentsOpen ? <FaChevronUp /> : <FaChevronDown />}
              size="sm"
              variant="ghost"
              onClick={() => setIsActiveReagentsOpen(!isActiveReagentsOpen)}
              ml="auto"
              color="gray.300"
              _hover={{ color: "white", bg: "gray.700" }}
            />
          </h3>
          <Collapse in={isActiveReagentsOpen}>
            {activeReagents.length > 0 ? (
              <Box mt={2}>
                {activeReagents.map((reagent) => (
                  <Box
                    key={reagent.id}
                    mb={2}
                    p={2}
                    borderRadius="md"
                    bg="rgba(255, 255, 255, 0.1)"
                    className={`entry-box ${reagent.status === 'Overdue' ? 'flashing' : ''}`}
                  >
                    <Flex justify="space-between" align="center" mb={1}>
                      <Text fontSize="xs" fontWeight="bold">
                        {reagent.name}
                      </Text>
                      <Text fontSize="xs" color="gray.400">
                        SKU: {reagent.sku || 'N/A'}
                      </Text>
                    </Flex>
                    <Flex align="center" mb={1}>
                      <Box
                        w={2}
                        h={2}
                        borderRadius="full"
                        bg={reagent.status === 'Overdue' ? 'red.500' : 'orange.500'}
                        mr={2}
                      />
                      <Text fontSize="xs">{reagent.status}</Text>
                    </Flex>
                    <Flex align="center" mb={1}>
                      <FaMapMarkerAlt size={10} style={{ marginRight: '4px' }} />
                      <Text fontSize="xs">{reagent.location || 'No location specified'}</Text>
                    </Flex>
                    <Flex align="center" mb={1}>
                      <FaClock size={10} style={{ marginRight: '4px' }} />
                      <Text fontSize="xs">{formatTimeLeft(reagent.timeLeft, reagent.status)}</Text>
                    </Flex>
                    <Flex align="center" justify="space-between" mt={1}>
                      <Flex align="center">
                        <ProfileImage user={user} size="small" />
                        <Text fontSize="xs" ml={1}>{getDisplayName(user)}</Text>
                      </Flex>
                    </Flex>
                    <Flex justify="space-between" align="center" mt={2}>
                      <Button
                        size="xs"
                        colorScheme="orange"
                        onClick={() => handleCheckIn(reagent.id, reagent.name)}
                      >
                        Check In
                      </Button>
                      <Tooltip label="Extend Duration" placement="top">
                        <IconButton
                          aria-label="Extend Duration"
                          icon={<FaExpandAlt />}
                          size="xs"
                          colorScheme="blue"
                          onClick={() => handleExtend(reagent)}
                        />
                      </Tooltip>
                      <Text
                        fontSize="xs"
                        fontStyle="italic"
                        color="gray.400"
                      >
                        {reagent.isPersonal ? 'Personal' : 'Shared'}
                      </Text>
                    </Flex>
                  </Box>
                ))}
              </Box>
            ) : (
              <Text fontSize="sm" color="gray.400" mt={2}>
                No active reagents
              </Text>
            )}
          </Collapse>
        </Box>
      </Box>
    </VStack>
  );
  
  return (
    <>
      {/* Desktop Sidebar */}
      {isLargerThan768 && (
        <Box className="hidden md:flex flex-col bg-gray-800 text-white w-64 py-4 h-screen fixed left-0 top-0">
          <Box className="text-2xl mb-4 px-4">SwankyLab</Box>
          <Flex direction="column" flex={1} overflowY="hidden">
            <NavContent />
          </Flex>
          <Box className="mt-4 px-4">
            <Flex align="center" cursor="pointer" onClick={handleProfileClick} width="fit-content">
              <ProfileImage user={user} size="large" />
              <Text fontSize="sm" ml={2}>{getDisplayName(user)}</Text>
            </Flex>
          </Box>
          <CheckoutModal
            isOpen={checkoutModalData.isOpen}
            onClose={() => setCheckoutModalData({ isOpen: false, reagentId: '', reagentName: '' })}
            onCheckout={handleCheckoutConfirm}
            reagentName={checkoutModalData.reagentName}
          />
        </Box>
      )}
  
      {/* Mobile Sidebar */}
      {!isLargerThan768 && (
        <Box
          className={`md:hidden fixed inset-y-0 left-0 z-30 w-64 bg-gray-800 text-white overflow-y-auto transition-transform duration-300 ease-in-out transform ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <Box className="flex justify-between items-center p-4">
            <Box className="text-2xl">SwankyLab</Box>
            <IconButton
              aria-label="Close menu"
              icon={<FaChevronLeft />}
              onClick={onClose}
              variant="ghost"
            />
          </Box>
          <NavContent />
          <Box className="mt-auto p-4">
            <Flex align="center" cursor="pointer" onClick={handleProfileClick} width="fit-content">
              <ProfileImage user={user} size="large" />
              <Text fontSize="sm" ml={2}>{getDisplayName(user)}</Text>
            </Flex>
          </Box>
        </Box>
      )}

      {/* Extend Duration Modal */}
      <Modal isOpen={!!extendingReagent} onClose={() => setExtendingReagent(null)}>
      <ModalOverlay />
        <ModalContent>
          <ModalHeader>Extend Duration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Enter the number of minutes to extend:</Text>
            <Input 
              type="number" 
              value={extendDuration} 
              onChange={(e) => setExtendDuration(parseInt(e.target.value))}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={confirmExtend}>
              Confirm
            </Button>
            <Button variant="ghost" onClick={() => setExtendingReagent(null)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default Sidebar;