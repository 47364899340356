import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Text,
  Select,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { Reagent, User } from './types';
import { useReagent } from '../hooks/useReagent';
import { serverTimestamp } from 'firebase/firestore';
import { ReagentCategory } from '../contexts/ReagentContext';
import { useLogger } from '../index';
import { useLabContext } from '../contexts/LabContext';

interface AddReagentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddReagent: (reagent: Omit<Reagent, 'id'>) => Promise<void>;
  isPersonal: boolean;
  existingLocations: string[];
  currentUser: User | null;
  existingLabs: string[];
}

const AddReagentModal: React.FC<AddReagentModalProps> = ({
  isOpen,
  onClose,
  existingLocations,
  currentUser,
  onAddReagent,
  isPersonal,
  existingLabs,
}) => {
  const { getNextSKU, releaseSKU } = useReagent();
  const logger = useLogger();
  const toast = useToast();
  const { currentLab, setCurrentLab } = useLabContext();

  // State variables
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [location, setLocation] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [category, setCategory] = useState<ReagentCategory | null>(null);
  const [isGeneratingNewSku, setIsGeneratingNewSku] = useState(false);
  const [lab, setLab] = useState(currentLab || 'MAIN');
  const [newLab, setNewLab] = useState('');

  // Function to reset and close the modal
  const handleClose = () => {
    setName('');
    setLocation('');
    setNewLocation('');
    setCategory(null);
    setLab(currentLab);
    setNewLab('');
    setSku('');
    onClose();
  };

  const handleCategoryChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCategory = e.target.value as ReagentCategory;
    setCategory(newCategory);
    await fetchCurrentSku(newCategory);
  };

  const handleAdd = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate required fields
    if (!name || !sku || (!location && !newLocation) || (!isPersonal && !lab)) {
      logger.warn('User attempted to add reagent with missing fields');
      toast({
        title: 'Missing Information',
        description: 'Please fill in all required fields.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const finalLocation = location === 'new' ? newLocation : location;
    const finalLab = isPersonal
      ? currentUser?.id || ''
      : lab === 'new'
      ? newLab
      : (lab || 'MAIN');

    try {
      if (!finalLab) {
        throw new Error('Invalid Lab ID for SKU assignment.');
      }

      await onAddReagent({
        name,
        sku,
        location: finalLocation,
        originalLocation: finalLocation,
        status: 'Available',
        isPersonal,
        category: category!,
        owner: isPersonal ? currentUser?.id || '' : 'shared',
        checkoutTime: null,
        dueTime: null,
        duration: null,
        timeLeft: null,
        watchedBy: [],
        lastUpdated: serverTimestamp(),
        isWatched: false,
        checkedOutBy: null,
        notifiedAt15: false,
        notifiedAtDue: false,
        lastOverdueNotification: null,
        lab: finalLab,
        createdAt: serverTimestamp(), // Add createdAt
        updatedAt: serverTimestamp(), // Add updatedAt
      });

      // Increment nextSKU
      await getNextSKU(category!, isPersonal, finalLab);

      logger.log(`New ${isPersonal ? 'personal' : 'shared'} reagent added: ${name}`);
      toast({
        title: 'Reagent Added',
        description: `New ${isPersonal ? 'personal' : 'shared'} reagent "${name}" has been added successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // If a new lab was added, update the current lab
      if (!isPersonal && lab === 'new') {
        setCurrentLab(newLab);
      }

      handleClose();
    } catch (error) {
      logger.error('Error adding reagent:', error);

      // Release the reserved SKU since addition failed
      if (sku) {
        await releaseSKU(category!, sku, isPersonal, finalLab).catch((releaseError) => {
          logger.error('Error releasing SKU after failed addition:', releaseError);
        });
      }

      toast({
        title: 'Error',
        description: 'Failed to add reagent. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCurrentSku = useCallback(
    async (newCategory: ReagentCategory) => {
      setIsGeneratingNewSku(true);
      try {
        let labId: string;
        if (isPersonal) {
          labId = currentUser?.id || '';
        } else {
          labId = lab === 'new' ? newLab : (lab || 'MAIN');
        }
  
        if (!labId) {
          throw new Error('Invalid Lab ID for SKU generation.');
        }
  
        const currentSku = await getNextSKU(newCategory, isPersonal, labId);
        if (currentSku !== null) {
          setSku(currentSku);
        } else {
          throw new Error('Failed to get current SKU');
        }
      } catch (error) {
        logger.error('Error fetching current SKU:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch current SKU. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsGeneratingNewSku(false);
      }
    },
    [getNextSKU, isPersonal, currentUser, lab, newLab, logger, toast]
  );

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New {isPersonal ? 'Personal' : 'Shared'} Reagent</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {/* Category Selection */}
            <FormControl isRequired>
              <FormLabel>Category</FormLabel>
              <Select
                value={category || ''}
                onChange={handleCategoryChange}
                placeholder="Select category"
                isDisabled={isGeneratingNewSku}
              >
                {Object.entries(ReagentCategory).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Select>
            </FormControl>

            {/* SKU Display */}
            {category && (
              <FormControl isRequired>
                <FormLabel>SKU</FormLabel>
                <Input value={sku} isReadOnly />
                {isGeneratingNewSku && (
                  <Text fontSize="sm" color="orange.500">
                    Fetching current SKU... <Spinner size="sm" />
                  </Text>
                )}
              </FormControl>
            )}

            {/* Reagent Name */}
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Reagent Name"
              />
            </FormControl>

            {/* Lab Selection (if not personal) */}
            {!isPersonal && (
              <FormControl isRequired>
                <FormLabel>Lab</FormLabel>
                <Select
                  placeholder="Select a lab"
                  value={lab}
                  onChange={(e) => setLab(e.target.value)}
                  isDisabled={isGeneratingNewSku}
                >
                  <option value="MAIN">MAIN</option>
                  {existingLabs.filter(labName => labName !== 'MAIN').map((labName) => (
                    <option key={labName} value={labName}>
                      {labName}
                    </option>
                  ))}
                  <option value="new">Add new lab</option>
                </Select>
              </FormControl>
            )}

            {/* New Lab Input (if "Add new lab" is selected) */}
            {!isPersonal && lab === 'new' && (
              <FormControl isRequired>
                <FormLabel>New Lab</FormLabel>
                <Input
                  value={newLab}
                  onChange={(e) => setNewLab(e.target.value)}
                  placeholder="New Lab Name"
                  isDisabled={isGeneratingNewSku}
                />
              </FormControl>
            )}

            {/* Location Selection */}
            <FormControl isRequired>
              <FormLabel>Location</FormLabel>
              <Select
                placeholder="Select a location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                {existingLocations.map((loc) => (
                  <option key={loc} value={loc}>
                    {loc}
                  </option>
                ))}
                <option value="new">Add new location</option>
              </Select>
            </FormControl>

            {/* New Location Input (if "Add new location" is selected) */}
            {location === 'new' && (
              <FormControl isRequired>
                <FormLabel>New Location</FormLabel>
                <Input
                  value={newLocation}
                  onChange={(e) => setNewLocation(e.target.value)}
                  placeholder="New Location"
                />
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleAdd}
            isDisabled={isGeneratingNewSku}
          >
            Add Reagent
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddReagentModal;