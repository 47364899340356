import React, { createContext, useContext, useState } from 'react';

interface LabContextType {
  currentLab: string;
  setCurrentLab: (lab: string) => void;
}

const LabContext = createContext<LabContextType | undefined>(undefined);

export const LabProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentLab, setCurrentLab] = useState('');

  

  return (
    <LabContext.Provider value={{ currentLab, setCurrentLab }}>
      {children}
    </LabContext.Provider>
  );
};

export const useLabContext = () => {
  const context = useContext(LabContext);
  if (context === undefined) {
    throw new Error('useLabContext must be used within a LabProvider');
  }
  return context;
};