import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel, VStack, Input, InputGroup, InputRightElement, useToast, Select, Flex, useMediaQuery, useDisclosure, Heading, Badge, Text, Button, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import ReagentList from './ReagentList2';
import AddReagentModal from './AddReagentModal';
import MobileNavigation from './MobileNavigation';
import { Reagent } from '../components/types';
import { useNotification } from '../hooks/useNotification';
import CheckoutModal from './CheckoutModal';
import { useCheckout } from '../hooks/useCheckout';
import { useReagent } from '../hooks/useReagent';
import { formatTimeLeft } from '../utils/reagentUtils';
import { FaMapMarkerAlt, FaClock, FaEye, FaEyeSlash, FaEdit } from 'react-icons/fa';
import EditReagentModal from './EditReagentModal';
import ProfileImage from './ProfileImage';
import { getDisplayName } from '../utils/userUtils';
import { useLogger } from '../index';


const Inventory: React.FC = () => {
  const { user, userProfileCache, getUserProfile } = useAuth();
  const { 
    reagents, 
    addReagent, 
    updateReagent, 
    deleteReagent, 
    toggleReagentStatus,
    checkReagentAlerts,
    watchReagent,
    unwatchReagent,
    releaseSKU
  } = useReagent();
  const { addNotification } = useNotification();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortOption, setSortOption] = useState<string>('name');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [locationFilter, setLocationFilter] = useState<string>('all');
  const { checkoutModalData, handleCheckout, handleCheckoutConfirm, setCheckoutModalData } = useCheckout();
  const [editingReagent, setEditingReagent] = useState<Reagent | null>(null);
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
  const [existingLabs, setExistingLabs] = useState<string[]>([]);
  const logger = useLogger();
  const toast = useToast();

  useEffect(() => {
    const savedTab = localStorage.getItem('activeInventoryTab');
    if (savedTab) {
      setActiveTab(parseInt(savedTab));
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      checkReagentAlerts();
    }, 60000); // Check alerts every minute
  
    return () => clearInterval(timer);
  }, [checkReagentAlerts]);

  useEffect(() => {
    // Preload user profiles for checked out reagents
    const preloadUserProfiles = async () => {
      const userIds = new Set(reagents
        .filter(r => r.checkedOutBy && !userProfileCache[r.checkedOutBy.id])
        .map(r => r.checkedOutBy!.id));
      
      for (const userId of userIds) {
        await getUserProfile(userId);
      }
    };

    preloadUserProfiles();
  }, [reagents, userProfileCache, getUserProfile]);

  const handleAddReagent = async (reagent: Omit<Reagent, 'id'>) => {
    try {
      const result = await addReagent(reagent);
      logger.log(`Reagent added successfully: ${result?.name}`);  
      addNotification(`Reagent "${result?.name}" added successfully.`, result?.isPersonal || false, result?.id || '');
    } catch (error) {
      logger.error('Failed to add reagent:', error);
      toast({
        title: "Error",
        description: "Failed to add reagent. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const filterAndSortReagents = (reagents: Reagent[]) => {
    return reagents.filter((reagent: Reagent) => {
      const matchesSearch = reagent.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = statusFilter === 'all' || reagent.status === statusFilter;
      const matchesLocation = locationFilter === 'all' || reagent.location === locationFilter;
      return matchesSearch && matchesStatus && matchesLocation;
    }).sort((a: Reagent, b: Reagent) => {
      switch (sortOption) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'status':
          return a.status.localeCompare(b.status);
        case 'location':
          return a.location.localeCompare(b.location);
        case 'timeLeft':
          const aTimeLeft = a.timeLeft ?? Infinity;
          const bTimeLeft = b.timeLeft ?? Infinity;
          return aTimeLeft - bTimeLeft;
        default:
          return 0;
      }
    });
  };

  const filteredAndSortedPersonalReagents = useMemo(() => 
    filterAndSortReagents(reagents.filter(r => r.isPersonal)), 
    [reagents, searchTerm, statusFilter, locationFilter, sortOption]
  );
  
  const filteredAndSortedSharedReagents = useMemo(() => 
    filterAndSortReagents(reagents.filter(r => !r.isPersonal)), 
    [reagents, searchTerm, statusFilter, locationFilter, sortOption]
  );

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    localStorage.setItem('activeInventoryTab', index.toString());
  };

  const existingLocations = useMemo(() => {
    return Array.from(new Set(reagents.map((r: Reagent) => r.location)));
  }, [reagents]);

  const handleOpenAddReagentModal = () => {
    onOpen();
  };

  // Add this function to the Inventory component
  const handleDeleteReagent = async (id: string, name: string, isPersonal: boolean) => {
    try {
      await deleteReagent(id);
      logger.log(`Reagent deleted successfully: ${name}`);
      addNotification(`Reagent "${name}" deleted successfully.`, isPersonal, id);
      onEditModalClose(); // Close the modal after successful deletion
    } catch (error) {
      logger.error('Failed to delete reagent:', error);
      addNotification(`Failed to delete reagent "${name}".`, isPersonal, id);
    }
  };


  const handleWatchToggle = async (reagent: Reagent) => {
    try {
      if (reagent.watchedBy?.includes(user?.id || '')) {
        await unwatchReagent(reagent.id);
        logger.log(`User unwatched reagent: ${reagent.name}`);
        toast({
          title: "Unwatched",
          description: `You are no longer watching "${reagent.name}".`,
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await watchReagent(reagent.id);
        logger.log(`User started watching reagent: ${reagent.name}`);
        toast({
          title: "Watching",
          description: `You are now watching "${reagent.name}".`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      addNotification(`Watch status changed for "${reagent.name}".`, false, reagent.id);
    } catch (error) {
      logger.error('Error toggling watch status:', error);
      toast({
        title: "Error",
        description: `Failed to ${reagent.watchedBy?.includes(user?.id || '') ? 'unwatch' : 'watch'} reagent.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const handleUpdateReagent = async (updatedReagent: Reagent): Promise<Reagent> => {
    try {
      const result = await updateReagent(updatedReagent);
      logger.log(`Reagent updated successfully: ${result.name}`, result);
      toast({
        title: "Reagent Updated",
        description: `Reagent "${result.name}" updated successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      addNotification(`Reagent "${result.name}" updated successfully.`, result.isPersonal, result.id);
      return result;
    } catch (err) {
      logger.error('Failed to update reagent:', err);
      toast({
        title: "Update Error",
        description: `Failed to update reagent "${updatedReagent.name}".`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      addNotification(`Failed to update reagent "${updatedReagent.name}".`, updatedReagent.isPersonal, updatedReagent.id);
      throw err;
    }
  };

  const handleCheckoutOrIn = async (reagentId: string, reagentName: string, currentStatus: string, checkedOutById: string | undefined) => {
    const isCheckedOutByCurrentUser = checkedOutById === user?.id;
    if (currentStatus === 'Available') {
      // For check-out, open the modal
      handleCheckout(reagentId, reagentName);
      logger.log(`Opened checkout modal for reagent: ${reagentName}`);
    } else if (isCheckedOutByCurrentUser) {
      // For check-in, directly call toggleReagentStatus only if checked out by current user
      try {
        await toggleReagentStatus(reagentId, 0, false);
        logger.log(`Reagent checked in: ${reagentName}`);
        toast({
          title: "Reagent Checked In",
          description: `Reagent "${reagentName}" checked in successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        addNotification(`Reagent "${reagentName}" checked in successfully.`, false, reagentId);
      } catch (error) {
        logger.error('Error checking in reagent:', error);
        toast({
          title: "Check-in Error",
          description: `Failed to check in reagent "${reagentName}".`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        addNotification(`Failed to check in reagent "${reagentName}".`, false, reagentId);
      }
    } else {
      logger.warn(`Attempted to check in reagent not checked out by user: ${reagentName}`);
      toast({
        title: "Action Not Allowed",
        description: `You can't check in a reagent that you didn't check out.`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      addNotification(`You can't check in a reagent that you didn't check out.`, false, reagentId);
    }
  };

  const handleEditReagent = (reagent: Reagent) => {
    if (reagent.isPersonal || reagent.status === 'Available' || reagent.checkedOutBy?.id === user?.id) {
      setEditingReagent(reagent);
      onEditModalOpen();
      logger.log(`Opened edit modal for reagent: ${reagent.name}`);
    } else {
      logger.warn(`Attempted to edit reagent checked out by another user: ${reagent.name}`);
      toast({
        title: "Edit Not Allowed",
        description: `You can't edit a reagent that is checked out by another user.`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      addNotification(`You can't edit a reagent that is checked out by another user.`, false, reagent.id);
    }
  };

  const handleSaveEditedReagent = async (updatedReagent: Reagent, newLocation?: string) => {
    try {
      let finalReagent = { ...updatedReagent };
  
      // If the location is 'new', use the newLocation value
      if (updatedReagent.location === 'new' && newLocation) {
        finalReagent.location = newLocation;
      }
  
      await handleUpdateReagent(finalReagent);
      onEditModalClose();
      logger.log(`Reagent updated successfully: ${finalReagent.name}`);
      toast({
        title: "Reagent Updated",
        description: `Reagent "${finalReagent.name}" updated successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      addNotification(`Reagent "${finalReagent.name}" updated successfully.`, finalReagent.isPersonal, finalReagent.id);
    } catch (error) {
      logger.error('Failed to update reagent:', error);
      toast({
        title: "Update Error",
        description: `Failed to update reagent "${updatedReagent.name}".`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      addNotification(`Failed to update reagent "${updatedReagent.name}".`, updatedReagent.isPersonal, updatedReagent.id);
    }
  };

  const renderInventoryContent = (isPersonal: boolean) => (
    <Box width="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <InputGroup maxWidth="300px">
          <Input
            placeholder="Search reagents..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            bg="white"
            borderRadius="full"
          />
          <InputRightElement>
            <SearchIcon color="gray.500" />
          </InputRightElement>
        </InputGroup>
        <Button colorScheme="green" onClick={handleOpenAddReagentModal}>
          Add Reagent
        </Button>
      </Flex>
      <Flex justifyContent="flex-end" px={4} mb={4} gap={4}>
        <Select 
          value={sortOption} 
          onChange={(e) => setSortOption(e.target.value)} 
          width="200px"
        >
          <option value="name">Sort by Name</option>
          <option value="status">Sort by Status</option>
          <option value="location">Sort by Location</option>
          <option value="timeLeft">Sort by Time Left</option>
        </Select>
        <Select 
          value={statusFilter} 
          onChange={(e) => setStatusFilter(e.target.value)} 
          width="200px"
        >
          <option value="all">All Statuses</option>
          <option value="Available">Available</option>
          <option value="In Use">In Use</option>
          <option value="Overdue">Overdue</option>
        </Select>
        <Select 
          value={locationFilter} 
          onChange={(e) => setLocationFilter(e.target.value)} 
          width="200px"
        >
          <option value="all">All Locations</option>
          {existingLocations.map((location: string) => (
            <option key={location} value={location}>{location}</option>
          ))}
        </Select>
      </Flex>
      <ReagentList
        isPersonal={isPersonal}
        isMobile={!isLargerThan768}
        reagents={isPersonal ? filteredAndSortedPersonalReagents : filteredAndSortedSharedReagents}
        onSave={handleUpdateReagent}
        onDelete={handleDeleteReagent}
        toggleReagentStatus={toggleReagentStatus}
        onCheckout={handleCheckout}
        onAddReagent={handleOpenAddReagentModal}
        userProfileCache={userProfileCache}
        existingLocations={existingLocations}
        releaseSKU={releaseSKU}
        existingLabs={existingLabs}
      />
    </Box>
  );

  const renderMobileInventoryContent = (isPersonal: boolean) => (
    <Box width="100%">
      <Button colorScheme="green" onClick={handleOpenAddReagentModal} mb={4} width="100%">
        Add Reagent
      </Button>
      <VStack spacing={4} align="stretch">
        {(isPersonal ? filteredAndSortedPersonalReagents : filteredAndSortedSharedReagents).map((reagent: Reagent) => (
          <Box key={reagent.id} p={4} bg="white" borderRadius="md" boxShadow="md">
            <VStack align="stretch" spacing={2}>
              <Flex justify="space-between" align="center">
                <Heading size="md">{reagent.name}</Heading>
                <Badge colorScheme={reagent.status === 'Available' ? 'green' : reagent.status === 'Overdue' ? 'red' : 'yellow'}>
                  {reagent.status}
                </Badge>
              </Flex>
              <Text fontSize="sm" color="gray.500">SKU: {reagent.sku || 'N/A'}</Text>
              <Text>
                <Icon as={FaMapMarkerAlt} mr={2} />
                {reagent.location || 'No location specified'}
              </Text>
              <Text>
                <Icon as={FaClock} mr={2} />
                Time left: {formatTimeLeft(reagent.timeLeft, reagent.status)}
              </Text>
              {!isPersonal && reagent.status !== 'Available' && reagent.checkedOutBy && (
                <Flex align="center">
                  <ProfileImage 
                    user={userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy} 
                    size="small" 
                  />
                  <Text ml={2} fontSize="sm">
                    {getDisplayName(userProfileCache[reagent.checkedOutBy.id] || reagent.checkedOutBy)}
                  </Text>
                </Flex>
              )}
              <Flex justify="space-between" align="center" mt={2}>
                <Button
                  colorScheme={reagent.status === 'Available' ? "yellow" : "orange"}
                  size="sm"
                  onClick={() => handleCheckoutOrIn(reagent.id, reagent.name, reagent.status, reagent.checkedOutBy?.id)}
                  isDisabled={!reagent.isPersonal && reagent.status !== 'Available' && reagent.checkedOutBy?.id !== user?.id}
                >
                  {reagent.status === 'Available' ? 'Check Out' : 'Check In'}
                </Button>
                <Flex gap={2}>
                  <Tooltip label="Edit">
                    <IconButton
                      aria-label="Edit"
                      icon={<FaEdit />}
                      onClick={() => handleEditReagent(reagent)}
                      isDisabled={!reagent.isPersonal && reagent.status !== 'Available' && reagent.checkedOutBy?.id !== user?.id}
                      colorScheme="blue"
                      size="sm"
                    />
                  </Tooltip>
                  {!isPersonal && (
                    <Tooltip label={reagent.watchedBy?.includes(user?.id || '') ? 'Unwatch' : 'Watch'}>
                      <IconButton
                        aria-label={reagent.watchedBy?.includes(user?.id || '') ? 'Unwatch' : 'Watch'}
                        icon={reagent.watchedBy?.includes(user?.id || '') ? <FaEyeSlash /> : <FaEye />}
                        onClick={() => handleWatchToggle(reagent)}
                        colorScheme={reagent.watchedBy?.includes(user?.id || '') ? "teal" : "gray"}
                        size="sm"
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
            </VStack>
          </Box>
        ))}
      </VStack>
    </Box>
  );
  
  return (
    <Box minHeight="100vh" position="relative" pb={isLargerThan768 ? 0 : "60px"}>
      {isLargerThan768 ? (
        // Desktop version
        <Box className="p-6">
          <Box className="inventory-container mb-8">
            <Tabs index={activeTab} onChange={handleTabChange} variant="enclosed">
              <TabList>
                <Tab>Personal Inventory</Tab>
                <Tab>Shared Inventory</Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={0}>
                  {renderInventoryContent(true)}
                </TabPanel>
                <TabPanel p={0}>
                  {renderInventoryContent(false)}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      ) : (
        // Mobile version
        <VStack spacing={0} align="stretch" height="100vh" overflowY="auto">
          <Box bg="blue.400" py={4} mb={4}>
            <Heading as="h1" size="xl" textAlign="center" color="white">Inventory</Heading>
          </Box>
          <Box px={4} py={2}>
            <InputGroup mb={4}>
              <Input
                placeholder="Search reagents..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bg="white"
                borderRadius="full"
              />
              <InputRightElement>
                <SearchIcon color="gray.500" />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" mb={4}>
              <Select 
                value={sortOption} 
                onChange={(e) => setSortOption(e.target.value)} 
                width="48%"
                bg="white"
              >
                <option value="name">Sort by Name</option>
                <option value="status">Sort by Status</option>
                <option value="location">Sort by Location</option>
                <option value="timeLeft">Sort by Time Left</option>
              </Select>
              <Select 
                value={locationFilter} 
                onChange={(e) => setLocationFilter(e.target.value)} 
                width="48%"
                bg="white"
              >
                <option value="all">All Locations</option>
                {existingLocations.map((location: string) => (
                  <option key={location} value={location}>{location}</option>
                ))}
              </Select>
            </Flex>
          </Box>
            <Box flex={1} px={4}>
              <Tabs isFitted variant="soft-rounded" colorScheme="blue" mb={4} index={activeTab} onChange={handleTabChange} className="mobile-tabs">
                <TabList mb="1em">
                  <Tab>Personal</Tab>
                  <Tab>Shared</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p={0}>
                    {renderMobileInventoryContent(true)}
                  </TabPanel>
                  <TabPanel p={0}>
                    {renderMobileInventoryContent(false)}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </VStack>
        )}
      <AddReagentModal 
        isOpen={isOpen}
        onClose={onClose} 
        onAddReagent={handleAddReagent}
        isPersonal={activeTab === 0}
        existingLocations={existingLocations}
        currentUser={user}
        existingLabs={existingLabs}
      />
      <CheckoutModal
        isOpen={checkoutModalData.isOpen}
        onClose={() => setCheckoutModalData({ isOpen: false, reagentId: '', reagentName: '' })}
        onCheckout={(duration) => handleCheckoutConfirm(duration)}
        reagentName={checkoutModalData.reagentName}
      />
      <EditReagentModal
        isOpen={isEditModalOpen}
        onClose={onEditModalClose}
        reagent={editingReagent}
        onSave={handleSaveEditedReagent}
        onDelete={handleDeleteReagent}
        existingLocations={existingLocations}
        
      />
      {!isLargerThan768 && <MobileNavigation />}
    </Box>
  );
};

export default Inventory;