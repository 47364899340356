import React from 'react';
import Inventory from './Inventory';
import { getDisplayName } from '../utils/userUtils';
import { useAuth } from '../contexts/AuthContext';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const displayName = getDisplayName(user);

  return (
    <div className="flex-1 p-6 bg-gray-100 text-gray-900">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Welcome, {displayName}</h1>
      </div>
      <Inventory />
    </div>
  );
};

export default Dashboard;