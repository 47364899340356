import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { User } from '../components/types';
import { authService } from '../services/authService';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc, setDoc, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../services/firebaseService';
import { useLogger } from '../index';
import { ReagentCategory } from './ReagentContext';
import { skuManager } from '../services/SKUManager';

interface UserProfileCache {
  [userId: string]: User;
}

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<{ user: User; token: string }>;
  logout: () => Promise<void>;
  updateUser: (updatedUser: User) => Promise<void>;
  userProfileCache: UserProfileCache;
  getUserProfile: (userId: string) => Promise<User | null>;
  clearUserProfileCache: () => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userProfileCache, setUserProfileCache] = useState<UserProfileCache>({});
  const logger = useLogger();

  const getUserProfile = useCallback(async (userId: string): Promise<User | null> => {
    if (userProfileCache[userId]) {
      return userProfileCache[userId];
    }
    try {
      const userProfile = await authService.getUserInfo(userId);
      if (userProfile) {
        setUserProfileCache(prev => ({ ...prev, [userId]: userProfile }));
        return userProfile;
      }
    } catch (error) {
      logger.error(`Error fetching user profile for ${userId}:`, error);
    }
    return null;
  }, [userProfileCache, logger]);

  const clearUserProfileCache = useCallback(() => {
    setUserProfileCache({});
  }, []);

  const updateUser = useCallback(async (updatedUser: Partial<User>) => {
    if (!user) throw new Error('No authenticated user');
    
    const updatedUserData = { ...user, ...updatedUser };
    await authService.updateUser(updatedUserData);
    setUser(updatedUserData);
    
    // Update Firestore document
    const userRef = doc(db, 'users', user.id);
    await setDoc(userRef, updatedUserData, { merge: true });
  }, [user]);

  const refreshUserState = useCallback(async (firebaseUser: any) => {
    if (firebaseUser) {
      try {
        const token = await firebaseUser.getIdToken(true);
        logger.info('Attempting to read user document:', `users/${firebaseUser.uid}`);
        let userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));

        if (!userDoc.exists()) {
          logger.info('User document does not exist. Creating new document.');
          const newUserData = {
            displayName: firebaseUser.displayName || 'New User',
            profileImage: firebaseUser.photoURL || '',
            notifyOnWatched: false,
          };
          await setDoc(doc(db, 'users', firebaseUser.uid), newUserData);
          userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
        }

        const userData = userDoc.data() as User;
        const updatedUserState: User = {
          id: firebaseUser.uid,
          displayName: userData.displayName || firebaseUser.displayName || 'New User',
          profileImage: userData.profileImage || firebaseUser.photoURL || '',
          notifyOnWatched: userData.notifyOnWatched || false,
        };
        logger.info('Updated user state:', updatedUserState);
        setUser(updatedUserState);
        setIsAuthenticated(true);
        localStorage.setItem('authToken', token);
      } catch (error) {
        logger.error('Error managing user document:', error);
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('authToken');
      }
    } else {
      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('authToken');
    }
    setIsLoading(false);
  }, [logger]);

  const clearGlobalState = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
    setUserProfileCache({});
    // Dispatch a custom event to notify other parts of the application
    window.dispatchEvent(new Event('user-logout'));
  }, []);

  useEffect(() => {
    const unsubscribeUsers = onSnapshot(collection(db, 'users'), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          const updatedUser = { id: change.doc.id, ...change.doc.data() } as User;
          setUserProfileCache(prev => ({
            ...prev,
            [updatedUser.id]: updatedUser
          }));
          
          // If the updated user is the current user, update the user state
          if (user && updatedUser.id === user.id) {
            setUser(updatedUser);
          }
        }
      });
    });

    return () => {
      unsubscribeUsers();
    };
  }, [user]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuthStateChanged = onAuthStateChanged(auth, refreshUserState);

    return () => {
      unsubscribeAuthStateChanged();
    };
  }, [refreshUserState]);

  const login = useCallback(async (email: string, password: string): Promise<{ user: User; token: string }> => {
    const { user, token } = await authService.login(email, password);
    await refreshUserState(user);
    return { user, token };
  }, [refreshUserState]);

  const logout = useCallback(async () => {
    await authService.logout();
    clearGlobalState();
    localStorage.removeItem('authToken');
  }, [clearGlobalState]);

  return (
    <AuthContext.Provider value={{
      user,
      isAuthenticated,
      isLoading,
      login,
      logout,
      updateUser,
      userProfileCache,
      getUserProfile,
      clearUserProfileCache,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};