import { signInWithEmailAndPassword, signOut, getAuth } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebaseService';
import { User } from '../components/types';
import { jwtDecode } from 'jwt-decode';

export const authService = {
  login: async (email: string, password: string): Promise<{ user: User; token: string }> => {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const firebaseUser = userCredential.user;
    const token = await firebaseUser.getIdToken();
    const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
  
    if (!userDoc.exists()) {
      throw new Error('User document does not exist in Firestore');
    }
  
    const userData = userDoc.data() as User;
    return { 
      user: { 
        id: firebaseUser.uid, 
        displayName: userData.displayName || firebaseUser.displayName || null,
        profileImage: userData.profileImage || firebaseUser.photoURL || '',
        notifyOnWatched: userData.notifyOnWatched || false,
      },
      token 
    };
  },

  logout: async () => {
    const auth = getAuth();
    await signOut(auth);
  },

  refreshToken: async (): Promise<string> => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No authenticated user');
    }
    return await user.getIdToken(true);
  },

  getUserInfo: async (userId: string): Promise<User | null> => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      return null;
    }
    const userData = userDoc.data();
    return { 
      id: userDoc.id, 
      ...userData,
      notifyOnWatched: userData.notifyOnWatched || false
    } as User;
  },

  verifyToken: async (token: string): Promise<User> => {
    const decodedToken: { uid: string } = jwtDecode(token);
    const userDoc = await getDoc(doc(db, 'users', decodedToken.uid));
    if (!userDoc.exists()) {
      throw new Error('User document does not exist');
    }
    return { id: userDoc.id, ...userDoc.data() } as User;
  },

  updateUser: async (updatedUser: User): Promise<void> => {
    await setDoc(doc(db, 'users', updatedUser.id), updatedUser, { merge: true });
  },
};