import { doc, runTransaction, updateDoc, arrayUnion, arrayRemove, Firestore, onSnapshot, getDoc, setDoc } from 'firebase/firestore';
import { ReagentCategory } from '../contexts/ReagentContext';
import { createLogger } from '../utils/logger';
import { db } from '../services/firebaseService';

const logger = createLogger(console);

export class SKUManager {
  private db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  private getSkuDocRef(isPersonal: boolean, id: string, category: ReagentCategory) {
    if (isPersonal) {
      if (!id || id === 'UNKNOWN') { // Prevent using 'UNKNOWN' as a user ID
        logger.error('Invalid user ID for personal SKU:', id);
        throw new Error('Invalid user ID for personal SKU.');
      }
    } else {
      if (!id || id === 'UNKNOWN') { // Prevent using 'UNKNOWN' for shared SKUs
        logger.error('Invalid lab ID for shared SKU:', id);
        throw new Error('Invalid lab ID for shared SKU.');
      }
    }

    const path = `skus/${isPersonal ? 'personal' : 'shared'}/${id}/${category}`;
    logger.log('SKU document path:', path);
    logger.log('SKU parameters:', { isPersonal, id, category });
    return doc(this.db, path);
  }

  async getCurrentSKU(category: ReagentCategory, isPersonal: boolean, id: string): Promise<string> {
    logger.log('getCurrentSKU called with:', { category, isPersonal, id });
    const skuDocRef = this.getSkuDocRef(isPersonal, id, category);
  
    const skuDoc = await getDoc(skuDocRef);
    if (!skuDoc.exists()) {
      // If the document doesn't exist, return the default SKU value
      return this.formatSKU(category, isPersonal, id, 1000);
    }
    const skuData = skuDoc.data();
    const nextSKU = skuData.nextSKU || 1000;
    return this.formatSKU(category, isPersonal, id, nextSKU);
  }

  async getNextSKU(category: ReagentCategory, isPersonal: boolean, id: string): Promise<string> {
    logger.log('getNextSKU called with:', { category, isPersonal, id });
    const skuDocRef = this.getSkuDocRef(isPersonal, id, category);
  
    const skuDoc = await getDoc(skuDocRef);
    if (!skuDoc.exists()) {
      // If the document doesn't exist, create it with initial values
      if (id === 'UNKNOWN') {
        throw new Error('Cannot create SKU document with ID "UNKNOWN".');
      }
      const initialData = { nextSKU: 1000, reservedSKUs: [], availableSKUs: [] };
      await setDoc(skuDocRef, initialData);
      return this.formatSKU(category, isPersonal, id, 1000);
    }
    const skuData = skuDoc.data();
    const nextSKU = skuData.nextSKU || 1000;
    return this.formatSKU(category, isPersonal, id, nextSKU);
  }

  async incrementNextSKU(category: ReagentCategory, isPersonal: boolean, id: string): Promise<void> {
    logger.log('incrementNextSKU called with:', { category, isPersonal, id });
    const skuDocRef = this.getSkuDocRef(isPersonal, id, category);
  
    return runTransaction(this.db, async (transaction) => {
      const skuDoc = await transaction.get(skuDocRef);
      if (!skuDoc.exists()) {
        throw new Error('SKU document does not exist.');
      }
      const skuData = skuDoc.data();
      const nextSKU = skuData.nextSKU || 1000;
  
      // Increment the nextSKU value
      transaction.update(skuDocRef, { nextSKU: nextSKU + 1 });
    });
  }

  private formatSKU(category: ReagentCategory, isPersonal: boolean, id: string, count: number): string {
    const prefix = isPersonal ? 'U' : 'S';
    const idPart = id.slice(0, 4).toUpperCase(); // Use the first 4 characters of the full ID
    const numberPart = count.toString().padStart(4, '0');
    logger.log('Formatting SKU:', { prefix, categoryEnum: category, idPart, numberPart });
    return `${prefix}-${category}-${idPart}-${numberPart}`;
  }

  async reserveSKU(sku: string, category: ReagentCategory, isPersonal: boolean, id: string): Promise<void> {
    logger.log('reserveSKU called with:', { sku, category, isPersonal, id });
    const skuDocRef = this.getSkuDocRef(isPersonal, id, category);
    await updateDoc(skuDocRef, {
      reservedSKUs: arrayUnion(sku),
      availableSKUs: arrayRemove(sku)
    });
  }

  async releaseSKU(sku: string, category: ReagentCategory, isPersonal: boolean, id: string): Promise<void> {
    logger.log('releaseSKU called with:', { sku, category, isPersonal, id });
    const skuDocRef = this.getSkuDocRef(isPersonal, id, category);

    try {
      await runTransaction(this.db, async (transaction) => {
        const skuDoc = await transaction.get(skuDocRef);
        if (!skuDoc.exists()) {
          throw new Error('SKU document does not exist.');
        }

        const skuData = skuDoc.data();
        if (!skuData.reservedSKUs || !skuData.reservedSKUs.includes(sku)) {
          logger.warn(`SKU ${sku} is not currently reserved.`);
          return; // Log a warning and return without throwing an error
        }

        // Remove from reservedSKUs
        transaction.update(skuDocRef, {
          reservedSKUs: arrayRemove(sku),
          availableSKUs: arrayUnion(sku) // Add back to availableSKUs
        });

        logger.log('SKU released successfully:', sku);
      });
    } catch (error) {
      logger.error('Error releasing SKU:', error);
      throw error;
    }
  }

  async useNextSKU(category: ReagentCategory, isPersonal: boolean, id: string): Promise<string> {
    logger.log('useNextSKU called with:', { category, isPersonal, id });
    if (!category) {
      logger.error('Category is undefined in useNextSKU');
      throw new Error('Category is undefined');
    }
    const sku = await this.getNextSKU(category, isPersonal, id);
    await this.reserveSKU(sku, category, isPersonal, id);
    return sku;
  }

  // New method to set up Firestore listeners for SKUs
  onSkuChange(category: ReagentCategory, isPersonal: boolean, id: string, callback: (sku: string) => void) {
    const skuDocRef = this.getSkuDocRef(isPersonal, id, category);
    return onSnapshot(skuDocRef, (doc) => {
      if (doc.exists()) {
        const skuData = doc.data();
        if (skuData.nextSKU) {
          const newSKU = this.formatSKU(category, isPersonal, id, skuData.nextSKU);
          callback(newSKU);
        }
      }
    });
  }
}

export const skuManager = new SKUManager(db);