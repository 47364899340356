import React, { useMemo } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { profileImageUrls } from '../configs/config';
import { User } from '../components/types';

interface ProfileImageProps {
  user: User | null;
  size?: 'small' | 'medium' | 'large';
}

const ProfileImage: React.FC<ProfileImageProps> = React.memo(({ user, size = 'medium' }) => {
  const imageUrl = useMemo(() => user?.profileImage || profileImageUrls.default, [user?.profileImage]);
  
  const sizeClass = useMemo(() => {
    switch(size) {
      case 'small': return 'sidebar-profile-image';
      case 'large': return 'sidebar-user-profile-image';
      default: return 'user-profile-image';
    }
  }, [size]);

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = e.currentTarget;
    imgElement.style.display = 'none';
    const parentElement = imgElement.parentElement;
    if (parentElement) {
      parentElement.textContent = user?.displayName?.[0] || 'U';
      parentElement.style.display = 'flex';
      parentElement.style.alignItems = 'center';
      parentElement.style.justifyContent = 'center';
      parentElement.style.backgroundColor = '#ccc';
      parentElement.style.color = '#fff';
      parentElement.style.fontWeight = 'bold';
    }
  };

  return (
    <Box className={sizeClass} style={{ position: 'relative', overflow: 'hidden' }}>
      <Image
        src={imageUrl}
        alt={user?.displayName || 'User Profile'}
        onError={handleImageError}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </Box>
  );
});

ProfileImage.displayName = 'ProfileImage';

export default ProfileImage;