import React, { createContext, useState, useCallback, useEffect, useContext } from 'react';
import { Notification } from '../components/types';
import { notificationService } from '../services/notificationService';
import { useAuth } from '../hooks/useAuth';
import { ReagentContext } from './ReagentContext';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../services/firebaseService';
import { useLogger } from '../index';

type NotificationContextType = {
  notifications: Notification[];
  unreadNotificationsCount: number;
  addNotification: (message: string, isPersonal: boolean, reagentId: string) => Promise<void>;
  clearAllNotifications: () => Promise<void>;
  markAllNotificationsAsRead: () => Promise<void>;
};

export const NotificationContext = createContext<NotificationContextType | null>(null);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const reagentContext = useContext(ReagentContext);
  const reagents = reagentContext ? reagentContext.reagents : [];
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const logger = useLogger();

  useEffect(() => {
    if (!user) return;

    const checkReagentAlerts = async () => {
      if (reagents.length === 0) return;
      const { notifications } = await notificationService.checkReagentAlerts(reagents);
      for (const notification of notifications) {
        await notificationService.addNotification(notification);
      }
    };

    const intervalId = setInterval(checkReagentAlerts, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [user, reagents]);

  useEffect(() => {
    if (!user) return;

    const notificationsRef = collection(db, 'notifications');
    const q = query(notificationsRef, where('userId', '==', user.id));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Notification[];
      setNotifications(newNotifications);
      setUnreadNotificationsCount(newNotifications.filter(n => !n.read).length);
    });

    return () => unsubscribe();
  }, [user]);

  const addNotification = useCallback(async (_message: string, _isPersonal: boolean, _reagentId: string) => {
    logger.warn('addNotification called directly. This should only be called by the notificationService.');
  }, []);

  const clearAllNotifications = useCallback(async () => {
    if (!user) return;
    await notificationService.clearAllNotifications(user.id);
  }, [user]);

  const markAllNotificationsAsRead = useCallback(async () => {
    if (!user) return;
    await notificationService.markAllNotificationsAsRead(user.id);
  }, [user]);

  const contextValue = {
    notifications,
    unreadNotificationsCount,
    addNotification,
    clearAllNotifications,
    markAllNotificationsAsRead,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === null) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};