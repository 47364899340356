import React from 'react';
import { ReagentProvider } from './ReagentContext';
import { NotificationProvider, useNotification } from './NotificationContext';
import { HistoryProvider } from './HistoryContext';
import { LabProvider } from './LabContext'; // Import LabProvider

const ReagentWithNotification: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { addNotification } = useNotification();
  return <ReagentProvider notifyCallback={addNotification}>{children}</ReagentProvider>;
};

export const CombinedProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <HistoryProvider>
      <NotificationProvider>
        <LabProvider> {/* Add LabProvider here */}
          <ReagentWithNotification>
            {children}
          </ReagentWithNotification>
        </LabProvider>
      </NotificationProvider>
    </HistoryProvider>
  );
};