import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Reagent } from '../components/types';
import { reagentService } from '../services/reagentService';
import { useAuth } from '../hooks/useAuth';
import { useAuthRequest } from '../hooks/useAuthRequest';
import { useHistory } from '../hooks/useHistory';
import { differenceInMinutes, addMinutes } from 'date-fns';
import { Timestamp, serverTimestamp } from 'firebase/firestore';
import { notificationService } from '../services/notificationService';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../services/firebaseService';
import { FirebaseError } from 'firebase/app';
import { useServerTime } from '../hooks/useServerTime';
import { useLogger } from '../index';
import { useSKU } from '../hooks/useSKU';
import { skuManager } from '../services/SKUManager';


export enum ReagentCategory {
  CM = "Culture Media",
  GF = "Growth Factors",
  CY = "Cytokines",
  AB = "Antibodies",
  EN = "Enzymes",
  CR = "Cryopreservation Reagents",
  DF = "Differentiation Factors",
  TR = "Transfection Reagents",
  EM = "Extracellular Matrix Proteins",
  SM = "Small Molecules",
  BS = "Buffers and Solutions",
  FD = "Fluorescent Dyes",
  AR = "Analytical Reagents",
  CS = "Cell Separation Materials",
  GN = "General Lab Supplies",
  CH = "Chemicals"
}

interface ReagentContextType {
  reagents: Reagent[];
  loading: boolean;
  error: string | null;
  personalReagents: Reagent[];
  sharedReagents: Reagent[];
  activeReagents: Reagent[];
  addReagent: (newReagent: Partial<Omit<Reagent, 'id'>>) => Promise<Reagent | undefined>;
  updateReagent: (updatedReagent: Reagent) => Promise<Reagent>;
  deleteReagent: (id: string) => Promise<void>;
  toggleReagentStatus: (id: string, duration: number, isPersonal: boolean) => Promise<Reagent | undefined>;
  watchReagent: (id: string) => Promise<void>;
  unwatchReagent: (id: string) => Promise<void>;
  extendReagentDuration: (id: string, duration: number) => Promise<void>;
  checkReagentAlerts: () => void;
  setReagents: React.Dispatch<React.SetStateAction<Reagent[]>>;
  addHistoryEntry: (action: string, isPersonal: boolean, reagent: string, duration?: number) => Promise<void>;
  getReagentByName: (name: string) => Reagent | null;
  getNextSKU: (category: ReagentCategory, isPersonal: boolean, labId: string) => Promise<string | null>;
  releaseSKU: (category: ReagentCategory, sku: string, isPersonal: boolean, labId: string) => Promise<void>;
  saveNextSkus: (updatedNextSkus: Record<ReagentCategory, string | null>, isPersonal: boolean) => Promise<void>;
}

export const ReagentContext = createContext<ReagentContextType | null>(null);

export const ReagentProvider: React.FC<{ 
  children: React.ReactNode, 
  notifyCallback?: (message: string, isPersonal: boolean, reagentId: string) => void 
}> = ({ children, notifyCallback }) => {
  const [reagents, setReagents] = useState<Reagent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState<string | null>(null);
  const { user } = useAuth();
  
  const authRequest = useAuthRequest();
  const { addHistoryEntry } = useHistory();
  const { getServerTime, getServerTimestamp } = useServerTime();
  const logger = useLogger();

  const personalReagents = useMemo(() => reagents.filter(r => r.isPersonal && r.owner === user?.id), [reagents, user]);
  const sharedReagents = useMemo(() => reagents.filter(r => !r.isPersonal), [reagents]);
  const activeReagents = useMemo(() => {
    if (!user) return [];
    return reagents.filter(reagent => 
      reagent.status === 'In Use' && 
      reagent.checkedOutBy?.id === user.id
    );
  }, [reagents, user]);


  

  const { getNextSKU: getSKU, useNextSKU, releaseSKU: releaseSKUFromManager } = useSKU();


  const saveNextSkus = useCallback(async (updatedNextSkus: Record<ReagentCategory, string | null>, isPersonal: boolean) => {
    await setDoc(doc(db, 'nextAvailableSKUs', isPersonal ? 'personalSkus' : 'sharedSkus'), updatedNextSkus, { merge: true });
  }, []);
  

  const getNextSKU = useCallback(async (category: ReagentCategory, isPersonal: boolean, labId: string): Promise<string> => {
    logger.log('getNextSKU called in ReagentContext with:', { category, isPersonal, labId });
  
    if (!user) {
      logger.error('Attempted to get SKU without a logged-in user');
      throw new Error('User not authenticated');
    }
  
    // Validation: Prevent 'UNKNOWN' for personal reagents
    if (isPersonal && (!user.id || user.id === 'UNKNOWN')) {
      logger.error('Invalid user ID for personal SKU:', user.id);
      throw new Error('Invalid user ID for personal SKU.');
    }
  
    // Validation: Prevent 'UNKNOWN' for shared reagents
    if (!isPersonal && (!labId || labId === 'UNKNOWN')) {
      logger.warn('Lab ID is missing or invalid for shared reagent. Defaulting to MAIN.');
      labId = 'MAIN'; // Default to 'MAIN' if labId is invalid
    }
  
    // Determine the correct ID for SKU
    const idForSKU = isPersonal ? user.id : labId;
    logger.log('ID for SKU in ReagentContext:', idForSKU);
  
    try {
      const newSku = await skuManager.getNextSKU(category, isPersonal, idForSKU);
      if (newSku === null) {
        throw new Error('Failed to get new SKU');
      }
      logger.log('Generated new SKU:', newSku);
      return newSku;
    } catch (error) {
      if (error instanceof FirebaseError && error.code === 'permission-denied') {
        logger.error('Permission denied. Please check Firestore security rules.');
      } else {
        logger.error('Error getting next SKU:', error);
      }
      throw error;
    }
  }, [user, logger]);
  
  const addReagent = useCallback(async (newReagent: Partial<Omit<Reagent, 'id'>>): Promise<Reagent | undefined> => {
    if (!user) return undefined;
  
    // Ensure required fields are defined
    if (!newReagent.category) {
      logger.error('Reagent category is required');
      throw new Error('Reagent category is required');
    }
  
    if (newReagent.isPersonal === undefined) {
      logger.error('Reagent isPersonal flag is required');
      throw new Error('Reagent isPersonal flag is required');
    }
  
    try {
      // Generate a new SKU using the updated getNextSKU function
      const newSKU = await getNextSKU(newReagent.category!, newReagent.isPersonal!, newReagent.isPersonal! ? user.id : (newReagent.lab || 'MAIN'));
      logger.log('Generated new SKU:', newSKU);
  
      // Add the new reagent with the generated SKU
      const reagentWithSKU = { ...newReagent, sku: newSKU } as Reagent;
      const addedReagent = await authRequest(() => reagentService.addReagent(reagentWithSKU, user));
      logger.log('Added new reagent:', addedReagent);
  
      setReagents(prevReagents => {
        const existingIndex = prevReagents.findIndex(r => r.id === addedReagent.id);
        if (existingIndex !== -1) {
          const updatedReagents = [...prevReagents];
          updatedReagents[existingIndex] = addedReagent;
          return updatedReagents;
        } else {
          return [...prevReagents, addedReagent];
        }
      });
  
      return addedReagent;
    } catch (error) {
      logger.error('Error adding reagent:', error);
      throw error;
    }
  }, [user, getNextSKU, authRequest, logger]);

  const releaseSKU = useCallback(async (category: ReagentCategory, sku: string, isPersonal: boolean, labId: string) => {
    logger.log('releaseSKU called in ReagentContext with:', { category, sku, isPersonal, labId });
  
    if (!user) {
      logger.error('Attempted to release SKU without a logged-in user');
      throw new Error('User not authenticated');
    }
  
    try {
      // Validation: Prevent 'MAIN' for personal reagents
      if (isPersonal && (!user.id || user.id === 'MAIN')) {
        logger.error('Invalid user ID for releasing SKU:', user.id);
        throw new Error('Invalid user ID for releasing SKU.');
      }
  
      // Validation: Prevent 'UNKNOWN' for shared reagents
      if (!isPersonal && (!labId || labId === 'UNKNOWN')) {
        logger.warn('Lab ID is missing or invalid for releasing SKU. Defaulting to MAIN.');
        labId = 'MAIN'; // Default to 'MAIN' if labId is invalid
      }
  
      // Determine the correct ID for SKU release
      const idForSKU = isPersonal ? user.id : labId;
      logger.log('ID for SKU release:', idForSKU);
  
      // Release the SKU using SKUManager
      await skuManager.releaseSKU(sku, category, isPersonal, idForSKU);
      logger.log('SKU released successfully:', sku);
    } catch (error) {
      logger.error('Error in releaseSKU:', error);
      throw error;
    }
  }, [user, logger]);


  const getReagentByName = useCallback((name: string): Reagent | null => {
    return reagents.find(reagent => reagent.name === name) || null;
  }, [reagents]);

  const updateReagent = useCallback(async (updatedReagent: Reagent, getNextSKUFunc: typeof getNextSKU): Promise<Reagent> => {
    if (!user) {
      logger.error('No authenticated user');
      throw new Error('No authenticated user');
    }
    try {
      logger.log('Attempting to update reagent:', updatedReagent);
      
      // Fetch current reagent to check category changes
      const currentReagentSnap = await getDoc(doc(db, 'reagents', updatedReagent.id));
      if (!currentReagentSnap.exists()) {
        throw new Error('Reagent not found');
      }
      const currentReagent = currentReagentSnap.data() as Reagent;
      
      // Check if the category has changed
      if (currentReagent.category !== updatedReagent.category) {
        const newSku = await getNextSKUFunc(
          updatedReagent.category,
          updatedReagent.isPersonal,
          updatedReagent.isPersonal ? user.id : (updatedReagent.lab || 'MAIN')
        );
        if (newSku) {
          // Release the old SKU
          await releaseSKU(currentReagent.category, currentReagent.sku, currentReagent.isPersonal, currentReagent.lab || 'MAIN');
          // Update the SKU in the reagent data
          updatedReagent.sku = newSku;
        } else {
          throw new Error('Failed to get new SKU');
        }
      }

      // Handle status and timeLeft updates
      if (updatedReagent.timeLeft && updatedReagent.timeLeft > 0) {
        const now = Timestamp.now();
        updatedReagent.status = 'In Use';
        updatedReagent.duration = updatedReagent.timeLeft;
        updatedReagent.checkoutTime = now;
        updatedReagent.dueTime = Timestamp.fromDate(addMinutes(now.toDate(), updatedReagent.timeLeft));
        
        // Ensure checkedOutBy is set
        if (!updatedReagent.checkedOutBy) {
          logger.error('checkedOutBy is not set for a reagent being checked out');
        }
        
        // Reset notification flags
        updatedReagent.notifiedAt15 = false;
        updatedReagent.notifiedAtDue = false;
        updatedReagent.lastOverdueNotification = null;
      } 
      // If the reagent was in use or overdue but now has no time left, reset its status to 'Available'
      else if (['In Use', 'Overdue'].includes(updatedReagent.status) && (!updatedReagent.timeLeft || updatedReagent.timeLeft <= 0)) {
        updatedReagent.status = 'Available';
        updatedReagent.timeLeft = null;
        updatedReagent.duration = null;
        updatedReagent.checkoutTime = null;
        updatedReagent.dueTime = null;
        updatedReagent.checkedOutBy = null;
        updatedReagent.notifiedAt15 = false;
        updatedReagent.notifiedAtDue = false;
        updatedReagent.lastOverdueNotification = null;
      }

      updatedReagent.lastUpdated = serverTimestamp();

      // Convert Reagent to a plain object for Firestore
      const reagentForFirestore = Object.entries(updatedReagent).reduce((acc, [key, value]) => {
        // Exclude the 'id' field
        if (key !== 'id') {
          acc[key] = value;
        }
        return acc;
      }, {} as { [key: string]: any });

      await updateDoc(doc(db, 'reagents', updatedReagent.id), reagentForFirestore);
      return updatedReagent;
    } catch (error) {
      logger.error('Error updating reagent:', error);
      throw error;
    }
  }, [user, logger, releaseSKU]);


  const deleteReagent = useCallback(async (id: string) => {
    if (!user) return;
    try {
      const reagentToDelete = reagents.find(r => r.id === id);
      if (!reagentToDelete) {
        throw new Error("Reagent not found");
      }

      await authRequest(() => reagentService.deleteReagent(id, user));

      setReagents(prevReagents => prevReagents.filter(r => r.id !== id));

      if (notifyCallback) {
        notifyCallback(`Reagent "${reagentToDelete.name}" deleted.`, reagentToDelete.isPersonal, id);
      }

    } catch (error) {
      logger.error('Error deleting reagent:', error);
      if (notifyCallback) {
        notifyCallback(`Failed to delete reagent. Please try again.`, false, id);
      }
      throw error;
    }
  }, [user, authRequest, notifyCallback, reagents, logger]);


  const watchReagent = useCallback(async (id: string) => {
    if (!user) return;
    try {
      const updatedReagent = await authRequest(() => reagentService.watchReagent(id, user));
      if (notifyCallback) {
        notifyCallback(`You are now watching reagent "${updatedReagent.name}".`, updatedReagent.isPersonal, id);
      }
    } catch (error) {
      logger.error('Error watching reagent:', error);
      if (notifyCallback) {
        notifyCallback(`Failed to watch reagent. Please try again.`, false, id);
      }
    }
  }, [user, authRequest, notifyCallback, logger]);

  const unwatchReagent = useCallback(async (id: string) => {
    if (!user) return;
    try {
      const updatedReagent = await authRequest(() => reagentService.unwatchReagent(id, user));
      if (notifyCallback) {
        notifyCallback(`You have stopped watching reagent "${updatedReagent.name}".`, updatedReagent.isPersonal, id);
      }
    } catch (error) {
      logger.error('Error unwatching reagent:', error);
      if (notifyCallback) {
        notifyCallback(`Failed to unwatch reagent. Please try again.`, false, id);
      }
    }
  }, [user, authRequest, notifyCallback, logger]);

  const calculateTimeLeft = useCallback((reagent: Reagent) => {
    if (reagent.status !== 'In Use' && reagent.status !== 'Overdue') {
      return null;
    }
    const now = getServerTime(); // Use getServerTime here
  
    let timeLeftMinutes;
  
    if (reagent.dueTime) {
      // If dueTime is available, use it for calculation
      const dueTime = reagent.dueTime.toDate().getTime();
      timeLeftMinutes = (dueTime - now) / (60 * 1000);
    } else if (reagent.checkoutTime && reagent.duration !== null) {
      // Fall back to the previous calculation method if dueTime is not available
      const checkoutTime = reagent.checkoutTime.toDate().getTime();
      const elapsedMs = now - checkoutTime;
      const elapsedMinutes = elapsedMs / (60 * 1000);
      timeLeftMinutes = reagent.duration - elapsedMinutes;
    } else {
      return null;
    }
    
    // Return the actual time left, even if it's negative
    return Math.floor(timeLeftMinutes);
  }, [getServerTime]);

  // Add a function to check for significant time drift
  const checkTimeDrift = useCallback(async () => {
    try {
      const serverTime = await reagentService.getServerTime();
      const localTime = Date.now();
      const drift = Math.abs(serverTime - localTime);
      
      if (drift > 60000) { // If drift is more than 1 minute
        logger.warn(`Significant time drift detected: ${drift}ms`);
        // Implement recalibration logic here
        // For example, you could update all reagent times or notify the user
      }
    } catch (error) {
      logger.error('Error checking time drift:', error);
    }
  }, [logger]);

  useEffect(() => {
    const driftCheckInterval = setInterval(checkTimeDrift, 300000); // Check every 5 minutes
    return () => clearInterval(driftCheckInterval);
  }, [checkTimeDrift]);

  const updateReagentTimes = useCallback(() => {
    logger.log('Updating reagent times');
    setReagents(prevReagents => 
      prevReagents.map(reagent => {
        if (reagent.status === 'In Use' && reagent.checkoutTime && reagent.duration !== null) {
          const newTimeLeft = calculateTimeLeft(reagent);
          
          if (newTimeLeft === 0 && reagent.status === 'In Use') {
            // Reagent just became overdue
            reagentService.updateReagentStatus(reagent.id, 'Overdue')
              .then(() => {
                addHistoryEntry(
                  `${reagent.isPersonal ? 'Personal' : 'Shared'} Reagent "${reagent.name}" is now overdue.`,
                  reagent.isPersonal,
                  reagent.name
                );
                
                // If it's a shared reagent, notify watchers
                if (!reagent.isPersonal) {
                  for (const watcherId of reagent.watchedBy) {
                    notificationService.addNotification({
                      message: `Shared reagent "${reagent.name}" is now overdue.`,
                      isPersonal: false,
                      reagentId: reagent.id,
                      userId: watcherId,
                      timestamp: getServerTimestamp(),
                      read: false
                    });
                  }
                }
              })
              .catch(error => logger.error('Error updating reagent status:', error));
          }
          
          return {
            ...reagent,
            timeLeft: newTimeLeft,
            status: newTimeLeft !== null && newTimeLeft <= 0 ? 'Overdue' : 'In Use'
          };
        }
        return reagent;
      })
    );
  }, [calculateTimeLeft, getServerTimestamp, addHistoryEntry, logger]);

  useEffect(() => {
    const timer = setInterval(updateReagentTimes, 60000); // Update every minute
    return () => clearInterval(timer);
  }, [updateReagentTimes]);

  useEffect(() => {
    if (user) {
      logger.log('Setting up reagent listeners');
      const unsubscribe = reagentService.setupReagentListeners((fetchedReagents) => {
        logger.log('Reagents fetched from Firestore:', fetchedReagents);
        const updatedReagents = fetchedReagents.map(reagent => ({
          ...reagent,
          timeLeft: calculateTimeLeft(reagent)
        }));
        setReagents(updatedReagents);
        setLoading(false);
      }, user); // Pass the user object here
  
      return () => {
        logger.log('Clearing reagent listeners');
        unsubscribe();
      };
    } else {
      setLoading(false);
    }
  }, [user, calculateTimeLeft, reagentService, logger]);

  const toggleReagentStatus = useCallback(async (id: string, duration: number, isPersonal: boolean) => {
    logger.log(`Attempting to toggle reagent status. ID: ${id}, Duration: ${duration}, IsPersonal: ${isPersonal}`);
    
    if (!user) {
      logger.error('No authenticated user');
      return;
    }
    
    try {
      logger.log('Current user:', user);
      
      logger.log('Calling reagentService.toggleReagentStatus');
      const updatedReagent = await authRequest(() => reagentService.toggleReagentStatus(id, duration, isPersonal, user));
      logger.log('Updated reagent:', updatedReagent);
      
      const action = updatedReagent.status === 'In Use' ? 'checked out' : 'checked in';
      logger.log(`Reagent ${action}`);
      
      // Add history entry
      logger.log('Adding history entry for reagent toggle');
      await addHistoryEntry(
        `${updatedReagent.isPersonal ? 'Personal' : 'Shared'} Reagent "${updatedReagent.name}" ${action}`,
        updatedReagent.isPersonal,
        updatedReagent.name,
        updatedReagent.status === 'In Use' ? duration : undefined
      );
    
      // Notify for shared reagents being checked in
      if (!isPersonal && updatedReagent.status === 'Available') {
        logger.log(`Sending notifications for shared reagent ${id} becoming available`);
        for (const watcherId of updatedReagent.watchedBy || []) {
          if (watcherId !== user.id) {
            try {
              await notificationService.addNotification({
                message: `Shared reagent "${updatedReagent.name}" is now available.`,
                isPersonal: false,
                reagentId: id,
                userId: watcherId,
                timestamp: serverTimestamp(),
                read: false
              });
              logger.log(`Notification sent to user ${watcherId}`);
            } catch (notificationError) {
              logger.error(`Error sending notification to user ${watcherId}:`, notificationError);
            }
          }
        }
      }
      
      logger.log('Updating local state of reagents');
      setReagents((prevReagents: Reagent[]) => { // Update reagents state directly
        return prevReagents.map(reagent => 
          reagent.id === id ? {
            ...updatedReagent,
            timeLeft: updatedReagent.status === 'In Use' ? duration : null // Set initial timeLeft to full duration when checked out
          } : reagent
        );
      });
      
      logger.log('Reagent status toggle completed successfully');
      return {
        ...updatedReagent,
        timeLeft: updatedReagent.status === 'In Use' ? duration : null // Return full duration when checked out
      };
    } catch (error) {
      logger.error('Error toggling reagent status:', error);
      if (error instanceof Error) {
        logger.error('Error message:', error.message);
        logger.error('Error stack:', error.stack);
      }
      if (notifyCallback) {
        notifyCallback(`Failed to ${duration > 0 ? 'check out' : 'check in'} reagent.`, isPersonal, id);
      }
      throw error;
    }
  }, [authRequest, user, setReagents, addHistoryEntry, notificationService, notifyCallback, logger]);


  const extendReagentDuration = useCallback(async (id: string, duration: number) => {
    if (!user) return;
    try {
      const updatedReagent = await authRequest(() => reagentService.extendReagentDuration(id, duration, user));
      
      setReagents(prevReagents => 
        prevReagents.map(reagent => 
          reagent.id === id ? {
            ...reagent,
            ...updatedReagent,
            timeLeft: updatedReagent.timeLeft,
            status: updatedReagent.status
          } : reagent
        )
      );

      if (notifyCallback) {
        notifyCallback(`Reagent "${updatedReagent.name}" duration extended by ${duration} minutes.`, updatedReagent.isPersonal, id);
      }
    } catch (error) {
      logger.error('Error extending reagent duration:', error);
      if (notifyCallback) {
        notifyCallback(`Failed to extend reagent duration. Please try again.`, false, id);
      }
    }
  }, [user, authRequest, notifyCallback, setReagents, logger]);

  const checkReagentAlerts = useCallback(() => {
    const currentTime = new Date();
    reagents.forEach(async (reagent) => {
      if (reagent.status === 'In Use' && reagent.dueTime) {
        const dueTime = reagent.dueTime.toDate();
        const minutesLeft = Math.round(differenceInMinutes(dueTime, currentTime));
        const newStatus = minutesLeft <= 0 ? 'Overdue' as const : 'In Use' as const;
  
        logger.log(`Checking reagent: ${reagent.name}, Current Status: ${reagent.status}, New Status: ${newStatus}, Minutes left: ${minutesLeft}`);
  
        let updatedReagent = { ...reagent, status: newStatus, timeLeft: Math.max(0, minutesLeft) };
        let shouldUpdate = false;
  
        const createAlert = async (message: string, updateField: keyof Reagent) => {
          logger.log(`Creating alert: ${message}`);
          try {
            await addHistoryEntry(message, reagent.isPersonal, reagent.name);
            logger.log(`History entry added for alert: ${message}`);
          } catch (error) {
            logger.error(`Failed to add history entry for alert: ${message}`, error);
          }
          if (notifyCallback) {
            notifyCallback(message, reagent.isPersonal, reagent.id);
          }
          updatedReagent = { ...updatedReagent, [updateField]: true };
          shouldUpdate = true;
        };
  
        if (minutesLeft === 15 && !reagent.notifiedAt15) {
          await createAlert(`${reagent.isPersonal ? 'Personal' : 'Shared'} Reagent "${reagent.name}" is due for check-in in 15 minutes.`, 'notifiedAt15');
        }
  
        if (newStatus === 'Overdue' && reagent.status === 'In Use') {
          await createAlert(`${reagent.isPersonal ? 'Personal' : 'Shared'} Reagent "${reagent.name}" is now overdue.`, 'notifiedAtDue');
        }
  
        if (newStatus === 'Overdue' && minutesLeft < 0) {
          const minutesOverdue = Math.abs(minutesLeft);
          if (minutesOverdue % 15 === 0 && (!reagent.lastOverdueNotification || differenceInMinutes(currentTime, reagent.lastOverdueNotification.toDate()) >= 15)) {
            await createAlert(`${reagent.isPersonal ? 'Personal' : 'Shared'} Reagent "${reagent.name}" is overdue by ${minutesOverdue} minutes.`, 'lastOverdueNotification');
            updatedReagent.lastOverdueNotification = Timestamp.fromDate(currentTime);
          }
        }
  
        if (shouldUpdate || newStatus !== reagent.status) {
          logger.log(`Attempting to update Firestore for reagent: ${updatedReagent.name}`);
          const reagentRef = doc(db, 'reagents', updatedReagent.id);
          try {
            await updateDoc(reagentRef, {
              status: updatedReagent.status,
              timeLeft: updatedReagent.timeLeft,
              lastUpdated: Timestamp.now(),
              notifiedAt15: updatedReagent.notifiedAt15,
              notifiedAtDue: updatedReagent.notifiedAtDue,
              lastOverdueNotification: updatedReagent.lastOverdueNotification
            });
            logger.log(`Firestore successfully updated for reagent: ${updatedReagent.name}`);
            
            // Update local state
            setReagents(prevReagents => 
              prevReagents.map(r => r.id === updatedReagent.id ? updatedReagent : r)
            );
          } catch (error) {
            logger.error(`Failed to update Firestore for reagent: ${updatedReagent.name}`, error);
            if (error instanceof FirebaseError) {
              if (error.code === 'permission-denied') {
                logger.error('Permission denied. Please check Firestore security rules.');
              }
            }
          }
        }
      }
    });
  }, [reagents, addHistoryEntry, notifyCallback, setReagents, logger]);

  useEffect(() => {
    logger.log('Setting up checkReagentAlerts interval');
    const alertInterval = setInterval(() => {
      logger.log('Running checkReagentAlerts');
      checkReagentAlerts();
    }, 60000); // Check every minute
    return () => {
      logger.log('Clearing checkReagentAlerts interval');
      clearInterval(alertInterval);
    };
  }, [checkReagentAlerts]);

  const contextValue = useMemo(() => ({
    reagents,
    loading,
    error,
    personalReagents,
    sharedReagents,
    activeReagents,
    addReagent,
    updateReagent: (reagent: Reagent) => updateReagent(reagent, getNextSKU),
    deleteReagent,
    toggleReagentStatus,
    watchReagent,
    unwatchReagent,
    extendReagentDuration,
    checkReagentAlerts,
    setReagents,
    addHistoryEntry,
    getReagentByName,
    getNextSKU,
    releaseSKU,
    saveNextSkus
  }), [
    reagents,
    loading,
    error,
    personalReagents,
    sharedReagents,
    activeReagents,
    addReagent,
    updateReagent,
    deleteReagent,
    toggleReagentStatus,
    watchReagent,
    unwatchReagent,
    extendReagentDuration,
    checkReagentAlerts,
    setReagents,
    addHistoryEntry,
    getReagentByName,
    getNextSKU,
    releaseSKU,
    saveNextSkus
  ]);

  return (
    <ReagentContext.Provider value={contextValue}>
      {children}
    </ReagentContext.Provider>
  );
};
